.protected-text{
min-width: 272px;
width: 100%;
  height: 40px;
 margin:25px 0 0 0;
  border-radius: 100px;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.2);
   @extend .sans-regular;
   font-size: 20px;
   line-height: 40px;
   color: var(--white);
font{font-weight: 600;}
background:#19c5a2;
&.bgaqua{background: rgba(25,197,163,1);
background: -moz-linear-gradient(left, rgba(25,197,163,1) 0%, rgba(25,197,163,1) 64%, rgba(21,171,141,1) 64%, rgba(21,171,141,1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(25,197,163,1)), color-stop(64%, rgba(25,197,163,1)), color-stop(64%, rgba(21,171,141,1)), color-stop(100%, rgba(21,171,141,1)));
background: -webkit-linear-gradient(left, rgba(25,197,163,1) 0%, rgba(25,197,163,1) 64%, rgba(21,171,141,1) 64%, rgba(21,171,141,1) 100%);
background: -o-linear-gradient(left, rgba(25,197,163,1) 0%, rgba(25,197,163,1) 64%, rgba(21,171,141,1) 64%, rgba(21,171,141,1) 100%);
background: -ms-linear-gradient(left, rgba(25,197,163,1) 0%, rgba(25,197,163,1) 64%, rgba(21,171,141,1) 64%, rgba(21,171,141,1) 100%);
background: linear-gradient(to right, rgba(25,197,163,1) 0%, rgba(25,197,163,1) 64%, rgba(21,171,141,1) 64%, rgba(21,171,141,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#19c5a3', endColorstr='#15ab8d', GradientType=1 );
}
&.bgorange{
background: rgba(253,80,0,1);
background: -moz-linear-gradient(left, rgba(253,80,0,1) 0%, rgba(253,80,0,1) 25%, rgba(220,70,0,1) 26%, rgba(220,70,0,1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(253,80,0,1)), color-stop(25%, rgba(253,80,0,1)), color-stop(26%, rgba(220,70,0,1)), color-stop(100%, rgba(220,70,0,1)));
background: -webkit-linear-gradient(left, rgba(253,80,0,1) 0%, rgba(253,80,0,1) 25%, rgba(220,70,0,1) 26%, rgba(220,70,0,1) 100%);
background: -o-linear-gradient(left, rgba(253,80,0,1) 0%, rgba(253,80,0,1) 25%, rgba(220,70,0,1) 26%, rgba(220,70,0,1) 100%);
background: -ms-linear-gradient(left, rgba(253,80,0,1) 0%, rgba(253,80,0,1) 25%, rgba(220,70,0,1) 26%, rgba(220,70,0,1) 100%);
background: linear-gradient(to right, rgba(253,80,0,1) 0%, rgba(253,80,0,1) 25%, rgba(220,70,0,1) 26%, rgba(220,70,0,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fd5000', endColorstr='#dc4600', GradientType=1 );
}
&.bgyellow{
background: rgba(255,193,7,1);
background: -moz-linear-gradient(left, rgba(255,193,7,1) 0%, rgba(255,193,7,1) 50%, rgba(221,167,6,1) 52%, rgba(221,167,6,1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,193,7,1)), color-stop(50%, rgba(255,193,7,1)), color-stop(52%, rgba(221,167,6,1)), color-stop(100%, rgba(221,167,6,1)));
background: -webkit-linear-gradient(left, rgba(255,193,7,1) 0%, rgba(255,193,7,1) 50%, rgba(221,167,6,1) 52%, rgba(221,167,6,1) 100%);
background: -o-linear-gradient(left, rgba(255,193,7,1) 0%, rgba(255,193,7,1) 50%, rgba(221,167,6,1) 52%, rgba(221,167,6,1) 100%);
background: -ms-linear-gradient(left, rgba(255,193,7,1) 0%, rgba(255,193,7,1) 50%, rgba(221,167,6,1) 52%, rgba(221,167,6,1) 100%);
background: linear-gradient(to right, rgba(255,193,7,1) 0%, rgba(255,193,7,1) 50%, rgba(221,167,6,1) 52%, rgba(221,167,6,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc107', endColorstr='#dda706', GradientType=1 );
}
img{margin: 0 12px -3px 0;}
   }
.status-text{
@extend .sans-regular;
 color: var(--grey-1);
 font-style: italic;
 margin:10px 0 0;
}

.empty-page{
  text-align:center;
  &.empty-page--xs-pad{
    padding-top:95px;   
  } 
  &.empty-page--vs-pad{
    padding-top:115px;   
  } 
  &.empty-page--sm-pad{
    padding-top:130px;   
  }  
  &.empty-page--md-pad{
    padding-top:160px;   
  } 
  &.empty-page--lg-pad{
    padding-top:180px;   
  } 
  &.empty-page--xl-pad{
    padding-top:190px;   
  }  
  .text-25{
    @extend .font-25l;
    text-align:center;
    margin:26px 0 22px;
    &.m134{margin:134px 0 24px 0;}
  }
  .btn-1{
  @extend .sans-1-bold;
    width:auto;
    height:auto;
    padding:10px 31px;
  }
}
.empty-page__img{display:block; margin:0 auto; max-width:100%;}


#detail h2{@extend .font-20b;}