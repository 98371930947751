.capsule{
  position:relative;
  display:inline-block;
  height:20px;
  font-size:14px;
  font-weight:normal;
  line-height:18px;
  padding:0 5px;
  color: var(--grey-3);
  border-radius: 10px;
  border:1px solid var(--grey-3);
  min-width:32px;
  text-align: center;
  background-color:var(--white);
  &--grey-3{
    color:var(--white);
    font-weight:bold;
    background-color: var(--grey-3);
  }
  &--aqua{
    color:var(--white);
    border-color: var(--aqua);
    background-color: var(--aqua);
  }
  &--dark-blue{
    color:var(--dark-blue);
    border-color: var(--dark-blue);    
  }
}

