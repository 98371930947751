.team-links{
  float:left;
  padding:5px 0;
  li{
    float:left;
    margin-right:25px;
    &:last-child{
      margin-right:0;
    } 
    a{
      
    }
    small{
      font-size:.857em;
      color:var(--grey-3);      
    }
    
  }
}
