@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,300i,400,400i,600,600i,700');

.sans-regular {
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  line-height: 1.38;
}
.sans-light {
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
   line-height: 1.38;
}
.sans-bold {
    font-size: 14px;
	font-stretch: normal;
    font-style: normal;
	font-weight: 600;
    line-height: 1.38;
}
.sans-regular-italic {
  font-size: 14px;
  font-stretch: normal;
  font-style: italic;
  font-weight: 400;
  line-height: 1.38;
}

.sans-1-regular {
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
   line-height: 1.38;
}
.sans-1-light {
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
   line-height: 1.38;
}
.sans-1-bold {
    font-size: 16px;
	font-stretch: normal;
    font-style: normal;
	font-weight: 600;
    line-height: 1.38;
}

.sans-2-bold {
    font-size: 25px;
	font-stretch: normal;
    font-style: normal;
	font-weight: 600;
    line-height: 1.2;
}

.font-12 {
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  line-height:1;
  color: var(--grey-1);
}
.font-25b{
  font-size:25px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  line-height:1.2;
  color: var(--grey-2);
}

.font-30b{
  font-size:30px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  line-height: 1.33;
  color: var(--grey-1);
}
.font-30l{
  font-size:30px;
  font-stretch: normal;
  font-style: normal;
  font-weight:300;
  line-height: 1.33;
  color: var(--grey-1);
}
.font-20b{
    font-size: 20px;
	font-stretch: normal;
    font-style: normal;
	font-weight: 600;
    line-height: 1.5;
	color: var(--grey-2);
}
h4{
@extend .sans-1-bold ;
  line-height: 1.25;
  letter-spacing: normal;
  color: var(--grey-3);
}
.font-14{
@extend .sans-regular;
line-height: 1.43;
color: var(--grey-1);
}

.font-25l {
  font-size: 25px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--grey-2);
}


h1 {
  @extend .font-30b;
  font-size:35px;
  line-height: 1.33;
  color: var(--grey-2);
}
h2 {
  @extend .font-30b;
  color: var(--grey-2);
}
h3 {
  @extend .font-25b;
  color: var(--grey-2);
}
h4 {
  @extend .sans-1-bold;
  color: var(--grey-2);
}
h5 {
    @extend .sans-bold;
	color: var(--grey-2);
}
