@import 'buttons';
@import 'forms';
@import 'tables';
@import 'dropdowns';
@import 'modals';
@import 'breadcrumb';
@import 'tabs';
@import 'capsules';
@import 'toggle';
@import 'calender';
@import 'images';
@import 'chart';
@import 'text';
@import 'tooltip';
@import 'notifications';
@import 'panel-collapsed';
@import 'animation';
@import 'time-slider';
@import 'loding';
@import 'inputtags';
@import 'validation';
@import 'listing';
@import 'page-animations';
@import 'page-transitions-component';
@import 'toaster';
@import 'team-links';
@import 'cloud-vaults';
@import 'modal-restore';
@import 'account-login';
@import 'range-slider';
@import 'error';
@import 'geolocate';
@import 'migration';
@import 'nouislider';
@import 'login-otp';
@import 'enable-2fa';
@import 'status-message-box';
@import 'tag';
