.dropdown, .dropup {
    position: relative;
}
.dropdown-toggle:focus {
    outline: 0 none;
}
.dropdown-menu {
    background-clip: padding-box;
    background-color: var(--white);
    border: 1px solid var(--aqua);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
    display: none;
    float: left;
    font-size: 14px;
    left: 0;
    list-style: outside none none;
    margin:0;
    min-width: 160px;
    padding: 5px 0;
    position: absolute;
    text-align: left;
    top: 100%;
    z-index: 1000;
  
  &__title{
    padding: 10px 19px;
    font-size:14px;
    font-weight:bold;
    color:var(--aqua);
  }
  
  &__accounts{
    border-top: solid 1px #e0e4e9;
    padding-top:8px;
    padding-bottom:8px;
    margin-top:8px;
    
    max-height:297px;
    overflow-y:auto;    
    a.dropdown-item{
      .indi{
        float:right;
        margin:0 0 0 10px !important;    
        color:var(--aqua);
      }
      &:hover{
        color:var(--white) !important;
        background:var(--aqua) !important;
        .indi{
          color:var(--white) !important;
        }
      }
    }
  }
  
  
  
}
.dropdown-menu.pull-right {
    left: auto;
    right: 0;
}
.dropdown-menu .divider {
    background-color: #e5e5e5;
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
}
.dropdown-menu > li > a {
    clear: both;
    color: #333333;
    display: block;
    font-weight: 400;
    line-height: 1.42857;
    padding: 3px 20px;
    white-space: nowrap;
}
.dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
    background-color: #f5f5f5;
    color: #262626;
    text-decoration: none;
}
.dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover {
    background-color: #337ab7;
    color: var(--white);
    outline: 0 none;
    text-decoration: none;
}
.dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:focus, .dropdown-menu > .disabled > a:hover {
    color: #777777;
}
.dropdown-menu > .disabled > a:focus, .dropdown-menu > .disabled > a:hover {
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    cursor: not-allowed;
    text-decoration: none;
}
.open > .dropdown-menu, .open > .dropdown-menu .dropdown-menu {
    display: block;
}
.open > a {
    outline: 0 none;
}
.dropdown-menu-right {
    left: auto;
    right: 0;
}
.dropdown-toggle {
  &::after {
    border-left: 0.4em solid rgba(132, 147, 169, 0);
    border-right: 0.4em solid rgba(132, 147, 169, 0);
    border-top: 0.4em solid;
    content: "";
    display: inline-block;
    height: 0;
    margin-left: 1em;
    vertical-align: middle;
    width: 0;
  }
  &:focus {
    outline: 0 none;
  }
}

.btn-select {
  position: relative;
  padding: 0;
  min-width: 236px;
  width: 400px;
  border-radius: 0;
  margin-bottom: 20px;
  background:var(--light-blue);
  &.w350{width:350px;}
  &--white{
    background-color: var(--white);
  }
  .btn-select-value {
    padding: 10px 3px 10px 12px;
    display: block;
    position: absolute;
    left: 0;
    right: 34px;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    border-top: none !important;
    border-bottom: none !important;
    border-left: none !important;
	border-radius: 3px;
  }
  .btn-select-arrow {
    float: right;
    line-height: 20px;
    padding:9px 15px 10px 3px;
	color: var(--grey-3);
	border-radius: 3px;
  }
  .btn-select-arrow:after {
    border-left: 0.4em solid rgba(0, 0, 0, 0);
    border-right: 0.4em solid rgba(0, 0, 0, 0);
    border-top: 0.4em solid;
    content: "";
    display: inline-block;
    height: 0;
    margin-left:0;
    vertical-align: middle;
    width: 0;
}
  ul {
    display: none;
    background-color: white;
    color: black;
    clear: both;
    list-style: none;
    padding:0px 0;
    margin: 0;
    border-top: none !important;
    position: absolute;
    left: -1px;
    right: -1px;
    top:0px;
    z-index: 995;
	float:left;
	
	max-height:200px;
	overflow-y:auto;
	
    li {
      padding:10px 12px 9px;
      text-align: left;
      svg:first-child{
        margin-right:10px;
      }
      &:hover {
         background-color:var(--light-blue);
      }
      &.selected {
        //color: white;
      }
      &.disabled{
        pointer-events: auto;
        opacity: 1;
        color: #adb6c5;
        svg{
          opacity:0.5;
        }        
        svg.icn-added{
          display: block;
          float: right;
          margin: 0;
          opacity: 1;
        }
        
      }
    }
  }
  &.btn-default {
    &:hover, &:active, &.active {
      border-color: #ccc;
    }
    ul {
	 border-radius: 3px;
	  box-shadow: 0 2px 7px 0 var(--grey-5);
      border: solid 1px var(--grey-5);
      background: var(--white);
      border: none;
	  @extend .sans-bold;
     /* line-height: 1.25;*/
      color:  var(--grey-2);
      text-transform: none;
      border: solid 1px var(--aqua);
	    border-top: solid 1px var(--aqua)!important;
      
      li{
	      &.selected {
          background-color:var(--light-blue);
        }
        &.select-file-disabled{
          position:relative;
          color: #adb6c5;
          cursor: default;
          svg{
            opacity:0.5;
          }
          .icn-added{
            display: block;
            float: right;
            margin: 0;
            opacity: 1;
          }
        }
        &:first-child{
          border-radius:3px 3px 0 0;
        }
        &:last-child{
          border-radius:0 0 3px 3px ;
        }
	    }
      
      
    }
    .btn-select-value {
      background: none;
      border: none;
	    @extend .sans-bold;
      line-height: 1.38;
      color: var(--grey-2);
      text-transform: none;
      border: solid 1px var(--grey-5);
      border-right: none;
      svg:first-child{
        margin-right:10px;
      }
    }
  }
}

.btn.btn-default.btn-select {
  box-shadow: 0 1px 0 0 var(--grey-5);
  border: solid 1px var(--grey-5);
  border-radius: 3px;  
}

.btn-select {
  &.btn-default {
    &:hover, &.active {
      background-color:var(--light-blue);
    }    
  }
  &.btn-primary {
    &:hover, &:active, &.active {
      border-color: #286090;
    }
    ul {
      li.selected {
        background-color: #2e6da4;
        color: white;
      }
      border: #2e6da4 1px solid;
    }
    .btn-select-value {
      background-color: #428bca;
      border: #2e6da4 1px solid;
    }
    &:hover, &.active {
      background-color: #286090;
    }
  }
  &.btn-success {
    &:hover, &:active, &.active {
      border-color: #4cae4c;
    }
    ul {
      li.selected {
        background-color: #4cae4c;
        color: white;
      }
      border: #4cae4c 1px solid;
    }
    .btn-select-value {
      background-color: #5cb85c;
      border: #4cae4c 1px solid;
    }
    &:hover, &.active {
      background-color: #449d44;
    }
  }
  &.btn-info {
    &:hover, &:active, &.active {
      border-color: #46b8da;
    }
    ul {
      li.selected {
        background-color: #46b8da;
        color: white;
      }
      border: #46b8da 1px solid;
    }
    .btn-select-value {
      background-color: #5bc0de;
      border: #46b8da 1px solid;
    }
    &:hover, &.active {
      background-color: #269abc;
    }
  }
  &.btn-warning {
    &:hover, &:active, &.active {
      border-color: #eea236;
    }
    ul {
      li.selected {
        background-color: #eea236;
        color: white;
      }
      border: #eea236 1px solid;
    }
    .btn-select-value {
      background-color: #f0ad4e;
      border: #eea236 1px solid;
    }
    &:hover, &.active {
      background-color: #d58512;
    }
  }
  &.btn-danger {
    &:hover, &:active, &.active {
      border-color: #d43f3a;
    }
    ul {
      li.selected {
        background-color: #d43f3a;
        color: white;
      }
      border: #d43f3a 1px solid;
    }
    .btn-select-value {
      background-color: #d9534f;
      border: #d43f3a 1px solid;
    }
    &:hover, &.active {
      background-color: #c9302c;
    }
  }
  &.btn-select-light .btn-select-value {
    background-color: white;
    color: black;
  }
}

.btn-group{
position:relative;
margin: 0 0 0 4px;
}
.btn-group, .btn-group-vertical {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}
.dropdown-toggle {
  &::after {
    border-left: 0.3em solid rgba(132, 147, 169, 0);
    border-right: 0.3em solid rgba(132, 147, 169, 0);
    border-top: 0.3em solid;
    content: "";
    display: inline-block;
    height: 0;
    margin-left: 1em;
    vertical-align: middle;
    width: 0;
  }
  &:focus {
    outline: 0 none;
  }
}

.dropup .dropdown-toggle::after {
  border-bottom: 0.3em solid;
  border-top: 0 none;
}

.dropdown-item {
  background: none;
  border: 0 none;
  clear: both;
  display: block;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: var(--grey-2);
  padding: 10px 19px;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
  position:relative;
}

.dropdown-menu {
  min-width: 100px;
}

a.dropdown-toggle{
  border-radius: 3px;
  border: solid 1px var(--grey-4);
  color: var(--grey-2);
  @extend .sans-bold;
  padding:5px 7px 5px 10px;
  text-transform:uppercase;
  text-decoration:none;
  display: inline-block;
  &:hover{
    text-decoration:none;
}
}

a.dropdown-toggle::after{
margin-left:1.5em;
margin-top: -2px;
}

.dropdown-menu.dropdown-menu-left {
    width: 200px;
    border-radius: 3px;
    background-color: var(--white);
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
    border: solid 1px var(--aqua);
    left:0px;
    right:inherit;
    padding:0;
    top:0px;
	&.right{left:inherit;right:0px;}
    .dropdown-item {
      @extend .sans-bold;
      line-height: 1.43;
      color:var(--grey-2);
      text-align: left;
      padding: 10px 19px;
	  width: 100%;
	 display:block;
	 &:first-child{
	  border-radius:3px 3px 0 0;
      }
	  &:last-child{
	  border-radius:0 0 3px 3px ;
      }
      &:hover {
        @extend .background-light-blue;
        text-decoration: none;
      }
    }
  }
.dropdown-link{
.btn.btn-default.btn-select{
background:var(--white);
min-width: 115px;
width: 130px;
ul{
width:200px;
}
}
&.aqua-130{
vertical-align: top;
margin:0 0 0 10px;
.btn-group{
margin:0;
.btn.btn-default.btn-select{
margin:0;height:30px;background:var(--aqua); box-shedow:none; border:none;
.btn-select-arrow{
padding: 5px 18px 5px;
color:var(--white);
&:after{
    border-left: 0.6em solid rgba(0, 0, 0, 0);
    border-right: 0.6em solid rgba(0, 0, 0, 0);
    border-top: 0.6em solid;

}
}
.btn-select-value{
padding: 6px 12px 5px;
color:var(--white);
text-transform:uppercase;
}
}
}
}
}

.dropdown-group-left {
    display: inline-block;
    margin-right: 30px;
    line-height: 30px;
    min-height: 32px;
}
.dropdown-group-right{
  display: inline-block;
  .btn-select ul{
    left:inherit;
  }
  .dropdown-menu.dropdown-menu-right{
    left:auto;
    right:0;
    top:0;
  }
}

.btn-group.bdrnone{
.btn.btn-default.btn-select{
box-shadow: none;
border: none;
border-radius:0px;
width:77px;
min-width:20px;
padding: 0;
margin:0;
&.w87{width:87px;}
&.w50{width:50px;}
&.w55{width:55px;}

.btn-select-value{
text-transform: uppercase;
font-size: 12px;
font-weight:600;
    padding: 0;
	margin:0;
	right:0px;
}
.btn-select-arrow {
    line-height: 15px;
    padding: 0;
	margin:0;
}
ul{
top:-10px;
font-size:12px;
left:-13px;
}
}
.btn-top{
    color: var(--grey-2);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding: 7px 0;
	text-decoration:none;
	.arrow{display:none;}
	.show{display:block;}
	.arrow-top{ width:11px;
  height:11px;
 background:url(../../images/dropdown-uparrow.svg) no-repeat 0 0;
	line-height: 20px;
	float:right;
  padding: 0;
  margin:3px 0 0 5px;}
  .arrow-bot{ width:11px;
  height:11px;
 background:url(../../images/dropdown-arrow.svg) no-repeat 0 0;
	line-height: 20px;
float:right;
  padding: 0;
  margin:3px 0 0 5px;}
}
}

.dropdown{
display:inline-block;
&.w105{
.btn-group{
&.mar0{margin:0;}
.btn-select{
margin:0;
}
.btn-select{
width:105px;
height:40px;
min-width:100px;
margin-bottom:0;
 .btn-select-value{right:29px;}
}
.btn-select ul li{ line-height:18px;}
}
}
}
a.disabled {
   pointer-events: none;
   cursor: default; opacity:0.5;
}
.aqua-plus a.dropdown-toggle.btn{
@extend .btn;
padding:0;
margin:0;
&::after{display:none;}
}
/*.dropdown-menu{
&.dropdown-menu-right{
&.w320{
width:320px;
height: 300px;
}
}
}*/

.dropdown-menu {
  
  .dropdown-item{
    inline-svg:first-child svg,
    svg:first-child{
      vertical-align: top;
      margin: 0 10px 0 0;
    }
    &:hover {
      @extend .background-light-blue;
      text-decoration: none;
    }    
    .icn-added{
      display:none;
    }
    &--disabled{
      color: #adb6c5 !important;
      cursor: default;
      svg{
        opacity:0.5;
      }
      .icn-added{
        display:block;
        float:right;
        margin:-2px 0 0 0;
        opacity:1;
      }
    }
  }
  
  
&.dropdown-menu-right{
  
  &.w320{
    width:320px;
    border-radius: 3px;
    background-color: var(--white);
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
    border: solid 1px var(--aqua);
    left:-85px;
    right:inherit;
    padding:10px 0;
    top:-8px;
    .dropdown-item {
      @extend .sans-bold;
      line-height: 1.43;
      text-align: left;
      padding: 10px 19px;
      width: 100%;
      display:block;      
      &.dropdown-item-select{
        color: #19c5a2;
      }
    }

  }
}
}


@media screen and (-webkit-min-device-pixel-ratio:0)
{
    .btn-select ul{top:-1px;}
}


a.dropdown-toggle.inside-table{
  border:none;
  padding:0;
  float:right;
  &::after{
    display:none;
  }
}
.dropdown-menu{
  /*display: block;*/
  position: absolute;
  left: inherit;
  right: 20px;
  top:20px;
  width: 200px;
  padding:10px 0px;
  border: solid 1px var(--aqua);
  background:#fff; 
  
  .dropdown-item{
    height:auto;
    inline-svg:first-child svg,
    svg:first-child,
    &.edit svg{
      vertical-align: top;
      margin: 0 10px 0 0;
    }    
    &:hover {
      @extend .background-light-blue;
      text-decoration: none;
    }    
    .icn-added{
      display:none;
    }
    &.inactive,
    &--disabled{
      color: #a0abbb !important;
      cursor: default; 
      svg{
        opacity:0.5;
      }
      .icn-added{
        display:block;
        position:absolute;
        right:19px;
        top:10px;
        margin:0;
        opacity:1;
      }
    }   
  }
  
  
  &.inside-table{
    top:15px;
   
  }
  
}

// if more than 4 rows, apply to last 4 rows
.table-main .table tr:nth-child(n+5):nth-last-child(-n+4) .dropdown-menu.inside-table{
  top:auto;
  bottom:15px;
}

