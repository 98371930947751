.modal-restore {
	position: relative;
	max-width: 960px;
	width: 960px;
	padding: 0;
	background-color: #f4f7f9;
	transition: .25s ease;

	h3 {
		font-weight: bold;
	}

	&__header {
		position: relative;
		padding: 20px 30px;
		overflow: hidden;
		z-index: 30;
		border-radius: 3px 3px 0 0;
		background-color: var(--white);
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);

		h3 {
			float: left;
			margin: 0;

			svg {
				width: 40px;
				height: 40px;
				vertical-align: middle;
				margin-right: 20px;
			}
		}

		.close {
			position: absolute;
			top: 30px;
			right: 30px;
		}
	}

	&__body {
		position: relative;
	}

	&__filters {
		padding: 20px 30px 10px;
		box-shadow: inset 0 -1px 0 0 #e0e4e9;

		> .row {
			margin-left: -7px;
			margin-right: -7px;

			> .col {
				position: relative;
				min-height: 1px;
				padding-left: 7px;
				padding-right: 7px;
				float: left;
				width: 33.33333333%;
			}
		}

		.dropdown-link .btn.btn-default.btn-select,
		.dropdown-link .btn.btn-default.btn-select ul {
			width: 100%;
			margin: 0;
		}

		.btn-select.btn-default .btn-select-value,
		.btn-select ul li {
			display: flex;
			font-weight: normal;

			svg {
				flex-shrink: 0;
			}

			span {
				padding-left: 3px;
				font-weight: 600;

				&.title {
					flex-shrink: 0;
					font-weight: normal;
					text-transform: uppercase;
				}

				&.label {
					font-weight: bold;
					margin-left: 5px;
					display: block;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
			}
		}

		.btn-select ul li span.title {
			display: none;
		}
	}

	&__search {
		margin-top: 17px;

		.checkbox {
			float: left;
			margin-bottom: 3px;

			label {
				font-weight: 600;
				vertical-align: top;
			}
		}
	}

	.custom-search-input.right {
		position: relative;
		margin: 3px 0 0 0;
		width: 100%;

		input {
			&::placeholder {
				text-transform: uppercase;
				font-size: 14px;
			}
		}
	}

	&__files {
		position: relative;
		height: 50vh;

		#tree-table-container {
			position: relative;
			height: 100%;
			overflow: auto;
			margin-left: 30px;
			margin-right: 30px;
			width: 928px;

			.status-message-box-container {
				height: 80%;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			#tree-table {
				colgroup {
					col {
						&#file {
							width: 400px;
							min-width: 360px;
						}

						&#restore {
							width: 100px;
							min-width: 90px;
						}

						&#size {
							width: 130px;
							min-width: 114px;
						}

						&#date_modified {
							width: 180px;
							min-width: 167px;
						}

						&#date_created {
							width: 180px;
							min-width: 167px;
						}
					}
				}

				thead {
					position: sticky;
					top: 0;
					z-index: 10;

					tr {
						border-bottom: 1px solid var(--grey-5);
						height: 40px;
						font-size: 12px;
						background-color: var(--light-blue);

						th {
							padding-top: 10px;
							color: var(--grey-2);
							border: unset;
						}
					}
				}

				tbody {
					background-color: var(--white);

					tr {
						border-bottom: 1px solid var(--grey-5);
						height: 40px;
						font-size: 14px;
						background-color: var(--white);

						td {
							padding-right: 10px;
							white-space: nowrap;
							color: var(--grey-1);
							border: unset;
						}

						span {
							&.fancytree-icon,
							&.fancytree-custom-icon {
								width: 20px;
								height: 20px;
								margin-left: 0;
								margin-right: 10px;
							}

							&.fancytree-title {
								padding: 0;
								margin-left: 0;
								color: var(--grey-1);
							}
						}

						&.restore-root {
							span.fancytree-title {
								font-weight: bold;
							}
						}

						&.fancytree-active,
						&.fancytree-selected,
						&.fancytree-focused {
							background-color: var(--white);
							outline: unset;

							span.fancytree-title {
								outline: unset;
							}
						}

						&:hover,
						&.fancytree-active:hover,
						&.fancytree-selected:hover {
							background-color: var(--light-blue);
							outline: unset;
						}

					}
				}
			}
		}
	}

	.restore-checkbox {
		position: relative;
		display: inline-block;
		border: 1px solid var(--grey-4);
		border-radius: 2px;
		width: 18px;
		height: 18px;
		float: left;
		overflow: hidden;

		&:hover {
			cursor: pointer;
		}

		&__filled {
			background: var(--aqua);
			border: 1px solid var(--aqua);
			color: var(--white);
		}

		&__checkmark {
			&:before {
				position: absolute;
				left: -2px;
				top: -1px;
				content: '';
				background: url(../../images/icn-check-white.svg) no-repeat 0 0;
				width: 18px;
				height: 18px;
				pointer-events: none;
			}
		}

		&__circle {
			&:before {
				position: absolute;
				left: 4px;
				top: 4px;
				content: '';
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background: var(--white);
			}
		}
	}

	.restore-expander {
		margin-right: 10px;
		width: 20px;
		height: 20px;
		background-position: center !important;
		background-size: 13px 13px !important;

		&__expanded {
			background: url(../../images/dropdown-arrow.svg) no-repeat;
		}

		&__collapsed {
			background: url(../../images/dropdown-arrow.svg) no-repeat;
			transform: rotate(-90deg);
		}
	}

	.restore-no-expander {
		margin-right: 10px;
		width: 20px;
		height: 20px;
	}

	.restore-icon {
		width: 20px;
		height: 20px;
	}

	&__tray {
		position: relative;
		margin-top: -1px;
		overflow: hidden;
		z-index: 20;
		padding: 15px 10px 15px 20px;
		background: var(--white);
		box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.25);
		border-radius: 0 0 3px 3px;
		width: 100%;

		p {
			font-size: 14px;
			float: left;
			color: var(--grey-2);
			line-height: 30px;

			em {
				color: var(--grey-1);
				margin-right: 8px;
			}

			.capsule {
				margin-left: 8px;
				margin-right: 5px;
			}
		}

		.btn-grp {
			display: flex;
			float: right;
			border: unset;
			margin: 0;
			padding: 0;
		}
	}

	#loader-wrapper {
		position: absolute;
		background: rgba(255, 255, 255, 0.5);
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
	}

	.overlay-container {
		position: absolute;
		background-color: #f4f7f9;
		z-index: 25;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
	}

	.restore-in-progress {
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;

		.status-message-box-container {
			align-self: center;
			width: 50%;
		}

		&__header {
			align-self: stretch;
			flex-grow: 0;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			gap: 30px;
			padding: 0 40px;

			img {
				width: 80px;
				height: 80px;
			}

			.progress-bar-container {
				align-self: stretch;
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: stretch;
				gap: 10px;
				padding: 0;

				.progress-bar-title {
					height: 26px;
					align-self: stretch;
					flex-grow: 0;
					font-size: 20px;
					font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.3;
					letter-spacing: normal;
					text-align: left;
					color: var(--grey-1);
				}

				.progress-bar {
					margin: unset !important;
					width: 100%;
					flex-shrink: 0;

					svg {
						margin: 8px 12px 0 0;
					}

					.bar.positive {
						-webkit-animation: unset !important;
						animation: unset !important;
					}

					&-label {
						font-weight: bold;
					}
				}
				.loading {
					margin: unset !important;
					.loader {
						width: 100%;
					}
				}

				.current-file {
					height: 16px;
					align-self: stretch;
					flex-grow: 0;
					font-size: 12px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.33;
					letter-spacing: normal;
					text-align: left;
					color: var(--grey-3);
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 768px;

					&-label {
						font-weight: bold;
						margin-right: 8px;
					}
				}
			}
		}

		&__table {
			flex-grow: 1;
			padding: 0 40px;
			width: 100%;
		}
	}

	.restore-summary {
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 20px;

		&__header {
			align-self: stretch;
			flex-grow: 0;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			gap: 30px;
			padding: 40px 40px 0;

			img {
				width: 80px;
				height: 80px;
			}

			.header-container {
				align-self: stretch;
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: stretch;
				gap: 10px;
				padding: 0;

				.header-title {
					height: 26px;
					align-self: stretch;
					flex-grow: 0;
					font-size: 20px;
					font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.3;
					letter-spacing: normal;
					text-align: left;
					color: var(--grey-2);
				}

				.status-message-box-container {
					padding: 0;
					.status-message-box-success, .status-message-box-warning, .status-message-box-error {
						width: 100% !important;
					}
				}
			}
		}

		&__table {
			flex-grow: 1;
			padding: 0 40px;
			width: 100%;
		}
	}

	.restore-summary-table {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: stretch;
		padding: 0;

		> .rst-row {
			height: 40px;
			align-self: stretch;
			flex-grow: 0;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding: 10px 0;
			border-bottom: solid 1px var(--grey-5);

			&:nth-last-child(1) {
				border-bottom: unset !important;
			}

			> .rst-col {
				flex: 1;
				font-size: 14px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.43;
				letter-spacing: normal;
				text-align: left;
				color: var(--grey-1);

				> .rst-label {
					font-weight: bold;
				}
			}
		}
	}

	.overlay-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;

		.overlay-inner {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			flex-grow: 1;
			text-align: center;
			min-height: 0;

			h3 {
				margin: 0;
			}

			p {
				text-align: center;
				max-width: 400px;
				margin: 0 auto;
			}

			a {
				white-space: nowrap;
			}

			.overlay-body {
				display: flex;
				flex-direction: column;
				align-items: center;
				min-height: 0;
			}

			.overlay-icon {
				img {
					width: 80px;
					height: 80px;
				}
			}

			.overlay-title-desc {
				display: flex;
				flex-direction: column;
				min-height: 0;
			}

			.restore-connection-error {

				&-container {
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					gap: 12px;
					padding: 12px 12px;
					border-radius: 4px;
					background-color: rgba(0, 113, 197, 0.12);
					min-height: 0;

					svg {
						width: 18px;
						height: 18px;
						color: var(--darker-blue);
					}
				}

				&-summary {
					display: flex;
					flex-direction: column;
					text-align: left;
					width: 600px;
					height: 100%;

					h4 {
						font-size: 18px;
						margin: 0;
					}

					p {
						text-align: left !important;
						max-width: unset;
						margin: 0;
					}
				}
			}

			.restore-progress {
				margin-top: 40px;

				&__file-counts-sizes {
					width: 100%;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					padding: 0 8px;
					margin-bottom: 2px;
					color: var(--grey-1);
				}

				.loading {
					margin: unset !important;
					.loader {
						width: 600px;
						height: 20px;
					}
				}

				&__current-file-transfer-speed {
					width: 100%;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					padding: 0 8px;
					margin-top: 2px;
					color: var(--grey-1);

					span:nth-child(1) {
						width: 500px;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						text-align: left;
					}
				}
			}

			.btn-container {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 30px;
				text-transform: uppercase;

				&-vertical {
					@extend .btn-container;
					flex-direction: column;

					.btn-3 {
						margin-bottom: 28px;
					}
				}

				&-horizontal {
					@extend .btn-container;
					flex-direction: row;
				}

				&-abort {
					@extend .btn-container-horizontal;
					margin-top: 60px;
				}
			}
		}
	}
}

/**
  Fine-tune the device-modal style to reflect the designs we received.
  TODO: if this is the preferred styling we're going to use in future for these dialogs,
   then we should rather make these changes in the generic stylesheets such as _modal.scss
**/
.modal-restore-summary {
	padding-bottom: 20px !important;

	h3 {
		font-weight: bold !important;
	}

	.restore-details-summary {
		h3:first-child {
			margin-bottom: 0 !important;
		}

		.icon-img {
			width: 18px;
			height: 18px;
		}

		.status-message-box-container {
			padding: 0 40px 20px 40px;
			.status-message-box-success, .status-message-box-warning, .status-message-box-error {
				width: 100% !important;
			}
		}
	}

	.restore-details-listing {
		li {
			border-top: unset !important;
			border-bottom: solid 1px var(--grey-5);

			span {
				font-weight: bold !important;
			}

			&:nth-last-child(1) {
				border-bottom: unset !important;
			}
		}
	}
}
