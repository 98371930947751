.note{
  @extend .sans-regular;
  position: fixed;
  bottom:0;
  text-align:center;
  left:0;
  right:0; 
  margin:0 auto;
  color:var(--white);
  padding:0;
  z-index: 9999;
  svg{margin:0 13px 0 0;}
  img{margin:0 13px -4px 0;}
  span{ font-weight:bold; margin:0 0 0 13px;text-transform: uppercase;
   &:hover{cursor:pointer;}
   }
.text-note{
height: 45px; 
display:inline-block;
padding:13px 15px;
border-radius: 4px 4px 0 0;}
}

.note-fade {
            -webkit-animation-duration:5s;
			animation-duration:5s;
            -webkit-animation-fill-mode: both;
			animation-fill-mode: both;
			 opacity: 0;
         }
         
         @-webkit-keyframes fadeOut {
    0% {
        opacity:1;
    }
    16% {
        opacity: 1;
    }
    84% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    16% {
        opacity: 1;
    }
    84% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
         .fadeOut {
          -webkit-animation: fadeOut 6s;
          animation: fadeOut 6s;
         }
		 