:root {
	--white: rgb(255, 255, 255);
	--black: rgb(0, 0, 0);
	--blue: rgb(0, 198, 217);

	--grey-1: #353d4b;
	--grey-2: #5b6e8c;
	--grey-3: #8493a9;
	--grey-4: #c6ced8;
	--grey-5: #e0e5e9;
	--grey-6: #f4f8fa;

	--red: rgb(253, 81, 79);
	--orange-tuple: 253, 80, 0;
	--orange: rgb(var(--orange-tuple));
	--green: rgb(80, 227, 130);
	--turquoise: rgb(80, 227, 194);

	--light-blue: #f4f8fa;
	--aqua-tuple: 25, 197, 162;
	--aqua: rgb(var(--aqua-tuple));
	--light-black: #535a66;
	--dark-aqua: #16b191;
	--dark-orange: #e34800;
	--yellow: #ffc107;
	--dark-yellow: #ff9900;
	--dark-blue: #0c9fed;
	--darker-blue: #0071c5;

	--shadow: rgba(0, 0, 0, 0.25);
	--link: var(--dark-blue);

	// NOTE: The rgb() and rgba() functions are finicky in Microsoft Edge. They only accept tuples.
	//  Not even colors like rgb(123, 45, 67) work intuitively as one would expect.
	--action-active: var(--aqua);
	--action-active-background: rgba(var(--aqua-tuple), 0.12);
	--action-error: var(--orange);
	--action-error-background: rgba(var(--orange-tuple), 0.12);
	--action-warning-tuple: 255, 153, 0;
	--action-warning: rgb(var(--action-warning-tuple));
	--action-warning-background: rgba(var(--action-warning-tuple), 0.12);
	--action-link: var(--dark-blue);
}

.background-aqua {
	background: var(--aqua);
}

.background-white {
	background: var(--white);
}

.background-blue {
	background: var(--blue);
}

.background-dark-blue {
	background: var(--dark-blue);
}

.background-darker-blue {
	background: var(--darker-blue);
}

.background-light-blue {
	background: var(--light-blue);
}

.background-dark-orange {
	background: var(--dark-orange);
}

.background-orange {
	background: var(--orange);
}

.background-light-grey {
	background: var(--grey-4);
}

.black-bg {
	background: rgba(83, 90, 102, 0.85);
}

.black-bg1 {
	background: rgba(51, 60, 75, 0.85);
}

.dblue-bg {
	background: var(--dark-blue);
}

.orange-bg {
	background: var(--orange);
}

.bg-grey-2 {
	background: var(--grey-2);
}


.grey-1 {
	color: var(--grey-1);
}

.grey-2 {
	color: var(--grey-2);
}

.grey-3 {
	color: var(--grey-3);
}

.grey-4 {
	color: var(--grey-4);
}

.grey-5 {
	color: var(--grey-5);
}

.orange {
	color: var(--orange);
}

.dark-orange {
	color: var(--dark-orange);
}

.dark-blue {
	color: var(--dark-blue);
}
