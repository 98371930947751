.button{
  border-radius: 100px;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 600;
  vertical-align: middle;
  margin: 0 10px;
}

.btn-orange {
  @extend .button;
  background: var(--orange);
  border: 1px solid var(--orange);
  color: var(--white);
  font-style: normal;
   display:block;
  &:hover {
    background: var(--dark-orange);
    text-decoration: none;
	border: 1px solid var(--dark-orange);
  }
  &:focus{box-shadow: 0 1px 2px 0 rgba(0,0,0,0.25);outline:none;}
  &.disabled{
pointer-events: none;
cursor: default;
opacity:0.85;
	   }
}

.btn-aqua {
	@extend .button;
	background: var(--aqua);
	border: 1px solid var(--aqua);
	color: var(--white);
	font-style: normal;

	&:hover {
		background: var(--dark-aqua);
		text-decoration: none;
		border: 1px solid var(--dark-aqua);
	}

	&:focus {
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
	}

	&.disabled {
		pointer-events: none;
		cursor: default;
		opacity: 0.5;
	}
}

.no-margin {
	margin: 0;
}

.btn {
  @extend .button;
  background: var(--white);
  border: 1px solid var(--aqua);
  color: var(--aqua);
  &:hover {
    background: var(--aqua);
    color: var(--white);
    text-decoration: none;
  }
  &:focus{box-shadow: 0 1px 2px 0 rgba(0,0,0,0.25);outline:none;}
}

.grey-outline {
  @extend .button;
  background: var(--white);
  border: 1px solid var(--grey-4);
  color: var(--aqua);
  &:hover {
    background: var(--white);
    color: var(--aqua);
    text-decoration: none;
	border: 1px solid var(--aqua);
  }
  &:focus{box-shadow: 0 1px 2px 0 rgba(0,0,0,0.25);outline:none;}
}
.grey-out-line {
  @extend .button;
  background: var(--white);
  border: 1px solid var(--grey-4);
  color: var(--aqua);
  &:hover {
    background: var(--white);
    color: var(--white);
    text-decoration: none;
	border: 1px solid var(--aqua);
  }
  &:focus{box-shadow: 0 1px 2px 0 rgba(0,0,0,0.25);outline:none;}
}




.btn-1 {
  width: 160px;
  height: 30px;
  line-height: 28px;
  font-size: 14px;
 &.w110{width:110px;}
 &.w112{width:112px;}
 &.w143{width:143px;}
 &.w141{width:141px;}
 &.w152{width:152px;}
 &.w156{width:156px;}
 &.w175{width: 175px;}
 &.marr0{margin-right:0;}
 &.marl0{margin-left:0;}
   &.btn-img{padding:0 20px 0 16px;width:auto;
  i.icon-aqua{margin:4px 13px 0 -5px;
  line-height: 20px;
  vertical-align: top;
  display: inline-block;
  }
  }

}

.btn-2 {
  width: 192px;
  height: 30px;
  line-height:28px;
  font-size: 14px;
}

.btn-3 {
  width: 220px;
  height: 40px;
  line-height: 38px;
  font-size: 16px;
  &.w130{ width: 130px;}
  &.w160{width:160px;}
}

.btn-4 {
  width: 170px;
  height: 40px;
  line-height: 38px;
  font-size: 16px;
  &.w160{ width: 160px;}
  &.w200{ width:200px;}
  &.btn-img{padding:0 26px;width:auto;
  i.icon-aqua{margin:8px 16px 0px -5px;line-height: 25px;
  vertical-align: top;
  display: inline-block;
  }
  }
}

.btn-5 {
  width: 160px;
  height: 40px;
  line-height: 38px;
  font-size: 16px;
}

.btn-6 {
 width:110px;
 height:20px;
 @extend .sans-bold;
 font-size: 12px;
 line-height: 1.25;
 padding:2px 0 0 9px;
&.w90{width:90px;}
&.w125{width:125px;}
  }
  .btn-9{
  	  height:30px;
	  line-height:30px;
	  width:100%;
}


.btn-grey {
 @extend .button;
 color: var(--white);
 background-color: var(--grey-3);
 border-radius: 100px;
 border:none;
 text-transform: capitalize;
 text-align:left;
 position:relative;
   &:hover {
    background: var(--grey-3);
    color: var(--white);
    text-decoration: none;
  }
  &:focus{box-shadow: 0 1px 2px 0 rgba(0,0,0,0.25);outline:none;}
  span{
 margin: 0 0 0 5px;
 display:inline-block;
 }
  .close{
  /*  background:url("../../images/close-white-small.svg") no-repeat 3px 3px;*/
    display: inline-block;
    height:14px;
    width:14px;
	margin:0;
	border-radius: 100px;
	padding:3px;
	position:absolute;
	right:4px;
	top:3px;
	&:hover{
 background:/*url("../../images/close-white-small.svg") no-repeat 3px 3px*/ #5b6e8c;
 }
 }
  }
.btn-7{
 width:126px;
 height:30px;
  font-size: 14px;
line-height: 27px;
&.w112{width:112px;}
}
.btn-8{
 width:149px;
 height:30px;
   font-size: 14px;
line-height: 27px;
}
.btn-10{
 width: 143px;
  height: 30px;
     font-size: 14px;
line-height: 27px;

}
.btn-11{
	width:100%;
	text-align: center;
	 height:20px;
	 @extend .sans-bold;
	 font-size: 12px;
	 line-height: 1.25;
	 padding:2px 0 0;
	 margin:0;
}

.btn-grp {
    text-align: center;
    margin-top: 20px;
    border-top: 1px solid var(--grey-5);
    padding-top: 20px;
&.btn-grp2{
    margin-top:32px;
}
&.mt30{margin-top:30px;}
&.mt40{margin-top:40px;}
}
.btn-grp3 {
    text-align: center;
    margin-top: 30px;
	.btn-3{margin:0 5px;}
}
.btn-grp1 {
    text-align: center;
    margin-top: 29px;
    border-top: 1px solid var(--grey-5);
    padding-top: 20px;
}
.btn-aqua.btn-1{
&.btn-img{
 width: auto;
padding: 0 20px 0 16px;
i.icon-aqua{
 margin:4px 4px 0 -2px;
}
/*img, svg{
    float: left;
    margin:4px 4px 0 -2px;
&.policy{margin:4px 5px 0;}
	}*/
	/*&.w175{
	img{margin:4px -1px 0 -5px;}
	}*/
	}
/*&.w152{
&.btn-img{
padding: 0 16px 0 18px;
img{margin:4px 0 0;}
}
}
*/}
.vcal-btn {
 @extend .sans-regular;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.25;
  color: var(--white);
  background:#2ac3a1;
  top: 166px;
  height: 75px;
    left: 0;
	width: 22px;
	border-radius:0 3px 3px 0;
	 position:fixed;
	  z-index:99;
	 display: block;
  cursor: pointer;
span{  display: block;
    text-align: center;
    transform: rotate(-90deg);
    vertical-align: middle;margin:35px 0 0 0;text-transform: uppercase;}
	&:hover{ text-decoration:none;}
	&.none{display:none;}
}

.download{ width: 30px;
  height: 30px;
  display: inline-block;
  margin:0 0 0 20px;
  img, svg{display: inline-block;margin: 4px 0 0 0;}
  }



.btn-blue {
 @extend .button;
 color: var(--white);
 background: #4bb7f5;
 border-radius: 100px;
 border:none;
 text-transform:none;
 text-align:left;
 position:relative;
 padding:3px 10px 3px 15px;
 margin: 0 6px 0 0;
 float: left;
 /*&:first-child{margin-left:0;}*/
   &:hover {
    background: #4bb7f5;
    color: var(--white);
    text-decoration: none;
  }
  span{
 margin: 0 0 0 5px;
 display:inline-block;
 }
  .btn-close{
    background:url("../../images/close-white-small.svg") no-repeat 3px 3px;
    display: inline-block;
    height:14px;
    width:14px;
	margin: 0 0 -2px 5px;
	border-radius: 100px;
	padding:2px;
	&:hover{
 background:url("../../images/close-white-small.svg") no-repeat 3px 3px #38a3e1;
 }
 }
  }
.btn-aqua.btn-1.btn-img.w156{padding:0 16px 0 15px;}
