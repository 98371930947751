.toggle_radio {
  position: relative;
  background:var(--aqua);
  margin:18px auto 0;
  padding: 0 !important;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  position: relative;
  height:9px;
  width: 200px;float: right;
}

.toggle_radio > * {
  float: left;
}

.toggle_radio input[type=radio] {
  display: none;
  /*position: fixed;*/
}

.toggle_radio label {
  z-index: 0;
  display: block;
  width:7px;
  height:7px;
  margin:1px 1px;
   background:rgba(0,0,0,0.3);
  -webkit-border-radius:100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  cursor: pointer;
  z-index: 1;
  /*background: rgba(0,0,0,.1);*/
  text-align: center;
  /*margin: 0 2px;*/
  /*background: blue;*/
  /*make it blue*/
}

.toggle_option_slider {
  /*display: none;*/
  /*background: red;*/
  width:7px;
  height:7px;
  position: absolute;
  top:1px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  -webkit-transition: all .4s ease;
  -moz-transition: all .4s ease;
  -o-transition: all .4s ease;
  -ms-transition: all .4s ease;
  transition: all .4s ease;
}
.toggle_radio label.first{left:0px;position: absolute;}
.toggle_radio label.second{left:41px;position: absolute;}
.toggle_radio label.third{left:82px;position: absolute;}
.toggle_radio label.fourth{left:191px;position: absolute;}

#fourth_toggle:checked ~ label.fourth, #third_toggle:checked ~ label.third, #second_toggle:checked ~ label.second, #first_toggle:checked ~ label.first{}
#first_toggle:checked ~ .toggle_option_slider {
  left:1px;
  background:rgba(0,0,0,0.1);
}

#second_toggle:checked ~ .toggle_option_slider {
  left:41px;
  background:rgba(0,0,0,0.1);
}

#third_toggle:checked ~ .toggle_option_slider {
  left:83px;
  background:rgba(0,0,0,0.1);
}
#fourth_toggle:checked ~ .toggle_option_slider {
  left: 192px;
  background:rgba(0,0,0,0.1);
}

.toggle_radio span{position: absolute;top:-41px;left: 0;color: var(--grey-2);}
#fourth_toggle:checked ~ label.fourth span, #third_toggle:checked ~ label.third span, #second_toggle:checked ~ label.second span, #first_toggle:checked ~ label.first span{
      background: url(../../images/time-slider-shape.svg) no-repeat 0 0;
      width:18px;
      height:25px;
      display:inline-block;
      margin:0;
	  color:var(--white);line-height: 19px;top:-29px;line-height: 22px;position: absolute;
	}
#first_toggle:checked ~ label.first span{	
	left: -6px;
}
#second_toggle:checked ~ label.second span{
left: -6px;	
}
#third_toggle:checked ~ label.third span{
	left: -6px;
}
#fourth_toggle:checked ~ label.fourth span{
	left: -6px;
}