table {
  background-color: rgba(0, 0, 0, 0);
}

caption {
  color: #777777;
  padding-bottom: 8px;
  padding-top: 8px;
  text-align: left;
}

th {
	text-align: left;
	text-transform: uppercase;
}

.table {
	margin-bottom: 20px;
	max-width: 100%;
	width: 100%;
	border-top: none;

	> {
		tbody > tr > {
			td {
				border: none;
				line-height: 1.42857;
				padding: 13px 22px 11px 22px;
				vertical-align: middle;
				border-bottom: 1px solid var(--grey-5);
				white-space: nowrap;

				&:first-child {
					padding: 13px 22px 11px 10px;
				}

				&.status {
					width: 1%
				}

			}
		}

		tfoot > tr > {
			td, th {
				line-height: 1.42857;
				padding: 15px 22px;
				vertical-align: top;
			}
		}

		thead > tr > {
			td {
				line-height: 1.42857;
				padding: 15px 22px;
				vertical-align: top;
			}

			th {
				line-height: 16px;
				padding: 7px 22px;
				background: var(--light-blue);
				vertical-align: middle;
				border: none;
				color: var(--grey-2);
				font-size: 12px;
				font-weight: 600;
				box-shadow: 0 1px 0 0 var(--grey-5);
				position: relative;
				z-index: 0;

				&:first-child {
					padding: 7px 22px 6px 10px;
					border-radius: 3px 0 0 3px;
				}

				&:nth-child(3) {
					padding-right: 0;
				}

				&:last-child {
					//padding:7px 22px 6px 10px;
					border-radius: 0 3px 3px 0;
				}

			}
		}

		thead:first-child > tr:first-child > {
			td, th {
				border-top: 0 none;
			}
		}

		tbody + tbody {
			border-top: 2px solid #dddddd;
		}
	}

	.table {
		background-color: var(--white);
	}
}

.table-condensed > {
  tbody > tr > {
    td, th {
      padding: 5px;
    }
  }
  tfoot > tr > {
    td, th {
      padding: 5px;
    }
  }
  thead > tr > {
    td, th {
      padding: 5px;
    }
  }
}

.table-bordered {
  border: 1px solid #dddddd;
  > {
    tbody > tr > {
      td, th {
        border: 1px solid #dddddd;
      }
    }
    tfoot > tr > {
      td, th {
        border: 1px solid #dddddd;
      }
    }
    thead > tr > {
      td, th {
        border: 1px solid #dddddd;
      }
      td, th {
        border-bottom-width: 2px;
      }
    }
  }
}

.table-striped > tbody > tr:nth-of-type(2n+1) {
  background-color:none;
}
.table > tbody > tr:hover {
  background:var(--light-blue);
  cursor:pointer;
}
.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}
.table--nohover > tbody > tr:hover{
  background:inherit;
  cursor: default;
}

table {
  col[class*="col-"] {
    display: table-column;
    float: none;
    position: static;
  }
  td[class*="col-"], th[class*="col-"] {
    display: table-cell;
    float: none;
    position: static;
  }
}

.table > {
  tbody > tr {
    &.active > {
      td, th {
        background-color: #f5f5f5;
      }
    }
    > {
      td.active, th.active {
        background-color: #f5f5f5;
      }
    }
  }
  tfoot > tr {
    &.active > {
      td, th {
        background-color: #f5f5f5;
      }
    }
    > {
      td.active, th.active {
        background-color: #f5f5f5;
      }
    }
  }
  thead > tr {
    &.active > {
      td, th {
        background-color: #f5f5f5;
      }
    }
    > {
      td.active, th.active {
        background-color: #f5f5f5;
      }
    }
  }
}

.table-hover > tbody > tr {
  &.active:hover > {
    td, th {
      background-color: #e8e8e8;
    }
  }
  &:hover > .active {
    background-color: #e8e8e8;
  }
  > {
    td.active:hover, th.active:hover {
      background-color: #e8e8e8;
    }
  }
}

.table > {
  tbody > tr {
    &.success > {
      td, th {
        background-color: #dff0d8;
      }
    }
    > {
      td.success, th.success {
        background-color: #dff0d8;
      }
    }
  }
  tfoot > tr {
    &.success > {
      td, th {
        background-color: #dff0d8;
      }
    }
    > {
      td.success, th.success {
        background-color: #dff0d8;
      }
    }
  }
  thead > tr {
    &.success > {
      td, th {
        background-color: #dff0d8;
      }
    }
    > {
      td.success, th.success {
        background-color: #dff0d8;
      }
    }
  }
}

.table-hover > tbody > tr {
  &.success:hover > {
    td, th {
      background-color: #d0e9c6;
    }
  }
  &:hover > .success {
    background-color: #d0e9c6;
  }
  > {
    td.success:hover, th.success:hover {
      background-color: #d0e9c6;
    }
  }
}

.table > {
  tbody > tr {
    &.info > {
      td, th {
        background-color: #d9edf7;
      }
    }
    > {
      td.info, th.info {
        background-color: #d9edf7;
      }
    }
  }
  tfoot > tr {
    &.info > {
      td, th {
        background-color: #d9edf7;
      }
    }
    > {
      td.info, th.info {
        background-color: #d9edf7;
      }
    }
  }
  thead > tr {
    &.info > {
      td, th {
        background-color: #d9edf7;
      }
    }
    > {
      td.info, th.info {
        background-color: #d9edf7;
      }
    }
  }
}

.table-hover > tbody > tr {
  &.info:hover > {
    td, th {
      background-color: #c4e3f3;
    }
  }
  &:hover > .info {
    background-color: #c4e3f3;
  }
  > {
    td.info:hover, th.info:hover {
      background-color: #c4e3f3;
    }
  }
}

.table > {
  tbody > tr {
    &.warning > {
      td, th {
        background-color: #fcf8e3;
      }
    }
    > {
      td.warning, th.warning {
        background-color: #fcf8e3;
      }
    }
  }
  tfoot > tr {
    &.warning > {
      td, th {
        background-color: #fcf8e3;
      }
    }
    > {
      td.warning, th.warning {
        background-color: #fcf8e3;
      }
    }
  }
  thead > tr {
    &.warning > {
      td, th {
        background-color: #fcf8e3;
      }
    }
    > {
      td.warning, th.warning {
        background-color: #fcf8e3;
      }
    }
  }
}

.table-hover > tbody > tr {
  &.warning:hover > {
    td, th {
      background-color: #faf2cc;
    }
  }
  &:hover > .warning {
    background-color: #faf2cc;
  }
  > {
    td.warning:hover, th.warning:hover {
      background-color: #faf2cc;
    }
  }
}

.table > {
  tbody > tr {
    &.danger > {
      td, th {
        background-color: #f2dede;
      }
    }
    > {
      td.danger, th.danger {
        background-color: #f2dede;
      }
    }
  }
  tfoot > tr {
    &.danger > {
      td, th {
        background-color: #f2dede;
      }
    }
    > {
      td.danger, th.danger {
        background-color: #f2dede;
      }
    }
  }
  thead > tr {
    &.danger > {
      td, th {
        background-color: #f2dede;
      }
    }
    > {
      td.danger, th.danger {
        background-color: #f2dede;
      }
    }
  }
}

.table-hover > tbody > tr {
  &.danger:hover > {
    td, th {
      background-color: #ebcccc;
    }
  }
  &:hover > .danger {
    background-color: #ebcccc;
  }
  > {
    td.danger:hover, th.danger:hover {
      background-color: #ebcccc;
    }
  }
}
.table > thead:first-child > tr > th.first, .table > tbody > tr > td.first{width:20px; padding-right:0;}
.table .btn-group{margin:0;
&.margin-l10{margin-left:10px;}
}
.table > tbody > tr > td{
color:var(--grey-1);
font-size:14px;
line-height:18px;
}
.panel-footer{
	margin-bottom:20px;
	&.panel-footer--has-device-sec{
		margin-bottom:90px;
	}
width:100%;
display:inline-block;
    color: var(--grey-3);
    @extend .sans-regular;
    line-height: 1.43;
      .btn.btn-default.btn-select{
	 	 width:80px;
		 min-width:64px;
         border: 1px solid #c6ced8;
		 background:var(--white);
		 margin:0;
		 color:var(--grey-2);
.btn-select-value{
line-height:13px;
background:var(--white);
color:var(--grey-2);
@extend .sans-bold;
padding:7px 6px 7px 12px;
}
.btn-select-arrow{
padding:4px 12px 4px 6px;
background:var(--white);
color:var(--grey-2);
}
ul{
 top:auto;
 bottom:0;
 max-height:137px;
 li{
 color:var(--grey-2);
 @extend .sans-bold;
 line-height: 13px;
 padding:7px 12px;
 margin:0;
 width:100%;
}
}

}
.pagination{
color: var(--grey-1);
line-height: 27px;
}
    li{
	float: left;
	margin:0 5px;
 a{
border: solid 1px var(--grey-4);
float: left;
border-radius: 3px;
height: 28px;
width: 28px;
padding: 3px;
&:hover{border: solid 1px var(--aqua);}
}
	}
.chevron-left{
float:left;
}
.chevron-right{
float:left;
}

}
.pagination-text{
color: var(--grey-1);
    line-height: 27px;
	margin:0 10px 0 0;
.page-text{color:var(--grey-3);
margin:0 0 0 5px;
}
}

tr {
	.dropdown-toggle {
		opacity: 0;

		&:hover {
			.icon {
				fill: var(--grey-3);
			}
		}
	}

	div.materialise {
		opacity: 0;
	}

	&:hover {
		.dropdown-toggle {
			opacity: 1;
		}

		div.materialise {
			opacity: 1;
		}
	}

}


.filter-btnsec{
 margin:15px 0;
 .button{
 margin:0 5px;
 }
}


.title-box {
margin:0 0 20px;
&.paddtb30{margin:0;}
h2 {
@extend .sans-bold;
  font-size:25px;
  line-height: 1.33;
  color: var(--grey-2);
  display: inline-block;
  margin:0;
  &.font-24{font-size:24px;line-height: 1.21;}
  &.font-20{@extend .font-20b;}

  .capsule{
    min-width:20px;
    top:-2px;
    margin-left:2px;
  }

}
.btn-aqua{margin:0 0 0 10px;}
}
#teams-table.table > tbody > tr > td.team a{
  color:var(--grey-1);
  &:hover{
    text-decoration:underline;
    color:var(--grey-1);
  }
}

.table > tbody > tr > td.open{ position:relative;


}

 .table-sec {
 padding:0 30px;
  .dropdown-toggle{
  padding:6px 12px 6px 10px;
  &::after {
    margin-left:0.8em;
  }
  }
   .dropdown-menu{
    position: absolute;
    left: inherit;
    right: 25px;
    top:20px;
    width: 200px;
	padding:10px 0px;
	 border: solid 1px var(--aqua);
	.dropdown-item{
	padding: 12px 27px 13px;
	&:hover{
	background: var(--light-blue)!important;
	text-decoration:none;
	}
	}
	}
	}
.panel-table{ position: relative;
/*padding:0;margin:0;*/
left: 0;
    top: 0;
    bottom: 0;
	@extend .animation;
    right: 0;
	/* position:absolute;*/

&.full-width{
padding:30px 0 0;
}
.table-right{
/*width:87%;*/
display:inline-block;
padding:30px 30px 0 30px;position: absolute;left:220px;
    right: 0;
    top: 0;
&.padd-right{
/*padding-right:15px;*/
}
}
}
.table-main{
/*overflow-x: hidden;
overflow-y: scroll;
height: 630px;*/
&.right{
padding:30px 0 0 30px;
}
}



.panel-top{
width:100%;
display:inline-block;
position: relative;
//z-index: 995;
@extend .sans-regular-italic;
color: var(--grey-1);
margin:0 0 20px;
&.mart18{margin-top:18px;}
.btn-group{
margin:0 0 0 10px;
.btn.btn-default.btn-select{
width:95px;
min-width:95px;
border: 1px solid #c6ced8;
background:var(--white);
margin:0;
color:var(--grey-2);
.btn-select-value{
text-transform: uppercase;
padding:0px 8px;
line-height:29px;
background:var(--white);
color:var(--grey-2);
@extend .sans-bold;
right: 22px;
}
.btn-select-arrow{
padding:4px 8px 5px;
background:var(--white);
color:var(--grey-2);
}
}
}
.setting-box{
position:relative;
margin:0;
float: right;
.columns-btn{
float:left;
margin:2px 10px 0 0;
 width:30px; height:30px;
 padding:0;text-align: center;
}
.filter-btn{
margin:2px 0 0 0;
 width:30px;
 height:30px;
 padding:0;
 float: left;
 text-align: center;
 svg{margin: 5px 0 0 0;}
  img{
  padding:5px 0 0 5px;
 &.active{
 display:none;
 padding:0;
 }
 }
&.last.current{
background: var(--light-blue);
 width:30px;
 height:30px;
 border-radius:20px;
 i{ background: url(../../images/filters-hover.svg) no-repeat 0 0;}
   img{
    display:none;
 &.active{
   padding:5px 0 0 5px;
 display:block;
 }
 }
   }
   }
.columns-btn{
svg{margin: 5px 0 0 0;}
  img{
  padding:5px 0 0 5px;
 &.active{
 display:none;
 padding:5px 0 0 5px;
 }
 }
&.current{
background: var(--light-blue);
 width:30px;
  height:30px;
   border-radius:20px;
    padding:0;
	margin:2px 10px 0 0;
	text-align: center;
	.columns{background: url(../../images/columns-hover.svg) no-repeat 0 0;}

	img{
	display:none;
 &.active{
display:block;
 }
 }


}
}
}
}

.name-text,
.tree-text{
  svg, img{
  /* float:left;*/ display:inline-block;
  margin:0px 10px 0 0;
  }
  span{
  @extend .sans-regular;
  /*float:left;*/
  }
  span.bold{
  @extend .sans-bold;
  /*float:left;*/
  }
}

.tree-text{

  &--lvl2{
    padding-left:77px !important;
  }
  &--lvl3{
    padding-left:107px !important;
  }

  .expander{
    margin:5px 15px 5px 5px;
    transform:rotate(-90deg);
    transition:transform .25s ease;
    &--expanded{
      transform:rotate(0);
    }
  }

}




.table > tbody > tr > td.team a {
    color: var(--grey-1);
}
td a.dropdown-toggle{ padding:0;}

.device-sec-main{
/*width:100%; position:relative; z-index:999;*/
.device-sec{
/*width:100%;*/
position: fixed;
    text-align: center;
    margin:0;
     padding: 20px 0;
     box-shadow:0 0 7px 0 rgba(0,0,0,0.1), inset 0 -1px 0 0 #e0e5e9;
    z-index: 999;
bottom: 0;
left:0;right:0;
background: #fff;
height: 70px;@extend .animation;min-width: 1199px;
&.dev-width{
margin-left:320px;
.middle-btn{/*width:80%;*/@extend .animation;}
}
&.dev-inv{
margin-left:220px;
.middle-btn{/*width:80%;*/}
}
.device-no{
position: absolute;left:30px;
    z-index: 1;
padding: 0 0 0 0;
  display: inline-block;
  float: left;
  color: var(--grey-3);
  @extend .sans-bold;
  font-weight: normal;
  line-height: 31px;
  text-align: left;
  min-width:475px;
  span{
  width: 28px;
  height: 20px;
  border-radius: 100px;
  background-color: var(--grey-3);
  text-align:center;
   color: var(--white);
   display: inline-block;
   float: left;
   font-weight: 600;
  margin:6px 10px 0 0;
    line-height:18px;
&.bg-aqua{background:var(--aqua)}
}
/*&.device-width{ width:70%;}*/
}
/*&.device-width{ width:70%;}*/
  &.dev-slide{margin-right: 260px;
   /*.device-width{ width:70%;margin-right:260px;}*/
   }

}
.middle-btn{
	display:inline-block;
    margin: 0 auto;
    text-align: center;
	/*display: table;
    width: 100%;
	position: absolute;
	top:20px;
	z-index: 0;*/
	@extend .animation;
 .btn-aqua{
  text-align:left;
 svg{float:left; margin:4px 7px 0 15px;}
 }
 &.btnimg{
 a{margin:0 30px 0 0;
/* &:last-child{margin:0;}*/
&:hover{text-decoration:none;}
 }

 }
  }
  .device-width{ width:100%;display:inline-block;}
  }

.building{float:left;margin:0;}
/*.right-table .device-sec{ position:relative;}*/
#admin .table {
}

.toggle-sec.acdetail {
	.table {
		margin: 0 0 0 57px;
	}

	h2 {
		margin: 0 0 15px;
		padding: 0;
		vertical-align: top;
		@extend .font-20b;
		display: inline-block;
	}

	img, .left-icon {
		margin: 0 28px 0 0;
	}

	svg.icon {
		margin: 0 28px 0 0;
	}
}

.toggle-sec {
	.table {
		width: 60%;

		thead > tr > th:first-child {
			padding: 7px 22px;
		}

		tr {
			td {
				color: var(--grey-1);
				@extend .sans-regular;
				position: relative;
				padding: 20px 0 18px 0;

				&:first-child {
					color: var(--grey-1);
					@extend .sans-bold;
				}

				//&:last-child {
				//	text-align: right;
				//	padding-right: 22px;
				//}
			}
		}
	}

	&.panel-table {
		padding: 0;
		position: relative;

		.panel-top {
			margin: 3px 0 23px;
		}

		.table {
			width: 100%;

			tr {
				td {
					padding: 14px 22px 12px;
					font-size: 14px;

					&:first-child {
						padding: 14px 22px 12px 20px;
					}
				}
			}
		}
	}
}

.table.cus-detail{
width:480px;
margin:0 auto 20px;
tr:hover{
background:none;
}
td {
line-height: 27px;
@extend .sans-1-regular;
padding: 15px 0;
.icon-pending{
margin: 5px 10px 0 0;
float:left;
}
&:first-child{
@extend .sans-1-bold;
}
&:last-child{
padding-right:0;
}

a{
color:var(--aqua);
&.save{
  width: 55px;
  height: 30px;
  color:var(--white);
  line-height:28px;
  margin:0;
}
&.edit{
margin:5px 15px 0 0;
float: right;
}
}
}
}


.panel-table.right{
padding:0px 0 30px 0px;
}

.panel-table .table.storage
 > thead
  > tr
   >
    th{
	padding:6px 10px 6px 10px;
	/*text-align: center;*/
	 &.last{
	 padding:6px 15px 6px 0;
	 }
	 &.paddl25{padding-left:25px;}
	}
.panel-table .table.storage
 > tbody
  > tr
   > td {
   padding:20px 10px 19px;
  /* text-align: center;*/
   &.name-text{
padding: 20px 0 19px 15px;
    text-align: left;
    width: 20%;
img{
float:left;
margin:8px 10px 0 0;
}
span{
@extend .sans-bold;
text-align: left;
float:none;
/*min-width:150px;*/
&.small-text{
@extend .sans-regular;
font-size:12px;
color: var(--grey-3);
line-height:1.8;
}
&.normal-text{
font-weight:normal;
}
}
dl{margin:0 0 0 30px; padding:0;
@extend .sans-regular;
font-size:12px;
color: var(--grey-3);
line-height:1.8;
}
}
    &.last{
	 padding:24px 22px 24px 22px;
	 }
	  &.paddl25{padding-left:25px;}
   }
.container-table {
    width: 100%;
    height: 100%;
    //height: calc(100% - 91px);

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: moz-none;
    -ms-user-select: none;
    user-select: none;
	position:relative;
	//margin-top:91px;
  padding-top:91px;
  box-sizing: border-box;-moz-box-sizing: border-box;/*overflow: hidden;*/
}
#main-table{
 display: table;
    position: relative;
    width: 100%;

}


#device-active{
.table{
 > {
    tbody > tr{
	&:last-child td{ border:none;}
	&:hover{background:none;}
	 td { padding:7px 0 6px 10px; line-height: 14px;text-align: left;
	  &:first-child{padding:7px 0 6px 10px; width:40px;}
	  &:last-child{font-weight:600;font-size: 16px;text-align: right;padding:7px 10px 6px 0;}

	  }
	}

}
}

}
.dis-container{
&.dis-width{
/*width:89%;
float:left;*/ position:absolute;right:0; left:0;
}
}
#abc{display:table;width:100%;}
.stretch-right-table{
  display:flex;
  flex-direction: column;
  flex:1 0 510px;
}
#right-table{
  position:relative;
  width:100%;
  flex:1 0 510px;
  display:flex;
  flex-direction: column;
&.fullwidth{width:100%; left:0;}
}
.main-container{height:100%;}
.table .w115{ width:115px; text-align:right;
&.paddr15{padding-right:15px;}
}
.table .w73{ width:73px;
&.paddr15{padding-right:25px;}
}

.table-main tr td.status i, .table-main tr td.status i.check.aquabg, .table-main tr td.status i.check.greybg {
	margin: 0;
}

.table {
	&.hardware {
		margin: 0 0 30px;

		thead > tr > th:first-child {
			padding-left: 20px;
		}

		tbody > tr > td {
			padding: 15px 22px 15px 22px;

			&:first-child {
				padding-left: 20px;
			}
		}
	}
}

.table {
	&.table1 {
		margin: 0 0 30px;

		thead > tr > th {
			padding: 7px 5px 7px 5px;

			&:first-child {
				padding: 7px 5px 7px 20px;
			}

			&:last-child {
				padding: 7px 20px 7px 5px;
			}
		}

		tbody > tr > td {
			padding: 15px 5px 15px 5px;

			&:first-child {
				padding: 15px 5px 15px 20px;
			}

			&:last-child {
				padding: 15px 20px 15px 5px;
			}
		}
	}
}

#tables1.sync{/* margin-right:260px; */ @extend .animation;}
#discoverd.dis-width{margin-left:320px;@extend .animation;}
#discoverd.sync{margin-right:245px;@extend .animation;}
.pending .clock-o{margin-bottom:-4px;}
a.mail{ color: var(--grey-1);}
a.link-db{ color: var(--dark-blue);}




.table.storage{
thead > tr > th{padding-left:11px;padding-right:11px;max-width: 94px;}
thead > tr > th.storage{width:20%;}
thead > tr > th:first-child {
    padding: 7px 13px 6px 18px;
}
tbody > tr > td{padding-left:11px;padding-right:11px;max-width: 94px;}
tbody > tr > td.name-text{width:20%;
span{width:150px;}
}
tbody > tr > td.text-right.ip{}
}

.policies{
display: inline-block;width:100%;
ul{width:100%;
border-radius: 3px;
box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
padding:22px 15px 22px 20px;
margin:0 0 20px;
color: var(--grey-1);
	&:hover{
	background: var(--light-blue);
	cursor: pointer;
	}

li{
display: inline-block;
@extend .sans-regular;
line-height:1.43;
vertical-align: top;
height: 36px;
&:first-child{width: 30%;min-width:409px;
span{color: var(--grey-1);}
}
&.text-icon{width:30%;min-width:409px;border: solid 1px #e0e5e9;border-top:none;border-bottom:none;text-align: center;
span{margin:8px 6%;font-weight:normal;}
}
&.icon{width:23%;min-width:253px;margin: 0;text-align: center;
span{margin:8px 8%; cursor: pointer;}
}
&.text{
  width:30%;
  span{
    margin: 8px 8%;
    font-weight:normal;
  }
}
img, svg{vertical-align: top;margin:0 6px 0 0;}
dd{
display: inline-block;
margin: 8px 10px 0 0;
float: left;
padding: 0;
vertical-align: top;
position:relative;
img{margin:0;}
  span.on{
    position:absolute;
    left:-3px;
    top:-4px;
    width:10px;
    height:10px;
    border-radius:50%;
    background-color:var(--aqua);
    border:1px solid var(--white);
  }
}
span{font-weight:600;display: inline-block;}
p{@extend .font-12;color: var(--grey-3);line-height:1.3;}
&.last{@extend .pos-rel;float:right;
a{border:none; margin-top:5px;
  &::after{display:none;}
  &:hover{
    color:var(--grey-2);
  }
}
.dropdown-menu{
left: inherit;right:0;top: 0;width: 200px;padding: 10px 0px;
i {
    float: left;
    margin: 0 10px 0 0;
}
.dropdown-item {
    padding: 10px 27px 10px;
    line-height: 20px;
&:hover {
    background: var(--light-blue) !important;
    text-decoration: none;
}
&.inactive{
 	color: #a0abbb;
	.pencil{opacity:0.7;}
 	&:hover{
 	background: var(--white)!important;
 	}
	}
}
}

}
}

}


}


.toggle-sec.acdetail .table.account {
	tr td:first-child {
		width: 38%;
	}

	tr:hover {
		background: none;
		cursor: inherit;
	}

	tbody > tr > td {
		padding-top: 0;
		padding-bottom: 0;
		height: 57px;
	}
}

.with-inputs {

	> {
		tbody > tr > {
			td {
				border: none;
				padding: 5px 0;

				&:first-child {
					padding: 5px 22px;
				}
			}

			tr {
				td {
					padding: 5px 0;
				}
			}

		}
	}
}

.table-main{
  &--has-device-sec{
    margin-bottom:80px;
  }
}
.table-scroll-wrap{position:relative;}
.table-scroll{
	min-height: .01%;
	margin-bottom:20px;
	.table{
		margin-bottom:0;
	}
}

.table-scroll-wrap--overflow{
  &:before,
  &:after{
    background: linear-gradient(to right, rgba(0,0,0,0.035) 0%,rgba(0,0,0,0) 100%);
    content: "";
    display: block;
    height: 100%;
	  height: calc(100% - 17px);
    position: absolute;
    left:0;
    top: 0;
    width: 7px
  }
  &:after{
    background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.035) 100%);
    left:auto;
    right: 0;
  }
  .table-scroll{
    overflow-x: auto;
    .table{
      margin-bottom:16px;
    }
  }
}



.table-scroll1{ height:330px; overflow-y:scroll;}
.table{
&.table2{
thead > tr > th{
padding:5px 20px 5px 20px;
&:first-child{padding:5px 20px 5px 20px;}
&:last-child{padding:5px 20px 5px 20px;}
}
tbody > tr > td{padding:5px 20px 5px 20px;
&:first-child{padding:5px 20px 5px 20px;}
&:last-child{padding: 5px 20px 5px 20px;}
}
}
}
.table-container{
  position:relative;
  overflow: hidden;
  flex:1 0 auto;
}

.trusted-device {
	&--device {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
}
