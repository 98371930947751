.vault-section{
  background-image: linear-gradient(to bottom, #fff, #F4F8FA);
  box-shadow: inset 0 -1px 0 0 #e0e5e9;

  &--private{
    background:transparent;
    box-shadow: none;
  }

  &__header{
    padding:30px 0 20px;
    .title-box{
      display:inline-block;
      margin-bottom:0;
    }
    .setting-box{
      float:right;
      position: relative;
      .download{
        margin-left:10px;
      }
    }
  }



}

.vaults{
  display: inline-block;
  width:100%;

  &__item{
    display:flex;
    width:100%;
    border-radius: 3px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
    padding:20px 0 20px;
    margin:0 0 20px;
    color: var(--grey-1);
    background:var(--white);
    &:hover{
      background: var(--light-blue);
      //cursor: pointer;
    }


    li{
      @extend .sans-regular;
      line-height:1.43;
      vertical-align: top;
      height: 40px;
    }

    img,
    svg{
      vertical-align: top;
      margin:0 5px 0 0;
    }
    p{
      @extend .font-12;
      color: var(--grey-3);
      line-height:1.3;
    }


    &--empty{
      display:block;
      padding: 25px 0 25px;
      background:transparent;
      border: dashed 1px var(--grey-4);
      box-shadow: none;
      &:hover{
        background:inherit;
        cursor: default;
      }
      li{
        height:auto;
      }
    }


  }

  &__empty{
    height:auto;
    text-align: center;
    svg{
      margin:0;
    }
  }


  &__title{
    display:block;
    min-width:0px;
    flex:42 0 400px;
    padding-left:15px;
  	font-size:14px;
    span{
      display:inline-block;
      color: var(--grey-1);
      font-weight:600;
    }
    dd{
      display: inline-block;
      position:relative;
      margin: 0 5px 0 0;
      float: left;
      padding: 0;
      vertical-align: top;
      &.is-unavailable{
        &:after{
          content:"";
          position:absolute;
          top:0; left:0;
          width:10px;
          height:10px;
          border:2px solid var(--white);
          background-color: var(--grey-4);
          border-radius:50%;
        }
      }
      &.is-active{
        &:after{
          content:"";
          position:absolute;
          top:0; left:0;
          width:10px;
          height:10px;
          border:2px solid var(--white);
          background-color: var(--aqua);
          border-radius:50%;
        }
      }
      &.is-warning{
        &:after{
          content:"";
          position:absolute;
          top:0; left:0;
          width:10px;
          height:10px;
          border:2px solid var(--white);
          background-color: var(--yellow);
          border-radius:50%;
        }
      }
      &.is-critical{
        &:after{
          content:"";
          position:absolute;
          top:0; left:0;
          width:10px;
          height:10px;
          border:2px solid var(--white);
          background-color: var(--orange);
          border-radius:50%;
        }
      }
    }
  }

  &__text-icon{
    display: flex;
    flex:21 0 260px;
    justify-content:space-around;
    padding:0 10px;

    border: solid 1px #e0e5e9;
    border-top:none;
    border-bottom:none;
    text-align: center;
    span{
      margin:10px;
      flex:1;
    }
  }

  &__info{
    display:flex;
    flex:37 0 380px;
    &-item{
      flex:1;
      height:40px;
      text-align: center;
      border-left: solid 1px #e0e5e9;
      &:first-child{
        border-left:none;
      }
      span{
        display:block;
        font-size:20px;
        font-weight:600;
        line-height:25px;
        &.na{
          color: var(--grey-4);
        }
      }
      p{
        text-align: center;
      }

      &--options{
        margin-top:auto;
        flex:0 1 auto;
        padding:9px 25px 0;
        position:relative;
        .dropdown-menu{
          top:0;
        }
      }
    }

  }

  &__loader{
    position:relative;
  }


}


.modal-cloud{
  padding:56px 0 20px;

  &__download{
    display:flex;
    justify-content: space-between;
    padding:0 40px;
    margin-bottom:40px;

    &-item{
      padding:20px 0 0;
      width:190px;
      background:var(--white);
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
      border-radius:4px;

      display:flex;
      flex-direction: column;
      text-align: center;
    }

      &-content{
        padding:0 5px 5px;
        h4{
          margin:0 0 7px;
          font-size:20px;
          color: var(--grey-1);
        }
        p{
          text-align: center;
          font-size:14px;
          margin-bottom:10px;
          &.specs{
            font-size:12px;
          }
        }
      }

      &-img{
        margin-bottom:15px;
        svg{
          width:60px;
          height:60px;
        }
      }

      &-btn{
        margin-top:auto;
        width:100%;
        padding:0 0 20px;
        text-align: center;
      }

  }

}
