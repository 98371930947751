.form-container-card-with-heading.otp {
	@extend .form-container-white;
	padding: 40px;
	margin-top: 24px;
	margin-bottom: 0;
	display: flex;
	flex-direction: column;
	gap: 16px;
	border-radius: 3px 3px 0 0;

	h3 {
		&.title {
			font-size: 24px;
			font-weight: 300;
			margin-bottom: 0;
		}
	}

	p {
		font-size: 14px;
		margin: 0;
	}

	.checkbox-group {
		padding: 0;

		.checkbox {
			margin: 0;
			width: 100%;
		}
	}

	.form-group {
		padding: 0;

		&.error-message {
			@extend .sans-bold;
			align-items: flex-start;
			align-self: stretch;
			border-radius: 4px;
			background-color: var(--action-error-background);
			color: var(--action-error);
			display: flex;
			flex-grow: 1;
			flex-direction: row;
			gap: 12px;
			height: auto;
			justify-content: flex-start;
			line-height: 1.43;
			font-weight: normal;
			padding: 12px 14px;
			margin: 0;
		}
	}
}

.form-container-action.otp {
	@extend .form-container-white;
	border-radius: 0 0 3px 3px;
	border-top: 1px solid var(--grey-5);
	margin: 0 auto;
	padding: 20px 0;
	text-align: center;
}

one-time-pin .otp-group {
	height: 72px;
	align-self: stretch;
	flex-grow: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 8px;
	padding: 0;
	width: auto;
}

input[type="text"] {
	&.otp-field {
		height: 72px;
		flex-grow: 1;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		padding: 12px 10px;
		border-radius: 4px;
		border: solid 1px var(--grey-4);
		background-color: var(--white);
		font-size: 40px;
		font-weight: 600;
		font-stretch: normal;
		text-transform: uppercase;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
		text-align: center;
		color: var(--grey-1);
		width: 0;

		&.invalid {
			background-color: var(--action-warning);
		}

		&.valid {
			background-color: var(--action-active);
		}
	}
}

.link-wrapper {
	height: 20px;
	align-self: stretch;
	flex-grow: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 15px;
	padding: 0;
	border-radius: 3px;
}

.try-another-way {
	@extend .sans-bold;
	height: 20px;
	flex-grow: 1;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: center;
	color: var(--aqua);

	&:hover {
		color: var(--aqua);
	}
}
