.customer-container{
width:1060px;
margin:0 auto;
}
.tab-container{
max-width:960px;
width:100%;
margin:0 auto;
}
.nav-tabs {
  border-bottom: 1px solid var(--grey-5);
  float:left;
  width:100%;
  margin: 0 0 30px;
  > li {
    float: left;
    margin-bottom: -1px;
    > a {
      margin-right: 2px;
      line-height:25px;
      border: 1px solid transparent;
      border-radius:3px;
      float: left;
      @extend .sans-bold;
      text-align: center;
      color:var(--grey-2);
      text-transform: uppercase;
      padding: 10px 30px;
      &:hover, &.nav-link.hover {
      background: none;
      color:var(--aqua);
      text-decoration:none;
      }
      img{margin:0 5px -3px 0;}
    }
    &.active > a {
      &:hover,
      &:focus {
        border-bottom:1px solid var(--white);
        cursor: default;
        text-decoration:none;
      }
    }
    &.disabled > a {
    opacity:0.5;
    &:hover{
     color:var(--grey-2);
     }
     }
  }
  &.nav-justified {
   // @extend .nav-justified;
   // @extend .nav-tabs-justified;
  }
}
.nav-tabs > li.active > a {
  border: 1px solid var(--grey-5);
  border-bottom:1px solid #fff;
  color:  var(--grey-2);
  &:focus, &:hover {
    border: 1px solid var(--grey-5);
    border-bottom:1px solid #fff;
    color: var(--grey-2);
  }
}
.nav-tabs > li > a{
  &.nav-link.inactive:hover{
      color: var(--grey-2);
    }
    }
.tab-content{
height:95px;
&.h-auto{height:auto;}
&.h110{height:110px;}
.tab-pane {
    display: none;
&.active  {
    display: block;
}
}
}
#data-dis{
@extend .font-12;
  font-weight: bold;
  line-height: 1;
  color: var(--grey-1);
  &.nav-tabs
   {
  margin:12px 0 0;
  border-bottom:none;
  
  li {
  width: 50%;
   border-top:1px solid var(--grey-5);
  }
   a
  { padding:0; width: 100%;}
   li.active {
   border-top:4px solid var(--grey-2);
  /*a{padding:0;}*/
  a{border-top:3px solid var(--white);padding:3px 0 0 0;}
   &:hover .tab-warning{
   background: url(../../images/warning-grey.svg) no-repeat 0 0;
}
   }
   a{ border:none; padding:9px 0 0;font-size:12px;line-height: 1;}
}
}
.protection__msg{
  margin:12px 0 0;
  padding:9px 0 0;
  font-weight:600;
  font-size:12px;
  line-height: 1;
  color: var(--grey-1);
  border-top: 1px solid var(--grey-5);
  
  svg{
    margin-right:2px;
    position:relative;
    top:-1px;
  }
  
}
.toggle-sec {
    display: none;
}
#detail{display:block;}
.capsule-btn{
@extend .sans-bold;
line-height: 29px;
width: 100px;
height:30px;
text-align: center;
color:#fff;
text-decoration:none;
display: inline-block;
text-transform: uppercase;
float:left;
&.aqua{
  border-radius: 100px 0 0 100px;
  background: var(--aqua);
  border: solid 1px var(--aqua);
  border-right:none;
}
&.grey{
  border-radius: 0 100px 100px 0;
  background:none;
  border: solid 1px #c6ced8;
  color:var(--grey-2);
  border-left:none;
  &:hover{border: solid 1px var(--aqua); border-left:none; color:var(--aqua);}
}
&:hover{text-decoration:none; }
}
#user-profile-tab{
h3{
@extend .font-20b;
text-align: left;
padding:0 20px;
margin:0 0 10px;
}
ul{
margin:0 20px 16px;
padding:0 0 22px 0;
display: block;
vertical-align:top;
border-top: solid 1px var(--grey-5);
div.title{
.font-25b{ float:right;}
}
li{
margin:0;
padding:14px 0;
@extend .sans-regular;
color: var(--grey-1);
display: block;
border-bottom: solid 1px var(--grey-5);
&:first-child{}
span{
@extend .sans-bold;
width:200px;
display: inline-block;
vertical-align: top;
}
&.title{
img{margin:0 24px -8px 0;}
@extend .sans-1-bold;
color: #5b6e8c;
margin:0;
span{@extend .font-25b; float:right; line-height:25px; text-align:right;}
}
  }
a.text-orange-bold{margin:0 6px 0 0;}
div.title{
img, svg{margin:0px 24px 0px 0;}
@extend .sans-1-bold;
color: #5b6e8c;
margin:20px 0 11px;
span{@extend .font-25b;}
}
&:last-child{ border-bottom:none;}
&.profile{
li{
border:none;
padding:0;
margin:0 0 0 50px;
dd{margin:0 16px 0 0;
    padding: 0;
    display: inline-block;
    width: auto;
span{width:auto;display: block;}
    }
dl{
&.data-size{text-align: right;
    padding: 0;
    margin: 0;
    display: inline-block;
    width: 11%;}
}
}
}
}
}
.capsule-tab{
width:auto;
border:none;
display: inline-block;
float: left;
li{
  
  display: inline;
  float: left;
  border: solid 1px var(--grey-4); border-right:none;
  &.active{background: var(--grey-2);color:var(--white); border-color:var(--grey-2);
  a, a:hover{color:var(--white);}
  &:first-child{border-left: solid 1px var(--grey-2);}
  &:last-child{border-right: solid 1px var(--grey-2);}
  }
  &.active + li{border-left:none;}
  &:first-child{border-radius:100px 0 0 100px;border-left: solid 1px var(--grey-4);
  a{border-radius:100px 0 0 100px;}
  }
  &:last-child{border-radius:0 100px 100px 0;border-right: solid 1px var(--grey-4);
  a{border-radius:0 100px 100px 0;}
  }
  a{
  @extend .sans-bold;
  color: var(--grey-2);
  text-transform: uppercase;
  padding:5px 20px;
  border:none;
  text-decoration: none;
  float: left;
  &:hover{color:var(--aqua);}
  }
  
  .capsule{
    margin-left:2px;
  }
}
}
.profile-wrap{width:50%;display: inline-block;float:left;}
.tab-wrap{
  width:1200px; margin:0 auto;padding:30px 0 0 50px;
  @media (max-width: 1400px) {    
    width:1010px;
  }
}
.tab-containbox{ 
box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
border-radius: 3px;
width:100%;
float:left;
.padd{padding:24px 32px 50px;}
}