.enable-2fa-dialog {
	@extend .modalpopup-container;

	&__heading {
		@extend .sans-bold;
		height: 64px;
		align-self: stretch;
		flex-grow: 0;
		font-size: 24px;
		font-weight: 300;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		text-align: center;
		color: var(--greys-grey-1);
	}

	&__description {
		@extend .sans-bold;
		height: 20px;
		align-self: stretch;
		flex-grow: 0;
		font-size: 16px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		text-align: center;
		color: var(--greys-grey-1);
	}

	.qr-code {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}

	.form-group.error-message {
		margin: unset;
	}

	.trouble-scanning {
		@extend .sans-bold;
		height: 16px;
		align-self: stretch;
		flex-grow: 0;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		text-align: center;
		color: var(--greys-grey-2);
	}

	.tfa-secret {
		@extend .sans-bold;
		height: 16px;
		align-self: stretch;
		flex-grow: 0;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		text-align: center;
		color: var(--action-link);
	}
}
