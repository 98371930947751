.geolocate{
   margin: 0 auto;
   padding: 0;
   width: 900px;
   height: auto;
   background-color: var(--white);
   border-radius: 10px;
   box-shadow: 0 1px 2px 0 rgba(0,0,0,0.25);
   &__header{
      position: relative;
      z-index:100;
      padding:20px 30px;
      border-radius: 10px 10px 0 0;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      background-color: var(--white);

      display:flex;
      align-items: center;

      &-left{
         flex:1;
         display:flex;
         align-items: center;
      }
      &-right{
         flex:1;
         p{
            text-align: right;
            margin:0 40px 0 0;
            font-size: 14px;
            color: var(--grey-3);
         }         
      }
      &-icon{
         display:inline-block;
         margin-right:15px;
         position: relative;
         &.is-active{
            &::after{
               content:"";
               position: absolute;
               top:-4px;
               left:-4px;
               width: 10px;
               height: 10px;
               border: solid 1px var(--white);
               background-color: var(--aqua);
               border-radius:50%;
            }
         }
      }
      &-name{
         display:inline-block;
         font-size: 14px;
         color: var(--grey-1);
         line-height:22px;
         padding-right:13px;
         margin-right:15px;
         border-right:1px solid #e0e4e9;
      }
      &-device{
         position: relative;
         display:inline-block;
         .dropdown-toggle{
            border:none;
            padding:0;
            font-weight: bold;
            text-transform: none;
            &::after{
               margin-left:.65em;
            }
         }
      }
      .close{
         position: absolute;
         top: 20px;
         right: 20px;
         float: right;
         z-index: 999;
      }
   }
   &__map{
      display:block;
      position:relative;
      width:900px;
      height:600px;
      border-radius: 0 0 10px 10px;
   }
}