.ui-datepicker {
	margin: 0;
	padding: 0;
	border: none;
	border-spacing: 0;
	display: none;
	width: 480px;
	padding: 30px 40px 34px;
	cursor: default;
	border-top: solid 1px var(--grey-5);
	text-transform: capitalize;
	@extend .sans-regular;
	color: var(--grey-1);
	text-align: center;
	background: var(--white);
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	table {
		margin: 0;
		padding: 0;
		border: none;
		border-spacing: 0;
		display: inline-block;
	}
	tr {
		margin: 0;
		padding: 0;
		border: none;
		border-spacing: 0;
	}
	td {
		margin: 0;
		padding: 0;
		border: none;
		border-spacing: 0;
	}
	th {
		margin: 0;
		padding: 0;
		border: none;
		border-spacing: 0;
	}
}
table.ui-datepicker-calendar {
	th {
		padding: 7px 19px 7px !important;
	}
}
.ui-datepicker-calendar {
	td {
		padding: 7px 19px 7px !important;
		padding: 13px 17px;
		text-align: center;
		position: relative;
	}
	th {
		padding-top: 15px;
		padding-bottom: 10px;
		text-align: center;
		font-weight: normal;
		color: var(--grey-3);
	}
	.ui-state-default {
		display: block;
		outline: none;
		text-decoration: none;
		color: var(--grey-1);
		border: 1px solid transparent;
	}
	.ui-state-active {
		background: var(--aqua);
		border-color: var(--aqua);
		font-size: 12px;
		font-weight: normal;
		color: var(--white);
		width: 36px;
		height: 36px;
		line-height: 33px;
		-webkit-border-radius: 50px;
		-moz-border-radius: 50px;
		border-radius: 50px;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		margin: 0 auto;
	}
	.ui-datepicker-current-day {
		padding: 6px;
	}
}
.ui-datepicker-header {
	position: relative;
	margin-bottom: 30px;
}
.ui-datepicker-next {
	top: -90px;
	width: 0;
	height: 0;
	padding: 0 !important;
	border:none;
	position: absolute;
	top:1px;
	padding: 5px;
	cursor: pointer;
	right:48px;
	padding-right: 0;
	span {
		display: block;
		width: 20px;
		height: 20px;
		text-indent: -9999px;
		background:  url(../../images/icn-chevron-right.svg) no-repeat 0 0;
		background-position: 0px 0px;
		&:hover{background:  url(../../images/icn-chevron-right-hover.svg) no-repeat 0 0;}
	}

}
.ui-datepicker-prev {
	top: -90px;
	width: 0;
	height: 0;
	padding: 0 !important;
	border:nonetransparent;
	position: absolute;
	top: 1px;
	padding: 5px;
	cursor: pointer;
	left:25px;
	padding-left: 0;
	span {
		display: block;
		width: 20px;
		height: 20px;
		text-indent: -9999px;
		background: url(../../images/icn-chevron-left.svg) no-repeat 0 0;
		background-position: 0px 0px;
		&:hover{background:  url(../../images/icn-chevron-left-hover.svg) no-repeat 0 0;}
	}
}
.ui-datepicker-title {
	text-align: center;
	.ui-datepicker-month {
	 @extend .sans-1-bold;
		color: var(--grey-1);
		padding: 0;
	}
}
#today {
@extend .font-30l;
	color: var(--aqua);
	text-align: center;
	margin: 0 0 16px;
}
.ui-datepicker-month {
	position: relative;
	padding-right: 15px;
	@extend .font-30l;
	color: var(--aqua);
}
.ui-datepicker-year {
	padding-left: 0;
	@extend .sans-1-bold;
	color: var(--grey-1);
}
.ui-datepicker-other-month {
	.ui-state-default {
		color: #565656;
	}
}
#ui-datepicker-div {
    display: none;
}
