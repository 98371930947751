.account-login {
	height: 100%;
	display: flex;

	&__lhs {
		flex: 0 0 400px;
		background-color: var(--grey-1);
		padding: 30px;
		position: relative;

		display: flex;
		flex-direction: column;
		justify-content: center;

		.logo {
			position: absolute;
			left: 30px;
			top: 30px;
		}
	}

	&__slider {
		width: 400px;
		margin: 0 -30px;
		color: var(--white);

		&-item {

		}

		&-icon {
			width: 200px;
			margin: 0 auto 30px;

			span {
				display: block;
				width: 200px;
				height: 200px;
				border-radius: 50%;
				background-color: var(--grey-2);
			}
		}

		&-text {
			width: 240px;
			margin: 0 auto;

			p {
				font-size: 25px;
				line-height: 1.2;
				font-weight: 300;
				color: var(--white);
				margin: 0;
				text-align: center;
			}
		}
	}


	&__rhs {
		flex: 1 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.logo {
			margin: 24px 0;
			position: inherit;

			img {
				height: 70px;
				// width: 160px; // keep aspect ratio by only specifying height
			}
		}
	}

	&__client {
		text-align: center;
		margin-bottom: 30px;

		h3 {
			color: var(--grey-1);
			margin: 0 0 3px;
		}

		p {
			text-align: center;
			color: var(--grey-1);
			font-weight: 300;
			font-size: 15px;
		}
	}

	&__form {
		width: 340px;
		margin: 0 auto;

		.form-group {
			padding: 0;

			&.error-message {
				@extend .sans-bold;
				align-items: flex-start;
				align-self: stretch;
				border-radius: 4px;
				background-color: var(--action-error-background);
				color: var(--action-error);
				display: flex;
				flex-grow: 1;
				flex-direction: row;
				gap: 12px;
				height: auto;
				justify-content: flex-start;
				line-height: 1.43;
				font-weight: normal;
				padding: 12px 14px;
				margin: 0;
			}
		}

		.checkbox-group {
			padding: 0;
		}

		.btn-group {
			display: block;
			margin: 30px 0 0;

			.btn-3 {
				width: 100%;
				margin: 0;
			}
		}

		input[type="text"],
		input[type="password"],
		.text-box {
			width: 100%;
		}
	}
}
