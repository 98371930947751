/*.close {
		 background: url(../../images/close.svg) no-repeat 0 0;
         width: 20px;
         height: 20px;
         display:inline-block;
		&:hover {
          background: url(../../images/close-active.svg) no-repeat 0 0;
		  cursor:pointer;
		}
}*/
.close {
&:hover {
cursor:pointer;
svg .icon{fill:var(--aqua);}
}
}
.check.aquabg {
    background: url(../../images/check-white.svg) no-repeat -1px -1px #19c5a2;
    border-radius: 10px;
    margin: 0 10px 0 0;
	height: 18px;
    width: 18px;
    display:inline-block;
	&.mb-5{ margin:0 10px -5px 0;}
}
.building{
      background: url(../../images/customer.svg) no-repeat 0 0;
      width: 20px;
      height: 20px;
      display:inline-block;
      margin: 0 10px 0 0;

}
.disable{
      background: url(../../images/status-disabled.svg) no-repeat 0 0;
      width: 20px;
      height: 20px;
    display:inline-block;
      margin: 0 10px 0 0;

}
.check.greybg {
    background: url(../../images/check-white.svg) no-repeat 0 0 #c6ced8;
    border-radius: 10px;
    color: var(--white);
    margin: 0 10px 0 0;
    padding: 0;
	height: 20px;
    width: 20px;
display:inline-block;
}
/*
.status-risk {
    background: url(../../images/status-risk.svg) no-repeat 0px 0px;
    margin: 0 10px 0 0;
    padding: 3px;
   	height: 20px;
    width: 20px;
    display:inline-block;
	&.mb-5{ margin:0 10px -5px 0; padding:0px;}
}
.status-failed {
    background: url(../../images/status-failed.svg) no-repeat 0px 0px;
    margin: 0 10px 0 0;
	  height:20px;
    width: 20px;
	  display:inline-block;
}
*/
.check.aquabg-squre {
    background: url(../../images/check-white.svg) no-repeat -1px -1px #19c5a2;
    border-radius: 3px;
    color: var(--white);
    margin: 0 10px 0 0;
   	height: 18px;
    width: 18px;
	display:inline-block;
}

.chevron-left{
background: url(../../images/chevron-left.svg) no-repeat 0 0 ;
height: 20px;
width: 20px;
display:inline-block;
&:hover{
  background: url(../../images/chevron-left-hover.svg) no-repeat 0 0 ;
}
}
.chevron-right{
background: url(../../images/chevron-right.svg) no-repeat 0 0 ;
height: 20px;
width: 20px;
 display:inline-block;
&:hover{
  background: url(../../images/chevron-right-hover.svg) no-repeat 0 0 ;
}
}
/*
.pencil{
   background: url(../../images/edit.svg) no-repeat 0 0;
      width: 20px;
      height: 20px;
      float: right;cursor: pointer;
&.inactive{
opacity:0.7;
}
&:hover{background: url(../../images/edit-grey-hover.svg) no-repeat 0 0;}
}

.clock-o{
   background: url(../../images/status-pending.svg) no-repeat 0 0;
      width:18px;
      height: 18px;
      margin:0 10px 0 0;
      display: inline-block;
}
*/

.warning {
	background: url(../../images/error-msg.svg) no-repeat 0 0;
	width: 20px;
	height: 20px;
	display: inline-block;
	flex-shrink: 0;

	&.mb-5 {
		margin: 0 10px -5px 0;
	}
}

/*
      .warning-15 {
      background: url(../../images/error-msg-15.svg) no-repeat 0 0;
      width:15px;
      height: 15px;
      display: inline-block;margin: 0 0 -3px 10px;
    }

 .more {
      background: url(../../images/more.svg) no-repeat 0 3px;
      width: 20px;
      height: 20px;
      display: inline-block;
      margin: 0;
	  &:hover{
	   background: url(../../images/more-hover.svg) no-repeat 0 3px;
	  }
	  &:hover .icon{fill:var(--grey-3);}
    }
.check-grey-outline{
background: url(../../images/check-grey-outline.svg) no-repeat 0 0;
      width: 20px;
      height: 20px;
      display: inline-block;
      margin:0 10px 0 0;}
.check-light-grey{
background: url(../../images/check-light-grey.svg) no-repeat 0 0;
      width: 20px;
      height: 20px;
      display: inline-block;
      margin:0 10px 0 0;}

.protected-aqua{
   background: url(../../images/protection-protected-aqua.svg) no-repeat 0 0;
      width:20px;
      height:20px;
      margin:0 10px 0 0;
      display: inline-block;
}
.protected-unprotected{
   background: url(../../images/protection-unprotected.svg) no-repeat 0 0;
      width:20px;
      height:20px;
      margin:0 10px 0 0;
      display: inline-block;
}

.protected-inactive{
   background: url(../../images/protection-inactive.svg) no-repeat 0 0;
      width:20px;
      height:20px;
      margin:0 10px 0 0;
      display: inline-block;
}
.protected-warning{
   background: url(../../images/protection-with-warning.svg) no-repeat 0 0;
      width:20px;
      height:20px;
      margin:0 10px 0 0;
      display: inline-block;
}
.protected-warning-grey{
   background: url(../../images/protection-with-warning-grey.svg) no-repeat 0 0;
      width:20px;
      height:20px;
      margin:0 10px 0 0;
      display: inline-block;
}
.protected-grey{
   background: url(../../images/protection-protected-grey-20.svg) no-repeat 0 0;
      width:20px;
      height:20px;
      margin:0 10px 0 0;
      display: inline-block;
}


.download-icon{
background: url(../../images/download-aqua.svg) no-repeat 4px 3px;
width:20px;
height:20px;
margin:0 14px -2px -8px;
display: inline-block;
}
.btn:hover .download-icon, .download-icon:hover{background: url(../../images/download-white.svg) no-repeat 4px 3px;}
*/
.close-16{float:right; margin:3px 0 0;background:url(../../images/close-w16.svg) no-repeat 0 0; width:13px; height:13px;
&:hover{background:url(../../images/close-hover-w16.svg) no-repeat 0 0; cursor:pointer; }
}
.circle-aqua-10 {
  width: 10px;
  height: 10px;
  background: var(--aqua);
  display:inline-block;
  border-radius:10px;
}
/*
.icn-teams{display:inline-block;margin:0 10px -2px 0;background:url(../../images/teams-aqua-12.svg) no-repeat 0 0; width:13px; height:13px;}
*/
.icn-team:hover{background:url(../../images/teams-white-12.svg) no-repeat 0 0; cursor:pointer; }
/*.nav-open{float:right; margin:0;background:url(../../images/nav-open.svg) no-repeat 0 0; width:20px; height:20px; cursor:pointer;
&:hover{background:url(../../images/nav-open-hover.svg) no-repeat 0 0;}
}
*/.data-backup{display:inline-block;margin:0 10px -5px 0;background:url(../../images/data-backup.svg) no-repeat 0 0; width:20px; height:20px;}
.data-discovered{display:inline-block;margin:0 10px -5px 0;background:url(../../images/data-discovered.svg) no-repeat 0 0; width:20px; height:20px;}
/*
.aqua-geolocation{display:inline-block;margin:0 10px -5px 0;background:url(../../images/geolocation-aqua.svg) no-repeat 0 0; width:20px; height:20px;}
.aqua-encryption{display:inline-block;margin:0 10px -5px 0;background:url(../../images/encryption-aqua.svg) no-repeat 0 0; width:20px; height:20px;}
.aqua-trusted{display:inline-block;margin:0 10px -5px 0;background:url(../../images/dlp-aqua.svg) no-repeat 0 0; width:20px; height:20px;}
.data-theft{display:inline-block;margin:0 10px -5px 0;background:url(../../images/auto-revoke-aqua.svg) no-repeat 0 0; width:20px; height:20px;}
.data-theft-red{display:inline-block;margin:0 10px -5px 0;background:url(../../images/auto-revoke-red.svg) no-repeat 0 0; width:20px; height:20px;}
.wipe-red{display:inline-block;margin:0 10px -5px 0;background:url(../../images/remote-wipe-red.svg) no-repeat 0 0; width:20px; height:20px;}
.encryption-lg{display:inline-block;margin:0 10px -5px 0;background:url(../../images/encryption-light-grey.svg) no-repeat 0 0; width:20px; height:20px;}
.geolocation-lg{display:inline-block;margin:0 10px -5px 0;background:url(../../images/geolocation-light-grey.svg) no-repeat 0 0; width:20px; height:20px;}
.auto-revok-lg{display:inline-block;margin:0 10px -5px 0;background:url(../../images/auto-revoke-light-grey.svg) no-repeat 0 0; width:20px; height:20px;}

#sorttable_sortfwdind{width:11px;
  height:11px;
 background:url(../../images/dropdown-uparrow.svg) no-repeat 0 0;
	line-height: 20px;
	display:inline-block;
  padding: 0;
  margin:0px 0 0px 5px;position:absolute; top:9px;}
#sorttable_sortrevind{width:11px;
  height:11px;
 background:url(../../images/dropdown-arrow.svg) no-repeat 0 0;
	line-height: 20px;
	display:inline-block;
  padding: 0;
  margin:0px 0 0px 5px;position:absolute; top:9px; }

.delete{
   background: url(../../images/trash.svg) no-repeat 0 0;
      width: 20px;
      height: 20px;
      float: right;
&.inactive{
opacity:0.7;
}
}
*/
.aqua-plus{
  display:inline-block;
  margin:0 0 7px 32px;
  vertical-align: bottom;
  @extend .pos-rel;
a{
  width: 36px;
  height: 36px;
  display:inline-block;
 /* position: absolute;
  bottom: 7px;*/
  margin:0;
  svg{margin:11px 0 0 0;}
 /*i{display:inline-block;margin:6px 0 0 0;background:url(../../images/accordian-expand-hover.svg) no-repeat 0 0; width:20px; height:20px;}*/
}
&.open svg{margin:0 10px 0 0;}
}
.aqua-plus a:hover i{background:url(../../images/accordian-expand-white.svg) no-repeat 0 0;}



/*
.check-dark-greybg {
    background: url(../../images/check-dark-grey.svg) no-repeat;
    margin: 0 10px 0 0;
	padding:0;
	height: 20px;
    width: 20px;
display:inline-block;
}
.status-risk-dark {
    background: url(../../images/status-risk-dark-grey.svg) no-repeat 0px 0px;
    margin: 0 10px 0 0;
    padding: 3px;
   	height: 20px;
    width: 20px;
    display:inline-block;
}

.backup-30{
   background: url(../../images/backup-30.svg) no-repeat 0 0;
      width:30px;
      height:30px;
      margin:0 20px 0 0;
      display: inline-block;
&:hover{ background: url(../../images/backup-30_hover.svg) no-repeat 0 0;}
}
.revoke-30{
   background: url(../../images/revoke-30.svg) no-repeat 0 0;
     width:30px;
      height:30px;
      margin:0 20px 0 0;
      display: inline-block;
&:hover{ background: url(../../images/revoke-30_hover.svg) no-repeat 0 0;}

}
.geolocation-30{
   background: url(../../images/geolocation-30.svg) no-repeat 0 0;
     width:30px;
      height:30px;
      margin:0 20px 0 0;
      display: inline-block;
&:hover{ background: url(../../images/geolocation-30_hover.svg) no-repeat 0 0;}

}
.remote-wipe-30{
   background: url(../../images/remote-wipe-30.svg) no-repeat 0 0;
     width:30px;
      height:30px;
      margin:0 20px 0 0;
      display: inline-block;
&:hover{ background: url(../../images/remote-wipe-30_hover.svg) no-repeat 0 0;}

}


.blacklist-30{
   background: url(../../images/status-failed-30.svg) no-repeat 0 0;
      width:30px;
      height:30px;
      margin:0;
      display: inline-block;
&:hover{ background: url(../../images/status-failed-30_hover.svg) no-repeat 0 0;}
}


.auto-activate-30{
   background: url(../../images/auto-activate-30.svg) no-repeat 0 0;
      width:30px;
      height:30px;
      margin:0 20px 0 0;
      display: inline-block;
&:hover{ background: url(../../images/auto-activate-30_hover.svg) no-repeat 0 0;}
}


.teams-30{
   background: url(../../images/teams-30.svg) no-repeat 0 0;
      width:30px;
      height:30px;
      margin:0 20px 0 0;
      display: inline-block;
&:hover{ background: url(../../images/teams-30_hover.svg) no-repeat 0 0;}
}




.trash-15{
   background: url(../../images/trash-15.svg) no-repeat 0 0;
     width:15px;
      height:15px;
      display: inline-block;
&:hover{ background: url(../../images/trash-15-hover.svg) no-repeat 0 0;}

}
.duplicate{
   background: url(../../images/duplicate.svg) no-repeat 0 0;
     width:20px;
      height:20px;
      display: inline-block;
&:hover{ background: url(../../images/duplicate-hover.svg) no-repeat 0 0;}

}

.trash{
   background: url(../../images/trash.svg) no-repeat 0 0;
      width: 20px;
      height: 20px;
	  display: inline-block;
&:hover{ background: url(../../images/trash-hover.svg) no-repeat 0 0;}
}
.edit-grey{
   background: url(../../images/edit-grey.svg) no-repeat 0 0;
      width:20px;
      height:20px;
	  display: inline-block;
&:hover{ background: url(../../images/edit-grey-hover.svg) no-repeat 0 0;}
}
.trash-grey{
   background: url(../../images/trash-grey.svg) no-repeat 0 0;
      width: 20px;
      height: 20px;
	  display: inline-block;
&:hover{ background: url(../../images/trash-grey-hover.svg) no-repeat 0 0;}
}
.icon-add{
   background: url(../../images/add.svg) no-repeat 0 0;
      width: 20px;
      height: 20px;
	  display: inline-block;
	   margin:0 9px 0 0;
}
.icon-add:hover, a:hover .icon-add{ background: url(../../images/add-grey.svg) no-repeat 0 0;}


a{
&:hover .icon-add .icon {fill:#5b6e8c;}
}

.add-icon{
svg{    display: inline-block;
    margin: 8px 0 0 0;}
&:hover .icon{fill:var(--white);}
}
a:hover .add-icon .icon{fill:var(--white);}
a:hover .add-icon .plus-icon{fill:var(--aqua);}
.add-icon{
   background: url(../../images/add.svg) no-repeat 0 0;
      width: 20px;
      height: 20px;
	  display: inline-block;
	  margin:0 10px -5px 0;
}
.add-icon:hover, a:hover .add-icon{ background: url(../../images/add-white.svg) no-repeat 0 0;}

/*.add-white{
   background: url(../../images/add-white.svg) no-repeat 0 0;
      width: 20px;
      height: 20px;
	  display: inline-block;
	  margin:0 10px -5px 0;
}
.add-white:hover, a:hover .add-white{ background: url(../../images/add-white.svg) no-repeat 0 0;}


.add-white{ vertical-align: top;
  display: inline-block;
svg{display: inline-block;
    margin:4px 4px 0 -2px;}
&:hover .icon{fill:var(--white);}
}
a:hover .add-white .icon{fill:var(--white);}
a:hover .add-white .plus-icon{fill:var(--aqua);}


.activate-27{
      background: url(../../images/protection-protected-grey-27.svg) no-repeat 0 0;
      width: 27px;
      height: 27px;
	  display: inline-block;
	  margin:0 10px 0 0;
	  &:hover{background: url(../../images/protection-protected-grey-27-hover.svg) no-repeat 0 0;}
}
.teams-27{
      background: url(../../images/teams-27.svg) no-repeat 0 0;
      width: 27px;
      height: 27px;
	  display: inline-block;
	  margin:0 10px 0 0;
	  &:hover{background: url(../../images/teams-27-hover.svg) no-repeat 0 0;}
}
.blacklist-27{
      background: url(../../images/status-failed-27.svg) no-repeat 0 0;
      width: 27px;
      height: 27px;
	  display: inline-block;
	  margin:0 10px 0 0;
	  &:hover{background: url(../../images/status-failed-27-hover.svg) no-repeat 0 0;}
}
.files-global-14{
      background: url(../../images/files-global-14.svg) no-repeat 0 0;
      width:14px;
      height:14px;
	  display: inline-block;vertical-align: top;
	  }
a:hover .files-global-14{background: url(../../images/files-global-aqua-14.svg) no-repeat 0 0;}
.all-files-14{
      background: url(../../images/file-14.svg) no-repeat 0 0;
      width:14px;
      height:14px;
	  display: inline-block;vertical-align: top;
	  }
a:hover .all-files-14{background: url(../../images/file-aqua-14.svg) no-repeat 0 0;}
.custom-files-14{
      background: url(../../images/settings-14.svg) no-repeat 0 0;
      width:14px;
      height:14px;
	  display: inline-block;vertical-align: top;
	  }
a:hover .custom-files-14{background: url(../../images/settings-aqua-14.svg) no-repeat 0 0;}
.auto-activate-27{
   background: url(../../images/auto-activate.svg) no-repeat 0 0;
      width:27px;
      height:27px;
      margin:0;
      display: inline-block;
&:hover{ background: url(../../images/auto-activate-aqua.svg) no-repeat 0 0;}
}
.backup-27{
   background: url(../../images/backup.svg) no-repeat 0 0;
      width:27px;
      height:27px;
      margin:0;
      display: inline-block;
&:hover{ background: url(../../images/backup-hover.svg) no-repeat 0 0;}
}
.auto-revoke-27{
   background: url(../../images/revoke-27.svg) no-repeat 0 0;
      width:27px;
      height:27px;
      margin:0;
      display: inline-block;
&:hover{ background: url(../../images/revoke-27-hover.svg) no-repeat 0 0;}
}
.remote-wipe-27{
   background: url(../../images/remote-wipe-27.svg) no-repeat 0 0;
      width:27px;
      height:27px;
      margin:0;
      display: inline-block;
&:hover{ background: url(../../images/remote-wipe-27-hover.svg) no-repeat 0 0;}
}
.geolocation-27{
   background: url(../../images/geolocation-27.svg) no-repeat 0 0;
      width:27px;
      height:27px;
      margin:0;
      display: inline-block;
&:hover{ background: url(../../images/geolocation-27-hover.svg) no-repeat 0 0;}
}
.more-20{
   background: url(../../images/more-hover.svg) no-repeat 0 0;
      width:20px;
      height:20px;
      margin:0;
      display: inline-block;
&:hover{ background: url(../../images/more-aqua.svg) no-repeat 0 0;}
}
.columns{
   background: url(../../images/columns.svg) no-repeat 0 0;
      width:20px;
      height:20px;
      margin:5px 0 0 5px;
      display: inline-block;
&:hover{ background: url(../../images/columns-hover.svg) no-repeat 0 0;}
&:active{ background: url(../../images/columns-hover.svg) no-repeat 0 0;}
}
.filters{
   background: url(../../images/filters.svg) no-repeat 0 0;
      width:20px;
      height:20px;
      margin:5px 0 0 5px;
      display: inline-block;
&:hover{ background: url(../../images/filters-hover.svg) no-repeat 0 0;}
&:active{ background: url(../../images/filters-hover.svg) no-repeat 0 0;}
}


.device-enable-27{
   background: url(../../images/device-enable.svg) no-repeat 0 0;
      width:27px;
      height:27px;
      margin:0;
      display: inline-block;
&:hover{ background: url(../../images/device-enable-hover.svg) no-repeat 0 0;}
}
.device-disable-27{
   background: url(../../images/device-disable.svg) no-repeat 0 0;
      width:27px;
      height:27px;
      margin:0;
      display: inline-block;
&:hover{ background: url(../../images/device-disable-hover.svg) no-repeat 0 0;}
}
.archive-27{
   background: url(../../images/archive-27.svg) no-repeat 0 0;
      width:27px;
      height:27px;
      margin:0;
      display: inline-block;
&:hover{ background: url(../../images/archive-27-hover.svg) no-repeat 0 0;}
}
.trash-27{
   background: url(../../images/trash-27.svg) no-repeat 0 0;
      width:27px;
      height:27px;
      margin:0;
      display: inline-block;
&:hover{ background: url(../../images/trash-27-hover.svg) no-repeat 0 0;}
}
.tab-warning{
   background: url(../../images/warning-grey.svg) no-repeat 0 0;
      width:14px;
      height:14px;
      margin:0 5px -2px 0;
      display: inline-block;
}
a:hover .tab-warning{
   background: url(../../images/warning-grey-hover.svg) no-repeat 0 0;
}
.check-aquabg-25 {
    background: url(../../images/check-white-15.svg) no-repeat 2px 3px #19c5a2;
    border-radius:25px;
    margin: 0 10px 0 0;
	height:23px;
    width:23px;
    display:inline-block;vertical-align: top;
}
.team-20 {
    background: url(../../images/teams.svg) no-repeat 0 0;
    margin: 0 10px 0 0;
	height:20px;
    width:20px;
    display:inline-block;
	vertical-align: top;
}*/
.disable-20{
   background: url(../../images/disable-20.svg) no-repeat 0 0;
      width:20px;
      height:20px;
       margin: 0 10px 0 0;
      display: inline-block;vertical-align: top;
}
/*
.noti-info{ background: url(../../images/notification-info.svg) no-repeat 0 0;
      width:75px;
      height:75px;
       margin:0;
      display: inline-block;vertical-align: top; box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);border-radius:100px;}
.noti-error{background: url(../../images/notification-error.svg) no-repeat 0 0;
      width:75px;
      height:75px;
       margin:0;
      display: inline-block;vertical-align: top; box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);transform: rotate(45deg);}

*/

a{
&:hover.edit .icon {fill:#ffc107;}
&.edit.inactive{opacity:0.7;}
&:hover.delete .icon{fill:var(--orange);}
&.delete.inactive{opacity:0.7;}
}
a{
.icon-aqua{margin: 8px 10px 0 0;}
&:hover {
.icon-aqua {
.icon{fill:var(--white);}
.aqua-icon{fill:var(--aqua);}
}
}
}

