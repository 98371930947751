#palette{
header.header{
&.black {
    margin:10px 0 50px;
}
}
.breadcrumb-sec {
  border-top: solid 1px var(--grey-5);
  border-bottom: solid 1px var(--grey-5);
  padding: 0 0 44px;
  .breadcrumb {
    margin: 10px 0 30px 50px;
    float: left;
    width: 80%;
  }
}
.grey-outline, .btn-grey {
    margin: 0 0 10px;
	}
    .buttons {
	    .btn-small-aqua-background, .btn-small-aqua-border{
	     clear:both;
	     float: left;
	     }
.btn-aqua, .btn-orange, .btn {
 margin:0 0 15px;
 }
.btn-1{
clear:both;
float:left;
}
}

  .input-sec {
    .user {
      float: none;
      margin: 10px 0 0;
    }
    .texttitle {
      text-align: left;
      margin: 0 0 10px;
      font-size: 14px;
    }
  }
  .checkbox.select {
    margin: 10px 0 0;
  }

.input-sec {
  .form-group {
    padding: 0;
  }
  input[type="text"] {
    margin: 0 0 10px;
  }
}

.modalpopup-container{
position:fixed;
top:50%;
left:0;
right:0;
margin:-125px auto;
display:none;
z-index:2;
height: 250px;
}

}
#palette{
  h2 {
    @extend .font-30l;
    margin: 30px 0 18px;
    float: left;
    width: 100%;
  }
  .texttitle {
  @extend .sans-1-regular;
    color: var(--grey-3);
    letter-spacing: 0.5px;
    margin: 0 0 18px;
    float: left;
    width: 100%;
  }
  .texttitle-sm{
    @extend  .texttitle;
    font-size:12px;
    margin-top:3px;
  }

  .heading-text{
  margin:25px 0 0 0;
  h1{margin:0 0 0;}
  h2{
  font-weight:600;margin:0px 0 15px;
  }
   h4{
  font-weight:600;
  }
  h1, h2, h3, h4, h5{display:block;color: var(--grey-2);float: left;width: 100%; margin:0 0 15px;}
  }
  .disabled{opacity:0.5;}
  .tooltip-title{margin:55px 0 0;}
  .tooltip-sec{margin:38px 0 0;
  a{margin:0 13px;display: inline-block;}
  }

  .icons-sec{

    &__row{
      margin-bottom:30px;
      svg{width:20px; height:20px; margin:0 12px 10px 0;}
      
      &--lg{
        svg{
          width:80px;
          height:80px;
        }
      }
    }


  }
  
  .graph-color{
    color:var(--grey-3);
    font-size:16px;
    margin:10px 0 20px;
    &__dot{
      display:inline-block;
      vertical-align: middle;
      width:50px;
      height:50px;
      border-radius:50%;
      margin-right:20px;
      &.darkgreen{
        background-color: #009074;
      }
      &.lightgreen{
        background-color: #7bd500;
      }
      &.turquoise{
        background-color: #00c6a2;
      }
      &.darkblue{
        background-color: #006fc8;
      }
      &.lightblue{
        background-color: #44b6f8;
      }
      &.purple{
        background-color: #9100ff;
      }
      &.pink{
        background-color: #d25cea;
      }
      &.maroon{
        background-color: #c7377b;
      }
      &.red{
        background-color: #ea1248;
      }
      &.darkorange{
        background-color: #ff4f00;
      }
      &.lightorange{
        background-color: #ff9900;
      }
      &.yellow{
        background-color: #ffc200;
      }
      &.grey {
        background-color: #c6ced8;
      }
    }
    
  }
  

}

