.error-msg{
  display:block;
  height: 30px;
  border-radius: 5px;
  background-color: var(--orange);
  @extend .sans-regular;
  color: var(--white);
  padding: 6px 0;
  span{margin: 0 16px 0 12px;vertical-align: top;display: inline-block;}
  &.mt36{margin-top:36px;}
}





