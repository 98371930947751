
#discoverd{position:relative;left:0px; right:0;top: 0;bottom: 0;/*display:table-cell;*/height: 100%;

  display:flex;
  flex-direction: column;

&.devinv{margin-left:220px;}
}
.dis-container{@extend .animation;}

.backup-line{
clear:both;
max-width:630px;
display:block;
box-shadow: inset 0 -1px 0 0 var(--grey-5);
padding:13px 0 17px;
height:70px;
line-height: 45px;
@extend .sans-regular;
font{margin:0 23px 0 0;
&.hours{margin:0 0 0 22px;float:right;}
}
span.m18{margin:0 18px;}
.checkbox{margin:0 16px -3px;display:inline-block;}

}


.path-sec{
padding: 30px 0;
margin:0 0 30px;
    background-color: var(--white);
    box-shadow: 0 1px 0 0 var(--grey-5), inset 0 1px 0 0 var(--grey-5);
	.path-wrap{display:inline-block; margin:0 0 20px;padding: 0 32px 0 0;width: 100%;
	&:last-child{margin-bottom:0;}
	}
	.path-text{@extend .sans-1-bold;color: var(--grey-2);display:inline-block;width:34px;float: left;margin: 10px 0 0 0;}
    .right-sec{display:inline-block;width:96%;float: right;margin: 0 0 20px;
	&.first{margin:0;}
	}
	.mid-sec{display:inline-block; margin:0 0 0 28px;float:left;}
	.input-sec{ display:inline-block;margin:0 41px 0 0;float:left;
	input{width:470px;}
		}

	.redio-btn-sec{
	display:inline-block;float:left;margin:11px 0 0 0;
	dl{display:inline-block;}
	.radio{
	display:inline-block;float:left;
	 label{color:var(--grey-1);}
	}
	}
	.btn-sec{display:inline-block;float:left;margin: 3px 0 0 30px;
	.aqua-plus{position:inherit;margin:0;}
	.trash{margin:7px 0 0 7px;display:inline-block;}
	}
}

.contain-wrap{max-width:1700px;margin:0 auto;min-width:1199px;padding:0 51px;}

.loading-wrap{@extend .form-container;
.loading-container{
    width:420px;
    margin: 0 auto;
    text-align: center;

		p{
      text-align: center;
      color: var(--grey-3);
    }

    .loading-client{
      > svg{
        width:60px;
        height:60px;
        margin-bottom: 25px;
      }
      h4{
        font-size:20px;
        font-weight: 600;
        margin: 0 0 1px;
        color: var(--grey-1);
      }
      p{
        font-size: 15px;
        font-weight: 300;
      }
    }

	}

}
.result-team{
margin:63px 0 0 0;
h4{@extend .font-20b;font-weight:normal;display:block;margin:0 0 18px;}
}


ul.small-list{
  li{
    color:var(--grey-3);
    font-size:12px;
    line-height:1.33;
  }
}

.moreless{
  &__content{
    position: relative;
    overflow: hidden;
    max-height:500px;
    transition:.75s ease;

    &::after{
      content:"";
      position: absolute;
      left:0;
      bottom:0;
      width:100%;
      height:20px;
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
      transition:height .35s ease;
    }
  }

  &__btn{
    margin-top:15px;
    -webkit-appearance: none;
    border:none;
    outline:none;
    border:none;
    background:transparent;
    font-size:12px;
    color:var(--aqua);
    cursor: pointer;
  }

  &.is-open{
    .moreless__content{
      &::after{
        height:0;
      }
    }
  }

  &.is-closed{
    .moreless__content{
      max-height:115px;

    }
  }


}
