.breadcrumb-wrap{
  width:1200px; margin:0 auto; padding:30px 0 0;display:block;
  @media (max-width: 1400px) {
    width:1010px;
  }
}
.breadcrumb {
	background: none;
	margin: 0;
	padding: 0;
	float: left;
 &.margin {
margin-left:80px;
margin-bottom:30px;
}
&.margin30 {@extend .marginb30;}
li {


 @extend .sans-regular;
	font-size: 20px;
	color: var(--grey-2);
	display: inline-block;
	float: left;
	position: relative;
  line-height: 1.3;
  a,
  span{
    @extend .sans-regular;
    font-size: 20px;
    color: var(--grey-2);
    float:left;
    line-height: 1.3;
    &:hover, &:active {
    text-decoration: none;
    color: var(--aqua);
  }
}
.chevron-right {
	margin: 4px 12px 0 12px;display: inline-block;
 &:hover {
 /*background: url(../../images/chevron-right.svg) no-repeat 0 0;*/
}
}
 &.first {
 margin:0px 24px 0 0;
 &.mt6{margin-top:6px;}
 a {
 .arrow-left {
/* background: url(../../images/arrow-left.svg) no-repeat 0 1px;*/
 border: 2px solid #8493a9;
 width: 26px;
 height: 26px;
 float: left;
 border-radius: 15px;
 margin:0;
 border: 2px solid var(--grey-3);
 border-radius: 15px;
 padding:0;
}
 &:hover .arrow-left, &.active .arrow-left {
 border: 2px solid var(--aqua);
/* background: url(../../images/arrow-left-hover.svg) no-repeat 0 1px;*/
.icon{fill:var(--aqua);}
}
}
}
 &.last {
 margin:0px;
 a {
 font-weight:600;
 padding:0;
 border:none;
 text-transform:capitalize;
 &.dropdown-toggle::after {
 margin-left: 0.9em;
 color:var(--grey-3);
}
 &:hover, &:active {
 text-decoration: none;
 color: var(--aqua);
 &.dropdown-toggle::after {
 color:var(--grey-3);
}
}
}
.form-group{margin:0;padding:0;
input{width: 300px;}
}
i.pencil, .edit{margin:5px 0 0 10px;}
 .dropdown-menu.dropdown-menu-left {
 top:-6px;
 left:-11px;
 padding: 5px 0 0;
 a {
 &.title-link {
 color: var(--aqua);
 font-weight:600;
 text-align: left;
 width: 100%;
 line-height: 1.3;
 padding: 0 0 0 10px;
}
 &.dropdown-item {
 padding:10px 10.5px;
 color: var(--grey-2);
}
}
}
}
}
.img-left{float:left;margin:0 15px 0 0;}

}
