input[type="text"], input[type="password"], .text-box {
	color: var(--grey-1);
	outline: medium none;
	padding: 0 0 7px;
	margin: 0 auto;
	width: 400px;
	height: auto;
	border-radius: 3px;
	background-color: var(--white);
	border: solid 1px var(--grey-5);
	padding: 10px;
	@extend .sans-1-regular;
	line-height: 1.25;
	font-family: 'Source Sans Pro', sans-serif;

	&:focus, &.input-active {
		box-shadow: 0 0 3px 0 rgba(24, 197, 162, 0.2);
		background-color: var(--white);
		border-color: var(--aqua);
	}

	&.w520 {
		width: 520px;
	}
}

.text-box {
	height: 100px;
}

input.domain-loading:focus {
	/*background: url(../../images/loading.svg) no-repeat 10px 10px #F4F8FA;*/
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: var(--grey-3);
}

::-moz-placeholder { /* Firefox 19+ */
	color: var(--grey-3);
}

:-ms-input-placeholder { /* IE 10+ */
	color: var(--grey-3);
}

:-moz-placeholder { /* Firefox 18- */
	color: var(--grey-3);
}

.custom-search-input {
	padding: 0;
	border: none;
	border-radius: 0;
	background-color: var(--white);
	float: left;
	width: auto;

	input {
		border: 0;
		box-shadow: none;
		background: var(--white);
		//@extend .sans-1-regular;
		line-height: 1.25;
		color: var(--grey-1);
		text-align: left;
		padding: 0;
		height: 40px;
		width: auto;
	}

	button {
		margin: -5px 0 0;
		background: none;
		box-shadow: none;
		border: 0;
		color: var(--grey-3);
		padding: 0 8px;
		border: none;

		&:hover {
			border: 0;
			box-shadow: none;
			border: none;
		}
	}

	&.left {
		width: 85px;

		input {
			background: url(../../images/icn-search.svg) no-repeat 10px center;
			width: 0;
			padding: 0 18px;

			&:hover {
				background: url(../../images/icn-search-hover.svg) no-repeat 10px center;
				width: 0;
				padding: 0 18px;
			}

			&:focus {
				background: #F4F8FA url(../../images/icn-search-close.svg) no-repeat right 11px center;
				border-radius: 3px;
				transition: background 0s ease 0s;
				width: 250px;
				border: solid 1px var(--aqua);
				height: 40px !important;
				margin: 0 0 0 0;
				padding: 0 30px 0 10px;
				color: var(--grey-1);
				text-align: left;
			}
		}
	}

	&.right {
		height: 40px;

		input {
			background: url(../../images/icn-search.svg) no-repeat 12px center;
			border: solid 1px transparent;
			padding: 0 30px 0 13px;
			width: 0;
			margin: -3px 0 0 0;

			&:hover {
				cursor: pointer;
				background: url(../../images/icn-search-hover.svg) no-repeat 12px center;
			}

			&:focus {
				border: solid 1px transparent;
				box-shadow: none;
			}

			&.expanded {
				background: #F4F8FA url(../../images/icn-search-close.svg) no-repeat right 11px center;
				border-radius: 3px;
				transition: background 0s ease 0s;
				width: 250px;
				border: solid 1px var(--aqua);
				height: 40px !important;
				margin: -3px 0 0 0;
				padding: 0 30px 0 10px;
				color: var(--grey-1);
				text-align: left;
			}
		}

		input.stay-expanded {
			&.expanded {
				background: var(--white) url(../../images/icn-search.svg) no-repeat right 11px center;
				width: 100%;
				height: 42px !important;
				border: solid 1px var(--grey-5);
			}
			&.not-empty {
				background: var(--white) url(../../images/icn-search-close.svg) no-repeat right 11px center;
			}
			&:focus {
				background: var(--white) url(../../images/icn-search-close.svg) no-repeat right 11px center;
				border: solid 1px var(--aqua);
			}
		}
	}

	.custom-search-close-overlay {
		background-color: transparent;
		width: 20px;
		height: 20px;
		z-index: 1000;
		margin-top: -30px;
		position: relative;
		display: none;

		&.expanded {
			cursor: pointer;
			margin-left: calc(100% - 30px);
			display: block;
		}
	}
}

.custom-search-input .input-group {
    margin: 0 10px 0 0;
}

.input-active {
	background: var(--light-blue);
	border: 1px solid var(--aqua);
	border-radius: 3px;
	box-shadow: 0 0 3px 0 rgba(24, 197, 162, 0.2);
	padding: 10px 15px;
	text-align: left;
	color: var(--grey-1);
}

.input-error, input[type="text"].input-error:focus, input[type="text"].input-error {
	/*background: var(--light-blue);*/
	border: 1px solid var(--orange);
	border-radius: 3px;
	box-shadow: 0 0 3px 0 rgba(24, 197, 162, 0.2);
	padding: 10px 10px;
	text-align: left;
	color: var(--grey-1);
}


.form-group {
	&.error-message {
		@extend .sans-bold;
		align-items: flex-start;
		align-self: stretch;
		border-radius: 4px;
		background-color: var(--action-error-background);
		color: var(--action-error);
		display: flex !important;
		flex-grow: 1;
		flex-direction: row;
		gap: 12px;
		height: auto;
		justify-content: flex-start;
		line-height: 1.43;
		font-weight: normal;
		padding: 12px 14px;
		margin: 0 38px;
	}

	&.aqua-text {
		@extend .sans-bold;
		line-height: 1.29;
		color: var(--aqua);
	}

	&.marb30 {
		margin-bottom: 30px;
	}
}


.checkbox label {
	padding: 0;
	color: var(--grey-1);
	@extend .sans-regular;
	line-height: 1.43;
}

.checkbox label:after {
	display: table;
	clear: both;
}

.checkbox .cr {
	position: relative;
	display: inline-block;
	border: 1px solid #c6ced8;
	border-radius: 2px;
	width: /* 1.3em*/
		18px;
	height: /* 1.3em*/
		18px;
	float: left;
	margin: 2px .5em 0 0;
	overflow: hidden;

	&:hover {
		cursor: pointer;
	}
}

.checkbox .cr .cr-icon {
	position: absolute;
	font-size: .8em;
	line-height: 0;
	top: -1px;
	left: -2px;
}

.checkbox label input[type="checkbox"] {
	display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon {
	transform: scale(1.5) rotateZ(-20deg);
	opacity: 0;
	transition: all .15s ease-in;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon {
	transform: scale(1) rotateZ(0deg);
	opacity: 1;
}

.checkbox label input[type="checkbox"] {
	&:checked + .cr {
		background: var(--aqua);
		border: 1px solid var(--aqua);
		color: var(--white);
	}

	&:disabled + .cr {
		opacity: .5;
	}
}

.checkbox label input[type="checkbox"].checked + .cr > .cr-icon {
	opacity: 1;
	transform: scale(1) rotateZ(0deg);
}

.checkbox label input[type="checkbox"].checked + .cr {
	background: var(--aqua);
	border: 1px solid var(--aqua);
	color: var(--white);
}

.cr-icon.check {
	background: url(../../images/icn-check-white.svg) no-repeat 0 0;
	width: 18px;
	height: 18px;
}

.cr-icon.circle {
	margin: 5px 0 0 6px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: var(--white);
}

.search {
	background: url(../../images/icn-search.svg) no-repeat 0 0;
	width: 20px;
	height: 20px;
	float: left;
}

.form-container {
	margin: 0;
	display: table-cell;
	vertical-align: middle;

	h2 {
		font-size: 40px;
		font-weight: 300;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.25;
		text-align: center;
		color: var(--white);
		margin: 0 auto 30px;
		display: block;

		&.title {
			display: block;
			line-height: 33px;
			margin: 17px 0 30px;
			text-align: center;
			font-size: 30px;
			color: var(--grey-1);

			&--white {
				color: var(--white);
			}
		}
	}

	&__footer-cta {
		margin: 35px 0 0;
		text-align: center;
		font-size: 14px;
		font-weight: bold;
		color: var(--aqua);

		a {
			color: inherit;
		}
	}

	&__logo-client {
		margin-bottom: 25px;

		> svg {
			width: 60px;
			height: 60px;
			margin-bottom: 25px;
		}

		h4 {
			font-size: 20px;
			font-weight: 600;
			margin: 0 0 1px;
			color: var(--white);
		}

		p {
			font-size: 15px;
			font-weight: 300;
			text-align: center;
			color: var(--white);
		}
	}

	.logo {
		margin: 24px 0;
		position: inherit;
		text-align: center;

		img {
			height: 70px;
			// width: 160px; // keep aspect ratio by only specifying height
		}
	}

}

.form-group {
	text-align: left;
	margin-bottom: 10px;
	padding: 0 38px;
}

.form-group-center {
	text-align: center;
	margin-bottom: 10px;
}

.checkbox-group {
	display: inline-block;
	padding: 0 40px;
	width: 100%;

	.checkbox {
		display: inline-block;
		float: left;
		margin: 10px 0 0;
		text-align: left;
		width: 45%;
	}
}

.form-group {
	label {
		@extend .sans-regular;
		line-height: 1.43;
		color: var(--grey-2);
		text-align: left;
		margin-bottom: 5px;
		display: block;

		&.strong {
			font-weight: 600;
		}
	}

	&.choose-domain {
		margin: 25px 0 15px;
		position: relative;

		.choose-domain-input {
			display: table;
			width: 100%;

			input {
				display: table-cell;
				width: 100%;
				/*float: left;*/
				&::placeholder, &::-moz-placeholder {
					text-align: right;
					color: var(--grey-3);
				}

				&.domain-loading {
					border-radius: 3px;
					text-align: right;
					padding: 10px 10px 10px 40px;

					&:focus {
						/*background: url(../../images/icn-loader.svg) no-repeat 10px 10px #F4F8FA;*/
					}

					&.bgnone {
						text-align: left;
						padding: 10px 10px 10px 10px;

						&:focus {
							background: var(--light-blue);
						}
					}
				}

				&.domain-loading-error {
					border-radius: 3px;
					text-align: right;
					padding: 10px 10px 10px 40px;

					&:focus {
						border: solid 1px #fd5000;
						/*
						background: url(../../images/icn-loader.svg) no-repeat 10px 10px #F4F8FA;
						*/
					}

					&.bgnone {
						text-align: left;
						padding: 10px 10px 10px 10px;

						&:focus {
							background: var(--light-blue);
						}
					}
				}
			}
		}

		span {
			display: table-cell;
			@extend .sans-1-bold;
			line-height: 1.38;
			color: var(--grey-3);
			padding: 0 0 0 5px;
			margin: 10px 0 0 0;
			/*float: left;*/
			&.aqua {
				color: var(--aqua)
			}
		}
	}

	&.error-message-text {
		margin: 20px 38px 0;
		padding: 18px 0 0 0;
		border-top: 1px dashed var(--orange);
		text-align: center;

		.text {
			display: inline-block;
			@extend .sans-bold;
			line-height: 19px;
			color: var(--orange);
		}

		p {
			@extend .sans-regular;
			text-align: center;
			color: var(--grey-1);
			line-height: 1.43;
			margin: 12px 0 0;
		}
	}
}

.form-group {
	&.mb22 {
		margin-bottom: 22px;
	}

	&.mb0 {
		margin-bottom: 0px;
	}

	.inline {
		label {
			display: inline-block;
			margin: 0 31px 0 0;
			min-width: 90px;
		}

		input {
			display: inline-block;
			width: 370px;
		}

		.trash-grey {
			margin: 10px 0 0 0;
			vertical-align: top;
			float: right;
		}
	}

	&.file-title {
		@extend .sans-1-bold;
		margin: 0 0 25px;

		img, svg {
			vertical-align: top;
			margin: -4px 10px 0 0;
		}
	}

	&.drop-down {
		margin: 0 0 36px;

		&.mb15 {
			margin-bottom: 15px;
		}

		&.mb5 {
			margin-bottom: 5px;
		}

		.btn-select {
			margin: 0;

			.btn-select-value {
				vertical-align: top;

				img, svg {
					vertical-align: top;
					margin: 0 10px 0 0;
				}
			}

			ul li {
				vertical-align: top;

				img, svg {
					vertical-align: top;
					margin: 0 10px 0 0;
				}
			}
		}
	}
}

.radio-group {
	border-radius: 4px;
	background-color: #ffffff;
	background-color: var(--white);
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
	margin: 0 38px 10px;
	padding: 15px 20px;

	&.mb0 {
		margin-bottom: 0px;
	}

	.radio {
		margin: 0;

		.check {
			left: inherit;
			right: 0;
		}

		label {
			padding: 0;
			@extend .sans-1-bold;
		}
	}

	p {
		@extend .sans-regular;
		width: 90%;
		margin-top: 9px;
	}

}

.radio {
	@extend .pos-rel;
	margin: 0 33px 0 0;
}

.radio input[type=radio] {
	position: absolute;
	visibility: hidden;
	width: 18px;
	height: 18px;
}

.radio label {
	display: block;
	position: relative;
	@extend .sans-regular;
	padding: 0 0px 0 33px;
	margin: 0px auto;
	z-index: 9;
	cursor: pointer;
	-webkit-transition: all 0.25s linear;
}

ul li:hover label {
	color: #FFFFFF;
}

.radio .check {
	display: block;
	position: absolute;
	border: solid 1px var(--grey-4);
	border-radius: 100%;
	height: 18px;
	width: 18px;
	top: 0;
	left: 0px;
	z-index: 5;
	transition: border .25s linear;
	-webkit-transition: border .25s linear;
}

.radio .check::before {
	display: block;
	position: absolute;
	content: '';
	border-radius: 100%;
	height: 12px;
	width: 12px;
	top: 2px;
	left: 2px;
	margin: auto;
	transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;
}

.radio input[type=radio]:checked ~ .check {
	border: 1px solid var(--aqua);
}

.radio input[type=radio]:checked ~ .check::before {
	background: var(--aqua);
}

button {
	font-family: "Source Sans Pro", sans-serif;
	padding: 0;
	margin: 0;
}

.form-group.edit {
	margin: -5px 0 0 0;
	padding: 0;

	input[type="text"] {
		width: 300px;
		height: 40px;
		@extend .sans-1-bold;
	}
}

input[type="radio"] + label:focus + .check {
	border-color: var(--aqua);
}

.error-text {
	margin: 0 0 0 20px;
	display: inline-block;
	@extend .sans-regular;
	color: var(--orange);
}
