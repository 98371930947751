header{
&.admin{
 .logo {
    display: inline-block;
    float: left;
  }
  .admintext {
    font-size: 30px;
    font-weight: 100;
    font-style: normal;
    font-stretch: normal;
    line-height:26px;
    color: var(--white);
    display: inline-block;
    margin:0px 0 0 18px;
  }
}
}

.navbar {
  background: none;
  border: none;
  margin: 0;
  text-align:center;
  position: relative;
  .nav
  {display: inline-block;
  width: auto;
  margin: 0 auto;
  float: none;
  vertical-align:middle;
  ul
  { float: none !important;
  margin: 0 auto;
  overflow: hidden;
  padding:0;
  li
  {
  margin:0;
  padding:0;
  display: inline-block;
  list-style-type: none;
  float: left;


   a {
    float: left;
    opacity:0.8;
    @extend .sans-bold;
    line-height:15px;
    text-align: center;
    color:var(--white);
    overflow: hidden;
    padding:20px 0;
    width:100px;
    text-decoration:none;
	font-size:12px;
    text-transform: uppercase;
    &:hover{
	background:none;
	color:var(--white);
	opacity:1;
	}
	&.active {
    opacity:1;
    background:rgba(0, 0, 0, 0.1);
    color:var(--white);
  }
  span {
    display:block;
    margin: 10px 0 0;
  }
}
}
  }
  }
}


header.header {
  padding:0 30px;
  background:var(--aqua);
  height:90px;
    float: left;
    margin: 0 auto;
    width: 100%;min-width: 1199px;
  .logo {
    margin: 30px 0;
    float: left;
    a{
    float: left;
      height: 28px;
    }
  }
  .logo-client{
    margin:25px 0 25px;
    float:left;
    a{
      float:left;
      height:39px;
      width:40px;
      svg{
        width:40px;
        height:39px;
      }
    }
    
    &__text{
      float:left;
      height:35px;
      margin:2px 0 2px 14px;  
      padding-left:15px;
      color: #f4f7f9;
      border-left:1px solid var(--grey-3);
    }
    &__name{
      position:relative;
      top:-6px;
      color:inherit;
      font-size:22px;
      line-height:28px;
      font-weight:bold;      
    }
    &__endpoint{
      position:relative;
      top:-6px;
      color:inherit;
      font-size:11px;
      line-height:14px;
      font-weight:300;
      letter-spacing:0.3px;
    }
  }
  &.fixed{
    position:fixed;
    top:0;
    z-index:900;
  }
}
header{
.user {
  @extend .sans-regular;
  line-height: 25px;
  text-align: center;
  color: var(--white);
  float: right;
  margin:31px 0 0;
  background:none;
  width:auto;
  height:auto;
  a {
    @extend .sans-regular;
    line-height: 30px;
    text-align: center;
    color:rgba(255, 255, 255, 0.8);
    text-transform: capitalize;
	font-weight:normal;
    display:inline-block;
	.active{display:none;}
    &:hover {
	 color:rgba(255, 255, 255, 1);
	  img{display:none;}
        img.active{display:inline-block;}
        .svg-img{display:none;}
        .active{display:inline-block;
		}
    }
  }
  img.active{display:none;}
  .dropdown-toggle span{
    margin-left:10px;
    display:inline-block;
    float:right;
    }
  .btn {
    &.active, &:active {
      box-shadow: none;
    }
  }
}
}
.user.open > a{
color:rgba(255, 255, 255, 1);
span img{
display:none;
&.active{display:block;}
}

.active{display:block;
.icon{fill: var(--grey-4);}
}

 span .svg-img{
display:none;
&.active{display:block;}
}
}

.user {
.dropdown-toggle{
  padding:0;
  border:none;
  &::after {
    display: none;
  }
  }
  .dropdown-menu {
    width: 280px;
    border-radius: 3px;
    background-color: var(--white);
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
    border: solid 1px var(--aqua);
    left: inherit;
    right: 0;
    padding:10px 0 0;
    top: 70px;
    .dropdown-item {
      @extend .sans-bold;
      line-height: 1.43;
      color:var(--grey-2);
      text-align: left;
      padding: 10px 19px;
	  width: 100%;
	 display:block;
      &:hover {
        @extend .background-light-blue;
        text-decoration: none;
      }
    }
  }
}
header.header.black {
  background:#373f4b;
 .navbar {
 .nav a {
 @extend .sans-bold;
 font-size:12px;
 line-height:19px;
 text-transform: uppercase;
 color: var(--grey-4);
img{
 &.hover{
 	display:none;
	}
	 &.active{
 	display:none;
	}
	}
    &:hover{
	  color: var(--white);
      background:none;
	  .icon{fill: var(--white);}
	img{
	display:none;
	&.hover{
 	display:inline-block;
	}
    &.active{
    display:none;
	}
	}
	}
	&.active {
    background:rgba(0, 0, 0, 0.15);
	color: var(--aqua);
	.icon{fill: var(--aqua);}
	img{
	display:none;
    &.active{
    display:inline-block;
	}
	&.hover{
 	display:none;
	}
	}
  }
}
.user{
a {  
	
	&:hover{
	 color: var(--white);
	 }
	 &.dropdown-item {
      color:var(--grey-2);
	  
	  }
	}
&.open a{
color:var(--white);
&:hover{
color:var(--white);
}
 &.dropdown-item {
      color:var(--grey-2);
	  }
}
	}
}
}

