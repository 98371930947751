.error{
   width: 600px;
   margin: 0 auto;
   text-align: center;

   &__img{
      display: block;
      margin: 0 auto 20px;
      max-width: 100%;
      &--maintenance{
         margin-bottom:35px;
      }
   }

   &__title{
      font-weight:300;
      color:var(--grey-1);
      margin-bottom:20px;
   }

   p{
      text-align: inherit;
      font-size:14px;
      line-height: 1.43;
   }

   &__btns{
      margin-top:45px;
   }

   &__browsers{
      margin-top:35px;
      &-item{
         display:inline-block;       
         margin:0 4px;
         padding:21px 29px 16px;
         border-radius: 3px;
         box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
         background-color: var(--white);
         border:1px solid var(--white);
         
         img{
            display:block;
            margin:0 auto 12px;
            width:60px;
            height:60px;
         }

         strong{
            display:block;
            font-size: 14px;
            color: var(--grey-1);            
         }

         &:hover{
            border:1px solid var(--aqua);
            text-decoration: none;
         }
      }
   }
}