@mixin clearfix {
  :after {
    content: '';
    display: table;
    clear: both;
  }
}
:focus{outline:none;}
.text-center {text-align: center;}
.text-left {text-align: left;}
.text-right {text-align: right;}
.pos-rel{position:relative;}

.white-box {
  background: var(--white);
  padding: 30px;
  border-radius: 3px;
  box-shadow: 0 2px 3px 0 var(--black85);
}
$uppercase: uppercase;
.float-L{float:left;}
.float-R{float:right;}
.dis-n{display:none;}
.dis-b{display:block;}

@mixin box-shadow($top, $left, $blur, $spread, $color, $inset: false) {
  @if $inset {
    -black85lack85ebkit-box-shadow:inset $top $left $blur $spread $color;
    -moz-box-shadow:inset $top $left $blur $spread $color;
    box-shadow:inset $top $left $blur $spread $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $spread $color;
    -moz-box-shadow: $top $left $blur $spread $color;
    box-shadow: $top $left $blur $spread $color;
  }
}
b{font-weight: 600;}
.paddtb30{padding-top:30px;padding-bottom:30px;}
.paddt30{padding-top:30px;}

.paddlr15{padding:0 15px;}
.marginlr130{margin:0 130px;}
.marginr130{margin-right:130px;}
.margint90{margin-top:90px;}
.marginb20{margin-bottom:20px;}
.marginl20{margin-left:20px;}
.marginb30{margin-bottom:30px;}
.marginr30{margin-right:30px;}
.marginr20{margin-right:20px;}
.margint5{margin-top:5px;}
.margint10{margin-top:10px;}
.margint15{margin-top:15px;}
.marginb10{margin-bottom:10px;}
.margint9{margin-top:9px;}
.margint20{margin-top:20px;}
.margint30{margin-top:30px;}
.margintb15{margin-top:15px;margin-bottom:15px;}
.h30{height:30px;}
.uppercase{text-transform: uppercase;}
.underline{text-decoration: underline;}
.marginlr30{margin:0 30px;}
.paddlr40{padding:0 40px;}
.paddlr20{padding:0 20px;}
.marginb25{margin-bottom:25px;}
.marginb80{margin-bottom:80px;}
.marginb160{margin-bottom:160px;}
.mb0{margin-bottom:0px;}
.mrglr30{margin:0 30px;}
.mrgr10{margin-right:10px;}
.mrgl10{margin-left:10px;}
.h100{height:100px;}
.mrgr11{margin-right:11px;}
.mrglr50{margin:0 50px;}


.width960{max-width:960px;width: 100%;}
.text-orange-bold{@extend .sans-bold;color:var(--orange);}
.text-aqua-14i{font-style:italic;@extend .sans-regular;color:var(--aqua);
&.grey-1{@extend .grey-1;}
}

.heading-img-16{
img, svg{margin:0px 24px -8px 20px;}
@extend .sans-1-bold;
color: #5b6e8c;
margin:0 0 20px;
}
h2{
&.f25{
@extend .font-25l;
margin:7px 0 27px;
}
}

.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
}
.icon-disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
}

p {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  color: var(--grey-1);
  margin:0;
  text-align:left;
}

a:hover svg .aqua{fill:var(--aqua);}
svg{vertical-align: top; fill:currentColor;}
.close:hover svg .aqua, .nav-open:hover svg .aqua{fill:var(--aqua);}
a:hover svg .orange{fill:var(--orange);}
a:hover svg .white{fill:var(--white);}

.w32{width:32px;}
.h35{height:35px;}
.wh-8{width:8px;height:8px;}
.wh-12{width:12px;height:12px;}
.wh-14{width:14px;height:14px;}
.wh-15{width:15px;height:15px;}
.wh-16{width:16px;height:16px;}
.wh-20{width:20px;height:20px;}
.wh-25{width:25px;height:25px;}
.wh-27{width:27px;height:27px;}
.wh-30{width:30px;height:30px;}
.wh-40{width:40px;height:40px;}
.wh-50{width:50px;height:50px;}
.wh-60{width:60px;height:60px;}
.wh-70{width:70px;height:70px;}
.wh-80{width:80px;height:80px;}


.z100{position:relative; z-index:100;}
.z110{position:relative; z-index:110;}
.z120{position:relative; z-index:120;}
.z130{position:relative; z-index:130;}
.z140{position:relative; z-index:140;}
.z150{position:relative; z-index:150;}

// Flexbox Shrinkable Spacers
// TO USE: create a div element inside a flexbox container and assign one of the classes below. Make sure container's
// min-width (or min-height) is set to 0px. The div will then shrink (either vertically or horizontally depending on
// container's flex-direction) as the container is shrunk.
.fb-spacer-10{flex:0 3 10px;}
.fb-spacer-20{flex:0 3 20px;}
.fb-spacer-25{flex:0 3 25px;}
.fb-spacer-30{flex:0 3 30px;}
.fb-spacer-40{flex:0 3 40px;}
.fb-spacer-50{flex:0 3 50px;}
