.migration{

   &__notice{
      margin:-22px 0 22px;
   }
   
   &__systems{
      border-radius: 3px;
      border:1px solid #e0e4e9;
      position: relative;
   }
   &__system{
      display:flex;
      border-top:1px solid #e0e4e9;

      &:first-child{
         border-top:none;
      }

      &-icn{
         flex:0 0 82px;

         display:flex;
         flex-direction: column;
         justify-content: center;
         align-items:center;
      }
      &-txt{
         flex:1 0 auto;
         padding:23px 20px 22px 0;
         p{
            font-size:14px;
            &:last-child{
               font-size:12px;
               color:var(--grey-3);
            }
         }
      }
   }

   &__indi{
      position: absolute;
      left:50%;
      top:50%;
      transform:translate(-50%, -50%);

      width: 36px;
      height: 36px;
      box-shadow: 0 1px 3px 0 var(--shadow);
      background-color: var(--white);
      border:1px solid var(--link);
      border-radius:50%;

      display:flex;
      justify-content: center;
      align-items: center;

      svg{
         transform:rotate(180deg);
         color:var(--link);
         path{
            fill:currentColor;
         }
      }
   }

   &__progress{

      &-details{
         margin:20px 0 -15px; 
         display:flex;
         p{
            font-size:14px;
            flex:1 0 auto;
            &:last-child{
               text-align: right;
            }
         }
         
      }

      &-filename{
         margin:10px 0 0;
         p{
            font-size:14px;
         }
      }           
   }


   &__results{
      margin-top:12px;
      width:100%;
      border-collapse: collapse;
      th,
      td{
         text-transform: none;
         padding:10px 0 9px;
         text-align: left;  
         border-bottom:1px solid #e0e4e9;
      }
   }
   
}