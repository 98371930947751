$track-w: 100%;
$track-h: 6px;
$thumb-d: 24px;
$track-c: #e0e4e9;


@mixin track() {
	box-sizing: border-box;
	border: none;
	width: $track-w; height: $track-h;
   background: $track-c;
   border-radius:9px;
}

@mixin thumb() {
	box-sizing: border-box;
	border: none;
	width: 30px; height: 24px;
	background: transparent;
}

.range-slider {
	display: flex;
	align-items: center;
	position: relative;
   width: $track-w; 
   height: 24px;
   font: 1em/1 'Source Sans Pro', sans-serif;
   

   &__control{
      &, &::-webkit-slider-thumb {
         -webkit-appearance: none
      }
      
      flex: 1;
      margin: 0;
      padding: 0;
      min-height: $thumb-d;
      background: transparent;
      font: inherit;
      
      &::-webkit-slider-runnable-track {
         @include track()
      }
      &::-moz-range-track { @include track }
      &::-ms-track { @include track }
      
      &::-webkit-slider-thumb {
         margin-top: .5*($track-h - $thumb-d);
         @include thumb
      }
      &::-moz-range-thumb { @include thumb }
      &::-ms-thumb {
         margin-top: 0;
         @include thumb
      }
      
      &::-ms-tooltip { display: none }
      
      
   }

   &__value{
      position: absolute;
      top:-1px;
      width:30px;
      height:24px;
      line-height:24px;
      font-size:14px;
      font-weight:bold;
      text-align: center;
      color:#fff;   
      pointer-events: none; 
      background: var(--aqua);
      border-radius:3px;
   }


}

