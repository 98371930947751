.tag {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 4px;
	padding: 2px 6px;
	border-radius: 4px;
	width: max-content;

	.capsule{
		font-weight:bold;
		padding:0 11px;
		cursor: pointer;
		&:hover{
			text-decoration: none;
		}
	}

	&-info {
		@extend .tag;
		background-color: rgba(12, 159, 237, 0.1);
		color: var(--dark-blue);

		.capsule {
			color:var(--dark-blue);
			border-color: var(--dark-blue);
			&:hover {
                color: var(--white);
                background-color: var(--dark-blue);
            }
		}
	}

	&-success {
		@extend .tag;
		background-color: rgba(25, 197, 162, 0.1);
		color: var(--aqua);

		.capsule {
			color:var(--aqua);
			border-color: var(--aqua);
			&:hover {
				color: var(--white);
				background-color: var(--aqua);
			}
		}
	}

	&-warning {
		@extend .tag;
		background-color: rgba(255, 153, 0, 0.1);
		color: var(--dark-yellow);

		.capsule {
			color:var(--dark-yellow);
			border-color: var(--dark-yellow);
			&:hover {
				color: var(--dark-yellow);
				background-color: var(--dark-yellow);
			}
		}
	}

	&-error {
		@extend .tag;
		background-color: rgba(253, 80, 0, 0.1);
		color: var(--orange);

		.capsule {
			color:var(--orange);
			border-color: var(--orange);
			&:hover {
				color: var(--orange);
				background-color: var(--orange);
			}
		}
	}

	svg {
		flex-shrink: 0;
		flex-grow: 0;
		width: 16px;
		height: 16px;
	}

	&__message {
		align-self: flex-start;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
	}
}
