.clickable {
   cursor: pointer;
}

.panel {
   border-radius: 0;
   border: none;
   float: left;
   width: 100%;
   margin: 0 0 20px;
}

.panel-primary {
   box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
   .panel-heading {
      background: var(--white);
      border: none;
      border-radius: 3px;
      padding: 20px 27px;
      cursor: pointer;
      height: 80px;
      position: relative;
      p {
         @extend .sans-regular;
         color: var(--grey-1);
         font-style: italic;
      }
      .img-left {
         display: inline-block;
         vertical-align: top;
      }
      img {
         display: inline-block;
         &.mb-10 {
            margin-bottom: -10px;
         }
      }
      .panel-text {
         display: inline-block;
         margin: 0 0 0 23px;
      }
      .panel-title {
         margin: 0;
         padding: 0;
         text-align: left;
         @extend .font-20b;
         line-height: 20px;
         display: inline-block;
      }
      span {
         margin: 13px 0 0 0;
      }
      .accor-minus {
         background: url(../../images/chevron-up.svg) no-repeat 0 0;
         width: 20px;
         height: 20px;
         display: inline-block;
         &:hover {
            background: url(../../images/chevron-up-hover.svg) no-repeat 0 0;
         }
      }
      .accor-plus {
         background: url(../../images/chevron-up.svg) no-repeat 0 0;
         width: 20px;
         height: 20px;
         display: inline-block;
         &:hover {
            background: url(../../images/chevron-up-hover.svg) no-repeat 0 0;
         }
      }
      .switch {
         float: right;
         margin: 8px 0 0 0;
      }
   }
   &.panel-defult {
      .panel-body {
         display: block;
      }
   }
   &.pdefult {
      .panel-body {
         display: block;
         border-top: none;
      }
   }
   .panel-body {
      //display: none;
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      position: relative;
      background: var(--white);
      border: none;
      border-top: solid 1px var(--grey-5);
      padding: 0; //z-index:99;
      transition: all .75s cubic-bezier(0.1, 0.9, 1, 1) -.35s, opacity .25s ease 0s;
      .panel-body__inner {
         position: relative;
         padding: 20px 0 40px;
         .aqua-plus {
            padding: 0;
         }
      }
      &.open-content {
         //display:block;
         max-height: 4999px;
         overflow: visible;
         opacity: 1;
         transition: all 1s cubic-bezier(0.9, 0.1, 1, 1) 0s, opacity .5s ease .25s; //animation:growPanel 1s ease-in-out forwards;
      }
      /*
@keyframes growPanel{
  0%{
    display: none;
    opacity:0;
  }
  1%{
    display:block;
    max-height:0;
  }
  15%{
    opacity:0;
  }
  85%{
    opacity:1;
  }
  99%{
    max-height:1000px;
    display: block;
  }
  100%{
    max-height:none;
  }
}
  */
      .detail {
         max-width: 600px;
         margin: 0px 0 0 90px;
         width: 63%;
         display: inline-block;
         min-height: 50px;
         vertical-align: top;
         &.w42p {
            width: 42%;
         }
         &.ml-40 {
            margin-left: 40px;
         }
      }
      .details {
         box-shadow: 0 1px 0 0 var(--grey-5);
         margin: 0;
         padding: 10px 0;
         height: 50px;
         img {
            vertical-align: top;
         }
         &.padd15 {
            padding: 15px 0;
         }
         .pill-label {
            margin-right: 50px;
         }
      }
      .detail-text {
         display: inline-block;
         margin: 0 0 0 10px;
         text-align: left;
         @extend .sans-bold;
         p {
            @extend .font-12;
            color: var(--grey-3);
         }
      }
   }
}

.btn-small {
   width: 104px;
   height: 20px;
   border-radius: 100px;
   border: solid 1px var(--grey-4);
   color: var(--grey-3);
   @extend .font-12;
   font-weight: bold;
   line-height: 20px;
   padding: 0px 6px;
   text-align: left;
   margin: 0 50px 0 0;
   text-transform: none;
   &.w120 {
      width: 120px;
   }
   i {
      margin: 2px 6px 0 0;
   }
   &.mt3 {
      margin-top: 5px;
   }
   &:hover {
      text-decoration: none;
   }
   img {
      margin: 2px 6px 0 0;
   }
}

.pill-label {
   display: inline-block;
   min-width: 92px;
   height: 20px;
   border-radius: 100px;
   border: solid 1px var(--grey-4);
   color: var(--grey-3);
   @extend .font-12;
   line-height: 19px;
   padding: 0px 6px;
   text-align: center;
   margin: 0;
   text-transform: none;
   background: var(--white);
   &--mt3 {
      margin-top: 3px;
   }
   &--mt5 {
      margin-top: 5px;
   }
   svg {
      width: 14px;
      margin-right: 5px;
   }
}

.panel-heading {
   &.inactive-header {
      .accor-minus {
         background: url(../../images/chevron-down.svg) no-repeat 0 0;
         width: 20px;
         height: 20px;
         display: inline-block;
         &:hover {
            background: url(../../images/chevron-down-hover.svg) no-repeat 0 0;
         }
      }
      .accor-plus {
         background: url(../../images/chevron-down.svg) no-repeat 0 0;
         width: 20px;
         height: 20px;
         display: inline-block;
         &:hover {
            background: url(../../images/chevron-down-hover.svg) no-repeat 0 0;
         }
      }
   }
}

.panel-primary {
   &.panel-secondery {
      .panel-heading {
         cursor: inherit;
         .panel-text {
            vertical-align: top;
            margin: 9px 0 0 23px;
         }
      }
      .panel-body {
         display: block;
         max-height: inherit;
         opacity: 1;
         .detail {
            max-width: inherit;
         }
      }
   }

  &.panel-vaults{
    .panel-heading{
      .panel-text {
        vertical-align: top;
        margin: 9px 0 0 23px;
      }
    }
    .panel-body{
      border-top:none;
      max-height:none;
      overflow: visible;
      opacity: 1;
      .detail{
        > p{
          font-size:14px;
          margin:-10px 0 20px;
        }
        .details{
          padding:20px 0;
          height:auto;
          &--lg{
            padding:25px 0;
          }
        }
        .detail-text{
          width:40%;
        }
        .detail-value{
          display:inline-block;
          font-size:16px;
          line-height:1.25;
        }
        .detail-label{
          display:inline-block;
          width:20%;
          font-size:12px;
          line-height:20px;
          font-weight:700;
          text-transform: uppercase;
          color:var(--grey-2);
        }
        .detail-content{
          display:inline-block;
          width:60%;
          color:var(--grey-1);
        }
        .switch{
          float:right;
          margin-right:20px;
        }
      }
    }
  }

	&.panel-settings {
		padding: 20px;

		.panel-heading {
			cursor: default;
			height: 34px;
			padding: 0;

			.panel-text {
				vertical-align: top;
				margin: 9px 0 0 23px;
			}
		}

		.panel-body {
			border-top: none;
			max-height: none;
			overflow: visible;
			opacity: 1;
			padding: 0;

			&__inner {
				padding: 0;
				position: relative;
				margin: 0 0 10px 60px;

				.aqua-plus {
					padding: 0;
				}
			}

			.detail {
				margin: 0;

				> p {
					font-size: 14px;
					margin: -10px 0 20px;
				}

				.details {
					padding: 20px 0;
					height: auto;

					&--lg {
						padding: 25px 0;
					}

					&--input {
						box-shadow: none;
						margin: 10px 0;
						padding: 0;

						.detail-label {
							color: var(--grey-1);
							display: inline-block;
							width: 40%;
							font-size: 14px;
							font-stretch: normal;
							font-style: normal;
							font-weight: bold;
							line-height: 1;
							letter-spacing: normal;
							margin: 15px 0;
							text-align: left;
							text-transform: none;
							vertical-align: top;
						}
					}

					&--trusted-devices {
						color: var(--grey-1);
						display: inline-block;
						margin: 0;
						padding: 0;
					}
				}

				.detail-text {
					width: 40%;
				}

				.detail-value {
					display: inline-block;
					font-size: 16px;
					line-height: 1.25;
				}

				.detail-label {
					color: var(--grey-1);
					display: inline-block;
					width: 40%;
					font-size: 14px;
					font-stretch: normal;
					font-style: normal;
					font-weight: bold;
					line-height: 1.43;
					letter-spacing: normal;
					text-align: left;
					text-transform: none;
					vertical-align: top;
				}

				.detail-content, .form-group {
					color: var(--grey-1);
					display: inline-block;
					margin: 0;
					padding: 0;
					width: 60%;

					input[type="password"] {
						width: 100%;
						padding: 10px;
					}

					.inline-edit-input {
						width: 100%;
					}
				}

				.form-group.has-error .validation-message {
					margin: 0;
				}

				.form-group.error-message {
					color: var(--action-error);
					float: right;
					padding: 10px;
				}

				.switch {
					float: right;
					//margin-right: 0;
				}
			}
		}

		.panel-footer {
			margin: 0;

			.action {
				display: inline-block;
				margin: 0 0 0 60px;
				max-width: 600px;
				width: 63%;

				&--space {
					display: inline-block;
					width: 40%;
				}
			}
		}
	}

	&.panel-authenticator {
		.panel-heading {
			padding: 15px;
			height: auto;

			image {
				height: 25px;
				vertical-align: bottom;
				width: 25px;
			}

			.panel-text {
				margin: 0 0 0 15px;
			}

			span {
				margin: 0;
			}
		}

		.panel-body {
			align-items: center;
			border-top: none;
			display: flex;
		}
	}
}

.panel {
   &.panel-primary {
      &.panel-third {
         .panel-heading {
            cursor: inherit;
            position: relative;
         }
         .panel-body {
            display: none;
            border-top: none;
            /* overflow:hidden;*/
            .pos-rel {
               z-index: 1;
            }
            &.hide1 {
               display: block;
               /*max-height: 0; transition: max-height 1s ease-in-out;*/
               overflow: hidden;
               /*height:0;*/
               padding: 0;
               max-height: 1px;
               overflow: hidden;
               transition: all .3s ease;
            }
            &.show1 {
               display: block;
               /*height:auto;*/
               max-height: 500px;
               transition: all 1s ease;
               opacity: 1;
               /* max-height:auto;transition: max-height 1s ease-in-out;*/
            }
            .detail {
               h2 {
                  @extend .font-20b;
                  color: var(--aqua);
                  &.orange {
                     color: var(--orange);
                  }
                  .img-left {
                     display: inline-block;
                     margin: 0 10px 0 0;
                     img {
                        margin-bottom: -2px;
                     }
                     svg {
                        margin-top: 5px;
                     }
                  }
               }
               .details {
                  .detail-text {
                     &.f14 {
                        @extend .sans-regular;
                        color: var(--grey-2);
                     }
                  }
               }
            }
         }
      }
      &.panel-forth {
         margin-bottom: 10px;
         .panel-heading {
            height: 60px;
            padding: 18px 10px 17px 20px;
            position: inherit;
            cursor: default;
            .switch {
               margin: 0;
            }
            img {
               vertical-align: top;
            }
            .panel-title {
               @extend .sans-bold;
               line-height: 1.43;
            }
            .panel-text {
               margin: 0 0 0 20px;
            }
         }
         .panel-body {
            padding: 0;
            .pos-rel {
               padding: 20px 0 32px;
            }
            label {
               padding-bottom: 5px;
               display: inline-block;
            }
            &.hide1 {
               /*height:0;@extend .animation;display:none;*/
               display: block;
               opacity: 0.5;
               overflow: hidden;
               padding: 0;
               max-height: 1px;
               transition: all .3s ease;
            }
            &.show1 {
               /*display:block; height:auto;@extend .animation;*/
               display: block;
               opacity: 1;
               max-height: 500px;
               transition: all 1s ease;
            }
         }
      }
   }
}

.panel-primary.panel-third.accordion .panel-body {
   display: none;
   @extend .animation;
}

.panel-primary.panel-third.accordion.active .panel-body {
   display: block;
   @extend .animation;
}

#custom2.panel-body.show1 {
   display: none;
   max-height: 1px;
   -webkit-transition: all .3s ease;
   -moz-transition: all .3s ease;
   -o-transition: all .3s ease;
   -ms-transition: all .3s ease;
   transition: all .3s ease;
}

#custom1.panel-body.dis-n.show1 {
   display: none;
   max-height: 1px;
   -webkit-transition: all .3s ease;
   -moz-transition: all .3s ease;
   -o-transition: all .3s ease;
   -ms-transition: all .3s ease;
   transition: all .3s ease;
}

#custom2.panel-body.show1.dis-b {
   display: block;
   max-height: 500px;
   -webkit-transition: all 1s ease;
   -moz-transition: all 1s ease;
   -o-transition: all 1s ease;
   -ms-transition: all 1s ease;
   transition: all 1s ease;
}

.panel.panel-primary .panel-top .btn-group {
   margin: 0;
}

.panel.panel-primary.sml {
   .panel-heading {
      height: 60px;
      padding: 20px;
      span {
         margin: 0;
      }
      .panel-title {
         min-width: 235px;
      }
      p {
         display: inline-block;
      }
   }
   .panel-body {
      padding: 0;
      .pos-rel {
         padding: 18px 0 30px;
      }
      &.bdr-none {
         border: none;
         padding: 0;
         .pos-rel {
            padding: 0 0 30px;
         }
      }
      .detail {
         min-height: auto;
         margin: 0 0 0 20px;
      }
   }
}
