.bootstrap-tagsinput {
  background-color:var(--light-blue);
  border:solid 1px var(--grey-5);
  box-shadow:none;
  display: inline-block;
  color:var(--grey-1);
  vertical-align: top;
  border-radius:3px;
  max-width: 100%;
  line-height: 22px;
  cursor: text;
  padding:5px;
  width: 400px;
  height:130px;
  overflow-y:scroll;
input {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0 6px;
  margin: 0;
  width: auto;
  max-width: inherit;
&:focus {
  border: none;
  box-shadow: none;
  background:transparent;
}
}
 .tag {
  margin-right: 2px;
  color: white;
 [data-role="remove"] {
  margin-left: 8px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0) url("../../images/close-white-small.svg") no-repeat scroll 3px 3px;
    border-radius: 100px;
    display: inline-block;
    height: 14px;
    margin: 0 0 -2px 7px;
    padding: 2px;
    width: 14px;
&:hover {
 background: #38a3e1 url("../../images/close-white-small.svg") no-repeat scroll 3px 3px;
}
}
}

span{
&.label {
    color: #ffffff;
    display: inline-block;
    font-weight:600;
    line-height:1.25;
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;
	margin: 0 5px 5px 0;
    padding:2px 8px 3px 8px;
	border-radius: 100px;
	font-size: 16px;
	float: left;
	height:25px;
	&.label-info {
    background-color: #4bb7f5;
}
}
}
}

