@import '_config.scss';
@import 'libs/_extends.scss';
@import 'base/_extends.scss';
@import 'components/_extends.scss';
@import 'modules/_extends.scss';
@import 'layouts/_extends.scss';



* {
  box-sizing:border-box;
}
html, body{
  font:14px 'Source Sans Pro', sans-serif;
  line-height: 1.38;
  height: 100%;
  margin:0 auto;
  padding:0;
}
.container {

}

.app-wrapper {
  height: 100%;
}

.pallet-container {
  border: 1px solid yellow;
}

a {
  cursor: pointer;
  color: var(--dark-blue);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

div[class^='layout'],div[class*='layout']{
  position: relative;
  min-width: 990px;
  padding-top: 75px;
  height: 100%;
  min-height: 600px;
  .footer-basic {
    position: absolute;
    bottom:0;
    width: 100%;
  }
}


.filler {
  min-height: 50px;
  &.filler-red {
    background: red;
  }
  &.filler-blue {
    background: blue;
  }
  &.filler-green {
    background: green;
  }
}

/*h2 {
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: var(--grey-1);
  display: inline-block;
  span{
  color: var(--grey-3);
  }
}

h3 {
  font-size: 25px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  text-align: center;
  color: var(--grey-2);
}

p {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  color: var(--grey-1);
  margin:0;
  text-align:left;
}
*/



#main{
}

.container {
  min-width:1160px;
  width:100%;
}
@media (max-width: 1415px) {
	.device-white-container .discovered .nomber{font-size:34px; line-height:34px;}
}
@media (max-width: 1400px) {
  
  .sidebar-left.team-sidebar{width: 219px;}
  #discoverd.dis-width {margin-left:220px;}
  .sidebar-left.team-sidebar .shedow{max-width:219px;}
  .device-sec-main .device-sec.dev-width {margin-left:220px;}
  .policies ul li.text-icon{width: 33%;}
  .policies ul li:first-child {
    width: 35%;
  }
  .policies ul li.icon {
    width: 22%;min-width: 243px;
  }

  .device-white-container.w480 {
    width: 32.2%; margin:0 1.2% 0 0;
  }
  .device-white-container.w185 {
    margin:0 1.2% 0 0;
  }

  .device-white-container.last{margin:0;}
  .table > tbody > tr > td{padding: 13px 15px 11px;}
  .table > thead > tr > th{padding: 7px 15px;}
  .panel-table .table.storage > tbody > tr > td.name-text span{width:auto;}
  .panel-table .table.storage > tbody > tr > td.name-text{width:22%;}

 
  
  
  
  .dis-container.dis-width{
    .legend{width:55%;}
  }

}

@media (min-width: 1201px) and (max-width: 1500px) {
  .table.policy.storage tbody > tr > td.name-text {
    width: 22%;
  }
  .table.policy.storage thead > tr > th.policy {
    width: 22%;
  }

}

@media (max-width: 1200px) {
  .main-container{width: 1199px;}
  .policies ul li.text-icon span {
    margin: 8px 5%;
  }
  .policies ul li:first-child {
    width: 35%;
  }
  .policies ul li.icon {
    width: 22%;min-width: 243px;
  }
  .table > tbody > tr > td{padding: 13px 11px 11px;}
  .table > thead > tr > th{padding: 7px 11px;}
	
	/*
  .device-white-container.w480 {
    width: 32.5%; margin: 0 1.2% 0 0;
  }
  .device-white-container.w185 {
    min-width: 140px;
    padding: 16px 10px;
    width: 15%; margin: 0 1.2% 0 0;
  }
  
  .device-white-container.last{margin:0;}
  */
  
  .panel-table .table.storage > tbody > tr > td.name-text span{width:auto;}
}

@media (min-width: 992px) and (max-width: 1199px) {
}

@media (max-width:992px) {
}
body:empty #discoverd {
  height: 100%;
}
@-moz-document url-prefix() {
  #discoverd { height: 100%;}
}
@media (min-width:4000px) and (max-width:5999px) {
  .device-white-container.protect{height: 240px;}
  .pie-chart {width: 475px;}
  .device-white-container.w429{height:220px;}
}
@media (min-width:6000px) and (max-width:8000px) {
  .device-white-container.protect{height: 275px;}
  .pie-chart {width: 475px;}
  .device-white-container.w429{height:275px;}

}


label:active + .check {
  color:blue;
}
