.fullpage-bg {
  padding: 30px;
  background:no-repeat 0 0 /cover #353d4b;
  height: 100%;
  position:relative;
  overflow: hidden;
  width: 100%;
  display:table;

  &.fullpage-bg--has-pattern{
    background-image:url(../../images/background-fragments.png);
  }
  
  &--overlay{
    position: fixed;
    z-index:99999;
    top:0;
    left:0;
    right:0;
    bottom:0;    
    background-color: rgba(53, 61, 75, 0.9);    
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }
  
  

}
.fullpage-greybg {
  padding: 30px;
  background:#f4f8fa;
  height: 100%;
  display:table;
  width: 100%;
}
.fullwidth{
  display: table;
  height: 100%;
  width: 100%;
}


.fullpage{
  padding: 30px;  
  height: 100%;
  display:table;
  width: 100%;
  &--white{
    background:var(--white);
  }
  &--lightgrey{
    background:#f4f7f9;
  }

  &__center{
    margin: 0;
    display: table-cell;
    vertical-align: middle;
  }
}



