.pt-perspective {
	position: relative;
	width: 100%;
	height: 100%;
	-webkit-perspective: 1200px;
	-moz-perspective: 1200px;
	perspective: 1200px;
}

.pt-page {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	visibility: hidden;
	/*overflow: hidden;*/
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.pt-page-current,
.no-js .pt-page {
	visibility: visible;
	z-index: 1;
}

.no-js body {
	overflow: auto;
}

.pt-page-ontop {
	z-index: 999;
}




/* Triggers (menu and button) */

.pt-triggers {
	position: absolute;
	width:auto;
	z-index: 999999;
	top:0;
	left:50%;
	margin-top:115px;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%); 
	transform: translateX(-50%);
}

.no-js .pt-triggers {
	display: none;
}

.pt-touch-button {
	border: none;
	border-radius:100px;
	font-size: 15px;
	font-weight: 600;
	text-transform: uppercase;
	color:var(--white);
	margin: 0;
	padding: 0px 15px;
	line-height: 35px;
	height: 35px;
	letter-spacing: 1px;
	width: 100%;
	cursor: pointer;
	display: block;
	text-align:center;
	background:var(--aqua);
	box-shadow:none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.pt-touch-button:hover {
text-decoration:none;
}
.pt-touch-button:active {
	/*box-shadow: 0 1px 0 rgba(0,0,0,0.1);*/
}
