.toast-title {
  font-weight: bold;
}
.toast-message {
}
.toast-message a,
.toast-message label {
  color: #ffffff;
}
.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}
.toast-close-button {
  position: absolute;
  right: 10px;
  top: 12px;
  text-align: left;
  text-indent: -9999px;
  overflow: hidden;
  width:19px; height:19px;
  background:transparent url(../../images/close-white-small.svg) center center no-repeat;
  background-size:8px 8px;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  /*background: transparent;*/
  border: 0;
  -webkit-appearance: none;
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
  text-align: center;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}
#toast-container {
	position: fixed;
	z-index: 999999;
	display:flex;
	flex-direction: column;
	pointer-events:none;
}

#toast-container * {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

#toast-container > div {
	display: block;
	position: relative;
	min-width:300px;
	margin-top: 2px;
	padding: 13px 35px 13px 50px;
	min-height: 45px;
	border-radius: 4px 4px;
	background-position: 15px center;
	background-repeat: no-repeat;
	background-size: 18px 18px;
  color: var(--white);
  pointer-events:all;
	&:last-child{
		border-radius:4px 4px 0 0;
	}
}

#toast-container > .toast-info {
  background-image:url(../../images/toaster/icn-info.png);
}
#toast-container > .toast-error {
  background-image:url(../../images/toaster/icn-error.png);
}
#toast-container > .toast-success {
  background-image:url(../../images/toaster/icn-confirm.png);
}
#toast-container > .toast-warning {
  background-image:url(../../images/toaster/icn-warning.png);
}
#toast-container.toast-top-center > div,
#toast-container.toast-bottom-center > div {
  /*width: 350px;*/
  max-width:700px;
  margin-left: auto;
  margin-right: auto;
}
#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.toast {
  background-color: var(--dark-blue);
}
.toast-success {
  background-color: var(--dark-blue);
}
.toast-error {
  background-color: var(--orange);
}
.toast-info {
  background-color: var(--dark-blue);
}
.toast-warning {
  background-color: var(--grey-2);
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}





.inline-toaster{
  display: block;
  margin:15px 0;	
  position: relative;	
  min-width:300px;
  padding: 5px 14px 5px 34px;
  
  border-radius: 4px 4px;
  background-position: 7px center;
  background-repeat: no-repeat;
  background-size: 18px 18px;

  &__msg{    
    color: var(--white);
    text-align: left;
    font-style: normal;
    line-height:1.45;
    .link{
      font-weight:bold;
      color:var(--white);      
    }
  }

  &--success {    
    background-color: var(--dark-blue);
    background-image:url(../../images/toaster/icn-confirm.png);
  }
  &--error {
    background-color: var(--orange);
    background-image:url(../../images/toaster/icn-error.png);
  }
  &--info {
    background-color: var(--dark-blue);
    background-image:url(../../images/toaster/icn-info.png);
  }
  &--warning {  
    background-color: var(--grey-2);
    background-image:url(../../images/toaster/icn-warning.png);
  }

}

/* specific positiong classes */
.panel-top + .inline-toaster{
  margin-top:-15px;
}