.listing{
&.marl62{margin-left:62px;}
ul{
margin:0 auto;
padding:0;
min-width:600px;
display:inline-block;
@extend .sans-regular;
color: var(--grey-1);
width:60%;
li{
border-top: solid 1px var(--grey-5);
padding:10px 7px;
&:last-child{border-bottom: solid 1px var(--grey-5);}
span{font-weight:600;min-width:235px;display: inline-block;}
}
}
}

