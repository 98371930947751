
@-webkit-keyframes "bake-pie" {
	from {
		-webkit-transform: rotate(0deg) translate3d(0, 0, 0);
		transform: rotate(0deg) translate3d(0, 0, 0);
	}
}
@keyframes "bake-pie" {
	from {
		-webkit-transform: rotate(0deg) translate3d(0, 0, 0);
		transform: rotate(0deg) translate3d(0, 0, 0);
	}
}
.pie-chart--wrapper {
	text-align: center;
}
.pie-chart__pie {
	display: inline-block;
	vertical-align: top;
	position: relative;
	width: 90px;
	margin: 0 0 0 7px;
	/*float: left;*/
	&::before {
		content: "";
		display: block;
		position: absolute;
		z-index: 1;
		width: 80px;
		height: 80px;
		background: #fff;
		border-radius: 50%;
		top: 5px;
		left: 5px;
	}
}
.pie-chart__legend {
	display: inline-block;
	vertical-align: top;
	display: none;
	list-style-type: none;
	padding: 0;
	margin: 0 auto;
	background: #FFF;
	padding: 0.75em 0.75em 0.05em;
	font-size: 13px;
	text-align: left;
	width: 65%;
	li {
		height: 1.25em;
		margin-bottom: 0.7em;
		padding-left: 0.5em;
		border-left: 1.25em solid black;
	}
	em {
		font-style: normal;
	}
	span {
		float: right;
	}
}
.slice {
	position: absolute;
	width: 90px;
	height: 90px;
	clip: rect(0px, 100px, 100px, 50px);
	-webkit-animation: bake-pie 1s;
	animation: bake-pie 1s;
	span {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		background-color: black;
		width: 90px;
		height: 90px;
		border-radius: 50%;
		clip: rect(0px, 100px, 100px, 40px);
	}
}
.pie-charts {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
}
.legend {
	list-style-type: none;
	padding: 0;
	margin: 0 0 0 32px;
	padding: 0;
	display: inline-block;
	color: var(--grey-1);
	@extend .sans-regular; 
	font-size: 12px;
	line-height: 1;
	float: right;
	width:55%;
	li {
		/*width: 160px;*/ width: 100%;
		margin: 0 0 8px;
		padding: 0;
		float: left;
		display: inline-block;
		clear: both;
		span{
		    width: 8px;
			height: 8px;
			display: inline-block;
			border-radius: 10px;
			margin: 2px 5px 0 0;
			float: left;
    
    /*
		&.blue {
			background: #0071c5;
		}
		&.red {
			background: #e71a4c;
		}
		&.yellow {
			background: #ffc107;
		}
		&.grey {
			background: #c6ced8;
		}
		&.lightblue {
			background: #4bb7f5;
		}
    */
      
    &.darkgreen{
      background-color: #009074;
    }
    &.lightgreen{
      background-color: #7bd500;
    }
    &.turquoise{
      background-color: #00c6a2;
    }
    &.blue,
    &.darkblue{
      background-color: #006fc8;
    }
    &.lightblue{
      background-color: #44b6f8;
    }
    &.purple{
      background-color: #9100ff;
    }
    &.pink{
      background-color: #d25cea;
    }
    &.maroon{
      background-color: #c7377b;
    }
    &.red{
      background-color: #ea1248;
    }
    &.darkorange{
      background-color: #ff4f00;
    }
    &.lightorange{
      background-color: #ff9900;
    }
    &.yellow{
      background-color: #ffc200;
    }
    &.grey {
      background-color: #c6ced8;
    } 
      
		}
	}
	em {
		font-style: normal;
		float: left;
		display: inline-block;
	}
	span:last-child {
		float: right;
		font-weight: 600;
		margin: 0;
		display: inline-block;
		width: auto;
	}
}
.legend.w155 {
	li {
		width: 150px;
	}
}
.chart-round {
	background: #000;
	width: 90px;
	height: 90px;
	display: inline-block;
	border-radius: 100px;
}
.legend-text {
	left: 5px;
	position: absolute;
	top: 23px;
	z-index: 99;
	@extend .sans-regular; font-size: 30px;
	line-height: 1;
	font-weight: 600;
	color: var(--grey-2);
	width: 80px;
	p{margin: 0;
	font-size: 12px;
	font-weight: normal;
	line-height: 12px;
	text-align:center;
}
&.protect,
&.small{
	top:29px;
}
}
#data-chart{
.pie-chart__pie {
	display: inline-block;
	vertical-align: top;
	position: relative;
	width:110px;
	margin:0;
	&::before {
		content: "";
		display: block;
		position: absolute;
		z-index: 1;
		width: 98px;
		height: 98px;
		background: #fff;
		border-radius: 50%;
		top: 6px;
		left: 6px;
	}
}
.pie-chart__legend {
	display: inline-block;
	vertical-align: top;
	display: none;
	list-style-type: none;
	padding: 0;
	margin: 0 auto;
	background: #FFF;
	padding: 0.75em 0.75em 0.05em;
	font-size: 13px;
	text-align: left;
	width: 65%;
	li {
		height: 1.25em;
		margin-bottom: 0.7em;
		padding-left: 0.5em;
		border-left: 1.25em solid black;
	}
	em {
		font-style: normal;
	}
	span {
		float: right;
	}
}
.slice {
	position: absolute;
	width:110px;
	height: 110px;
	clip: rect(0px, 115px, 115px, 51px);
	-webkit-animation: bake-pie 1s;
	animation: bake-pie 1s;
	span {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		background-color: black;
		width: 110px;
		height: 110px;
		border-radius: 50%;
		clip: rect(0px, 115px, 115px, 55px);
	}
}

.legend-text {
	left:30px;
	top:33px;
	}
#location-chart{
ul.legend { margin:15px 0 0 15px;}
}
}

@media (max-width: 500px) {
	.pie-charts {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}

.dis-container.dis-width{
.legend{ margin: 0 0 0 13px;width:60%;height:100px;}
}

.pie-chart{/*width:280px;*/margin:0 auto;
&.w280{width:280px;}
}

@media (max-width: 1400px) {
	.device-progress-sec .pie-chart{width:auto;margin:0 auto;}
}


.pie-chart-inner-left {
	float: left;
	width: 35%;
	.pie-chart__pie {
		float: none;
	}
}

.pie-chart-inner-right {
	float: right;
	width: 65%;
	.legend {
		width:85% !important;
	}
}
