#sidebar {
   background: var(--light-blue);
   width: 15%;
   float: left;
   min-height: 700px;
   .filter-title {
      @extend .sans-regular;
      line-height: 1.43;
      color: var(--grey-3);
      margin: 0;
      padding: 20px;
      overflow: hidden;
      border-bottom: 1px solid var(--grey-5);
      &.lightblack {
         border-bottom: 1px solid var(--grey-5);
      }
   }
   a {
      @extend .sans-regular;
      line-height: 1.43;
      color: var(--grey-3);
      &:hover {
         text-decoration: none;
      }
   }
   ul {
      margin: 20px 0 0;
      padding: 0 20px;
      display: inline-block;
      list-style-type: none;
      float: left;
      border-bottom: none;
      li {
         margin: 0;
         padding: 0;
         display: inline-block;
         list-style-type: none;
         width: 100%;
         a {
            margin: 0 0 10px;
            padding: 0;
            @extend .sans-regular;
            line-height: 1.43;
            color: var(--grey-1);
            display: inline-block;
            &:hover {
               text-decoration: none;
            }
         }
      }
   }
   h3 {
      @extend .sans-regular;
      line-height: 1.43;
      color: var(--grey-1);
      text-align: left;
      margin: 20px 0;
   }
}

/*.filter-box {
  border-bottom: 1px solid var(--grey-5);
  margin: 0;
  padding: 0 20px 10px;
  overflow: hidden;
  .checkbox {
    width: 100%;
    margin: 0 0 15px;
  }
	 .close {
		 background: url(../../images/close.svg) no-repeat 0 0;
         width: 20px;
         height: 20px;
         float: right;
		&:hover {
          background: url(../../images/close-active.svg) no-repeat 0 0;
		}
  }
}*/

#sidebar .plus {
   background: url(../../images/add.svg) no-repeat 0 0;
   width: 20px;
   height: 20px;
   padding: 0px;
   float: right;
   &:hover {
      cursor: pointer;
   }
}

#sidebar .plus.add-select {
   background: url(../../images/add-select.svg) no-repeat 0 0;
   width: 20px;
   height: 20px;
   float: right;
}

.btn-info {
   background: none;
   border: none;
   @extend .sans-regular;
   line-height: 1.43;
   color: var(--grey-3);
   float: left;
   margin: 0;
   padding: 0;
   text-align: left;
   width: 100%;
}

.sidebar-left {
   width: /*12%*/
   220px;
   float: left;
   height: 100%;
   -moz-box-shadow: inset -1px 0 0 0 var(--grey-5);
   -webkit-box-shadow: inset -1px 0 0 0 var(--grey-5);
   box-shadow: inset -1px 0 0 0 var(--grey-5);
   padding: 30px 0 0;
   min-width: 220px;
   max-width: 380px;
   background: #fff;
   /*position:absolute;
  left:0;right:0; top:0; min-height:690px;*/
   position: fixed;

	h3 {
		@extend .font-25l;
		color: var(--grey-2);
		text-align: left;
		margin: 0 0 20px 30px;
		display: block;
	}

	h4.user-settings {
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		text-align: left;
		text-transform: uppercase;
		margin: 30px 20px 10px;
	}

   ul {
      margin: 0;
      padding: 0;
      display: block;
      list-style-type: none; //float: left;
      border-bottom: none;
      min-height: 77px;
      max-height: 100%;
      overflow-y: auto;
      li {
         margin: 0;
         padding: 0;
         display: inline-block;
         list-style-type: none;
         width: 100%;
         a {
            margin: 0 1px 0 0;
            padding: 10px 24px 10px 30px;
            @extend .sans-regular;
            line-height: 1.43;
            color: #353d4b;
            color: var(--grey-1);
            display: block;
            &:hover {
               text-decoration: none;
               font-size: 14px;
               font-weight: normal;
               background-color: var(--light-blue);
               color: var(--aqua);
            }
            &.active {
               text-decoration: none;
               font-size: 14px;
               font-weight: 600;
               background-color: var(--light-blue);
            }
            img,
            svg {
               float: left;
               margin: 0 13px 0 0;
            }
            .arrow-right {
               /*background:url(../../images/arrow-right-hover.svg) no-repeat 0 0;*/
               display: inline-block;
               float: right;
               height: 14px;
               width: 14px;
               margin: 5px 13px 0 0;
            }
         }
      }
   }
   &.team-sidebar {
      /*display:none;*/
      position: fixed;
      top: 90px;
      left: -320px;
      z-index: 999;
      overflow: hidden;
      height: 100%;
      @extend .animation;
      width: 319px;
      &.dis-block {
         /*display:block;*/
         left: 0px;
      }
      h3 {
         font-size: 16px;
         font-weight: 600;
         color: var(--grey-3);
         text-transform: uppercase;
         margin: 0 15px 15px 46px;
         .close {
            float: right;
            margin: 3px 0 0;
            background: url(../../images/close-w16.svg) no-repeat 0 0;
            width: 13px;
            height: 13px;
            &:hover {
               background: url(../../images/close-hover-w16.svg) no-repeat 0 0;
            }
         }
         .arrow-left {
            /* background: url(../../images/arrow-left.svg) no-repeat 0 1px;*/
            margin: 0;
            padding: 0;
            display: inline-block;
            float: right;
            svg {
               width: 15px;
               height: 15px;
               cursor: pointer;
            }
            &:hover svg .aqua {
               fill: var(--aqua);
            }
         }
         &:hover .arrow-left {
            /*background: url(../../images/arrow-left-hover.svg) no-repeat 0 1px;*/
         }
      }
      ul {
         margin: 0 10px;
         overflow-y: auto;
         height: 70%;
         min-height: 77px;
         max-height: 100%;
         float: none;
         li {
            position: relative;
            padding: 5px 5px 5px 15px;
            color: var(--grey-1);
            display: flex;
            height: 30px;
            >span {
               padding: 0 0 0 21px;
               align-self: center;
            }
            .name {
               flex: 1 0 0;
               flex-grow: 1;
               margin-right: 10px;
               line-height: 20px;
               vertical-align: middle;
               white-space: nowrap;
               padding: 0;
               overflow: hidden;
               text-overflow: ellipsis;
            }
            .capsule {
               flex-shrink: 0;
               padding: 0 5px;
            }
            &.open {
               background-color: var(--light-blue);
            }
            &:hover {
               cursor: pointer;
               background-color: var(--light-blue);
               .capsule {
                  display: none;
               }
               a {
                  .sidebar-more {
                     display: block;
                     margin: 2px 9px 1px 7px;
                     float: none;
                  }
                  .aqua {
                     fill: var(--aqua);
                  }
                  &.dropdown-toggle {
                     &::after {
                        display: none;
                     }
                  }
               }
            }
            a {
               padding: 0;
               border: none;
               display: inline-block;
               .sidebar-more {
                  display: none;
               }
               &.dropdown-toggle {
                  margin-right: 0;
                  &::after {
                     display: none;
                  }
               }
               img,
               svg {
                  margin: 0;
                  &.auto-active {
                     margin: 2px 5px 0 0;
                  }
               }
            }
            .dropdown-menu {
               border: solid 1px var(--aqua);
               left: inherit;
               padding: 10px 0;
               position: absolute;
               right: 5px;
               top: 5px;
               width: 145px;
               .dropdown-item {
                  font-weight: 600;
                  color: var(--grey-2);
                  padding: 5px 24px 5px 15px;
               }
            }
            &.open {
               a:focus {
                  background-color: var(--light-blue);
               }
            }
            &.title {
               font-size: 14px;
               font-weight: 600;
               color: var(--grey-2);
               a {
                  font-size: 14px;
                  font-weight: 600;
                  color: var(--grey-2);
               }
            }
            &.title .capsule,
            &.no-submenu .capsule {
               display: block;
            }
            &.blank {
               height: 30px;
               background-color: var(--white);
               cursor: auto;
            }
         }
      }
      .shedow {
         bottom: 0;
         display: block;
         height: 70px;
         width: 100%;
         box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 0 #e0e5e9;
         z-index: 999;
         background: var(--white);
         max-width: 318px;
         position: fixed;
         text-align: center;
         left: -320px;
         &.lsopen {
            left: 0;
         }
         .btn {
            margin: 0;
         }
      }
   }
}

.side-nav span.badge.new,
.collapsible span.badge.new {
   position: relative;
   background-color: transparent
}

.side-nav span.badge.new::before,
.collapsible span.badge.new::before {
   content: '';
   position: absolute;
   top: 10px;
   right: 0;
   bottom: 10px;
   left: 0;
   background-color: #26a69a;
   border-radius: 2px;
   z-index: -1
}

.side-nav .collapsible,
.side-nav.fixed .collapsible {
   border: none;
   box-shadow: none
}

.side-nav .collapsible li,
.side-nav.fixed .collapsible li {
   padding: 0;
   border-bottom: solid 1px #e0e5e9;
}

.side-nav .collapsible-header,
.side-nav.fixed .collapsible-header {
   background-color: transparent;
   border: none;
   line-height: inherit;
   height: inherit;
   padding: 0 16px
}

.side-nav .collapsible-header:hover,
.side-nav.fixed .collapsible-header:hover {
   background-color: none
}

.side-nav .collapsible-body,
.side-nav.fixed .collapsible-body {
   border: 0;
   background-color: #fff
}

.side-nav .collapsible-body li a,
.side-nav.fixed .collapsible-body li a {
   padding: 0 23.5px 0 31px
}

.side-nav {
   position: fixed;
   right: 0;
   bottom: 0;
   margin: 0;
   top: 91px;
   height: 100%;
   /*height: calc(100% + 60px);
	right:0;
	height: -moz-calc(100%);*/
   padding-bottom: 60px;
   background-color: #fff;
   z-index: 9999;
   overflow-y: auto;
   box-shadow: -2px 0 3px 0px rgba(0, 0, 0, 0.25);
   display: block;
   overflow: hidden;
   max-width: 380px;
   min-width: 260px;
   &.top387 {
      top: 387px;
   }
   .sidebar-btn {
      /* position: relative;
       padding:0;
	   z-index: 9999;*/
      position: fixed;
      padding: 0;
      z-index: 9999;
      bottom: 0;
      max-width: 260px;
      .shedow {
         /* position: fixed;
   bottom:0;
   right:0;*/
         display: block;
         height: 70px;
         width: 100%;
         max-width: 260px;
         padding: 20px 20px 0;
         box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 0 #e0e5e9;
         z-index: 999;
         background: var(--white);
         .btn-orange {
            margin: 0;
         }
      }
   }
}

.side-nav.right-aligned {
   right: 0;
   -webkit-transform: translateX(105%);
   transform: translateX(105%);
   left: auto;
   -webkit-transform: translateX(100%);
   transform: translateX(100%)
}

.side-nav .collapsible {
   margin: 0
}

.side-nav li {
   float: none;
   line-height: 48px
}

.side-nav li.active {
   background-color: none
}

.side-nav a {}

.side-nav a:hover {
   background-color: none;
}

#sidenav-overlay {
   background-color: rgba(0, 0, 0, 0.5);
   height: 120vh;
   left: 0;
   position: fixed;
   right: 0;
   top: 91px;
   will-change: opacity;
   z-index: 997;
}

#slide-out-column {
   .show-colinner {
      overflow: hidden;
      float: left;
      overflow-y: scroll;
      height: 253px;
      width: 100%;
      display: inline-block;
   }
   .btn-sec {
      width: 100%;
      display: inline-block;
      @extend .text-center;
      font-style: normal;
      padding: 14px 0 16px;
      box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.25);
   }
}

.show-col {
   padding: 0 0;
   float: left;
   width: 100%;
   position: absolute;
   top: 0;
   right: 0px;
   background: #fff;
   z-index: 9;
   width: 100%;
   /*@include box-shadow(0, 2px, 7px, 0, rgba(0, 0, 0, 0.25));*/
   .heading {
      float: left;
      width: 100%;
      padding: 25px 20px 10px;
      border-bottom: solid 1px #e0e5e9;
      h3 {
         display: inline-block;
         font-size: 16px;
         color: var(--grey-3);
         margin: 0;
      }
      a {
         display: inline-block;
         float: right;
         color: var(--aqua);
         font-size: 14px;
         line-height: 20px;
         font-weight: 600;
         font-style: normal;
         padding: 0;
      }
   }
   .cols {
      border-bottom: 1px solid var(--grey-5);
      padding: 10px 20px;
      float: left;
      width: 100%;
      >span {
         float: left;
         width: auto;
         color: var(--grey-1);
         font-size: 16px;
         line-height: 20px;
         font-style: normal;
      }
      .checkbox {
         float: right;
         width: auto;
         margin: 0;
         span {
            width: 18px;
            margin: 0;
         }
      }
   }
}

.filter {
   padding: 0 0 0;
   float: left;
   width: 100%;
   position: absolute;
   top: 0px;
   right: 0px;
   background: #fff;
   z-index: 9998;
   width: 100%;
   /*@include box-shadow(0, 2px, 7px, 0, rgba(0, 0, 0, 0.25));*/
   .heading {
      float: left;
      width: 100%;
      padding: 19px 20px;
      border-bottom: solid 1px #e0e5e9;
      h3 {
         display: inline-block;
         font-size: 16px;
         color: var(--grey-3);
         margin: 0;
      }
      a {
         display: inline-block;
         float: right;
         color: var(--aqua);
         font-size: 14px;
         line-height: 20px;
         font-weight: 600;
         font-style: normal;
         padding: 0;
      }
   }
   .cols {
      padding: 0 0 10px;
      border-bottom: none;
      .checkbox {
         padding: 5px 0;
         float: left;
         width: 100%;
         label {
            position: relative;
            /*
           display:block;
           padding-right:25px;
           */
            display: flex;
         }
         svg {
            display: inline-block;
            float: left;
            margin-right: 5px;
            flex: 0 0 auto;
         }
         span {
            width: auto;
            /*
           display:block;
           margin-left:25px;
           */
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            flex: 1 1 auto;
         }
         span.cr {
            /*position:absolute; right:0; top:2px; */
            width: 18px;
            margin-left: 5px;
            flex: 0 0 auto;
            top: 1px;
         }
         /* &:last-child{ padding:10px 0;}*/
         &--unchecked label>svg .fill {
            fill: #8493A9 !important;
         }
         &--unchecked label .dot {
            border: 1px solid #c6ced8 !important;
            background: transparent !important;
         }
      }
      .right {
         width: 100%;
         input {
            width: 100%;
            padding: 3px 10px;
            margin: 10px 0;
            cursor: pointer;
            font-size: 14px;
            background: #F4F8FA url("../../images/icon-search-small.svg") no-repeat scroll 95% center;
            border: solid 1px var(--grey-5);
            height: 25px;
            &:focus {
               background: #f4f8fa url("../../images/icn-search-hover-small.svg") no-repeat scroll 95% center;
               color: #000;
               border: solid 1px var(--aqua);
            }
         }
      }
   }
   .expand {
      clear: both;
      display: block;
      /*overflow-y: scroll;*/
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 5px 0 0; //height:450px;
      //min-height:244px;
      max-height: 440px;
      .checkbox {
         &:last-child {
            padding: 5px 0;
         }
      }
   }
   .checkbox {
      label {
        .dot {
          width: 8px;
          height: 8px;
          border-radius: 4px;
          float: left;
          margin: 6px 16px 0 3px;
          flex: 0 0 auto;
        }

        .subdot {
          @extend .dot;
          margin: 6px 16px 0 13px;
        }
      }

      &--unchecked label {
        > svg .fill {
          fill: #8493A9 !important;
        }

        .dot {
          border: 1px solid #c6ced8 !important;
          background: transparent !important;
        }

        .subdot {
           @extend .dot;
           margin: 6px 16px 0 13px;
        }
      }
   }
}

#slide-out-column {
   right: 0;
   top: 40px;
   padding: 0;
   position: absolute;
   width: 300px;
   height: 0;
   overflow: hidden;
   z-index: 999;
   background: var(--white);
   border: 1px solid transparent;
   @extend .animation;
   visibility: hidden;
   &.open {
      visibility: visible;
      @extend .animation;
      height: 371px;
      border: solid 1px #e0e5e9;
      border-radius: 2px;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
   }
}

#slide-out-filter {
   width: 260px;
   resize: both;
   right: -264px;
   /* display:none;*/
   @extend .animation;
   &.acc {
      right: 0;
      .shedow {
         right: 0;
      }
   }
}

#slide-out-filters {
   width: 260px;
   resize: both;
   right: 0px;
   position: absolute;
   top: 0;
   height: 100%;
}

.resize-drag {
   padding: 0px;
   margin: 0px;
   width: auto;
   box-sizing: border-box;
}

.resize-container {
   width: 100%;
}

#handle {
   position: absolute;
   left: -4px;
   top: 0;
   bottom: 0;
   width: 8px;
   cursor: w-resize;
   z-index: 9999;
}

.side-nav .accordion {
   border: none;
}

.accordion-toggle {
   cursor: pointer;
   margin: 0;
   padding: 10px 0;
   position: relative;
}

.accordion-content {
   display: block;
   padding: 0 20px;
   overflow: hidden;
   /*border-bottom: 1px solid #e0e5e9;*/
   opacity: 0;
   max-height: 1px;
   -webkit-transition: all .3s ease;
   -moz-transition: all .3s ease;
   -o-transition: all .3s ease;
   -ms-transition: all .3s ease;
   transition: all .3s ease;
   @extend .animation;
   &.open-content {
      display: block;
      opacity: 1;
      max-height: 500px;
      -webkit-transition: all 1s ease;
      -moz-transition: all 1s ease;
      -o-transition: all 1s ease;
      -ms-transition: all 1s ease;
      transition: all 1s ease;
      @extend .animation;
   }
}

.accord-bdr {
   border-bottom: 1px solid #e0e5e9;
}

.accordion-toggle {
   display: block;
   cursor: pointer;
   min-height: 3rem;
   line-height: 3rem;
   padding: 0 20px;
   font-size: 16px;
   font-style: normal;
   color: var(--grey-1);
   transition-property: all;
   transition-duration: .5s;
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   svg {
      display: inline-block;
      margin: 12px 0 0 0;
   }
   .icon-expand {
      display: inline-block;
      float: right;
   }
   .icon-collapse {
      display: none;
      float: right;
   }
   &:hover {
      color: var(--aqua);
      .icon {
         fill: var(--aqua);
      }
      .plus {
         width: 20px;
         height: 20px;
         line-height: 20px;
         float: right;
         padding: 0;
         background: url(../../images/accordian-expand-hover.svg) no-repeat 0 0;
      }
      .minus {
         width: 20px;
         height: 20px;
         line-height: 20px;
         float: right;
         padding: 0;
         background: url(../../images/accordian-collapse-hover.svg) no-repeat 0 0;
      }
   }
   &.active-header {
      .icon-expand {
         display: none;
         float: right;
      }
      .icon-collapse {
         display: inline-block;
         float: right;
      }
   }
   .plus {
      /*display: block;*/
      margin: 14px 0 0;
   }
   .plus {
      width: 20px;
      height: 20px;
      background: url(../../images/accordian-expand.svg) no-repeat 0 0;
      line-height: 20px;
      float: right;
      padding: 0;
   }
   .minus {
      /*display:none;*/
      margin: 14px 0 0;
   }
   .minus {
      width: 20px;
      height: 20px;
      background: url(../../images/accordian-collapse.svg) no-repeat 0 0;
      line-height: 20px;
      float: right;
      padding: 0;
   }
   &.active {
      border-bottom: none;
      .plus {
         display: none;
      }
      .minus {
         display: block;
      }
   }
}

.auto-active {
   /* width:16px;
  height:16px;
 background:url(../../images/auto-activate-outline-aqua.svg) no-repeat 0 0;
	line-height:16px;
	float:left;*/
   padding: 0;
   margin: 2px 5px 0 0;
   flex-shrink: 0;
   &.none {
      background: none;
   }
}

.sidebar-more {
   /* width:16px;
  height:16px;
 background:url(../../images/more-small.svg) no-repeat 0 0;
	line-height: 20px;
*/
   float: right;
   padding: 0;
   margin: 3px 0 0 5px;
}

/*#slide-out-filters{
&.side-nav{
position: absolute;
top:0;
}
}*/

.sidebar-btn {
   position: absolute;
   /* padding:0;
	   z-index: 9999;position: fixed;
    padding: 0;
    z-index: 9999;
    bottom: 0;*/
   width: 100%;
   min-width: 260px;
}

.shedow {
   /* position: fixed;
   bottom:0;display: block;*/
   position: fixed;
   bottom: 0;
   right: -385px;
   height: 70px;
   width: 100%;
   max-width: 260px;
   padding: 20px 20px 0;
   box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 0 #e0e5e9;
   z-index: 9999;
   background: var(--white);
   @extend .animation;
   .btn-orange {
      margin: 0;
   }
   .btn-reset {
      line-height: 30px;
      font-weight: bold;
      color: var(--grey-2);
   }
}

#slide-out {
   position: absolute;
   z-index: 9990;
   top: 0px;
   right: -385px;
   width: 260px;
   height: 100%;
   @extend .animation;
   visibility: hidden;
}

#device-slide-out {
	position: fixed;
	z-index: 9999;
	top: 90px;
	right: -385px;
	width: 380px;
	/*display: none;*/
	height: 100%;
	@extend .animation;

	#device-sidebar {
		display: block;
		right: 0;
		position: absolute;
		top: 0;
		height: 100%;
		z-index: 9999;

		&.side-nav {
			min-width: 380px;

			.mar-lr {
				margin: 0 30px;
				padding: 20px 0;

				.fix-height {
					overflow-x: hidden;
					overflow-y: scroll;
					height: 81%;
					padding: 10px 0 0 0;
					padding: 0;
					overflow-y: auto;
				}
			}

			.neald-top {
				border-bottom: solid 1px var(--grey-5);
				margin: 0 0;
				padding: 0 0 15px;

				.title {
					@extend .font-25b;
					color: #5b6e8c;

					img,
					svg {
						margin: 0 24px -8px 0;
					}

					.nav-open {
						margin: 0 0 0 16px;

						&:hover {
							cursor: pointer;
						}

						svg {
							margin: 0;
						}
					}

					.close {
						margin: 0;

						svg {
							margin: 0;
						}
					}
				}

				p {
					@extend .sans-regular;
					color: var(--grey-1);
					text-align: left;
					margin: 0 0 0 50px;

					&.italic {
						font-style: italic;
						margin: 6px 0 0 50px;
					}

					&.online {
						font-weight: 600;
						color: var(--aqua);
						margin: 6px 0 0 50px;
					}

					&.migration {
						font-weight: 600;
						color: var(--dark-blue);
						margin: 6px 0 0 50px;
					}

					.capsule {
						font-weight: bold;
						padding: 0 11px;
						margin-left: 5px;
						cursor: pointer;

						&:hover {
							text-decoration: none;
							color: var(--white);
							background-color: var(--dark-blue);
						}
					}
				}

				.tag-container {
					display: flex;
					flex-wrap: wrap;
					gap: 5px;
					margin: 6px 0 0 50px;
				}
			}

			ul {
				border-bottom: solid 1px var(--grey-5);
				margin: 0 0 16px 0;
				padding: 0 0 22px 0;

				&.first {
					padding: 16px 0 5px 0;
				}

				div.title {
					.font-25b {
						float: right;
						line-height: 25px;
					}
				}

				li {
					margin: 0 0 0 50px;
					@extend .sans-regular;
					color: var(--grey-1);
					display: flex;
					clear: both;

					span {
						@extend .sans-bold;
						width: 115px;
						display: inline-block;
						vertical-align: top;
						float: left;
						flex: 0 0 auto;
					}

					font {
						flex: 1 1 auto;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					svg {
						margin-top: 2px;
					}

					dd {
						margin: 0;
						padding: 0;
						display: inline-block;
						width: 138px;
						float: right;
					}

					&.title {
						img {
							margin: 0 24px -8px 0;
						}

						@extend .sans-1-bold;
						color: #5b6e8c;
						margin: 0;

						span {
							@extend .font-25b;
							float: right;
							line-height: 25px;
							text-align: right;
						}
					}
				}

				a {
					color: var(--dark-blue);
				}

				div.title {
					img,
					svg {
						margin: 0 24px -8px 0;
					}

					@extend .sans-1-bold;
					color: #5b6e8c;
					margin: 0 0 11px;

					span {
						@extend .font-25b;
					}
				}

				&:last-child {
					border-bottom: none;
				}
			}
		}
	}

	.sidebar-btn {
		text-align: center;

		.shedow {
			max-width: 380px;
			right: -385px;

			a {
				margin: 0 14px;
				display: inline-block;

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					/*margin:0;
	 				img{margin:0 0 3px;}*/
				}
			}
		}
	}
}

#split-bar {
   background-color: black;
   height: 100%;
   float: right;
   width: 6px;
   cursor: col-resize;
}

.sf-text {
   padding: 5px 0;
   i.w8 {
      width: 8px;
      height: 8px;
      margin: 6px 10px 0 6px;
      display: inline-block;
      border-radius: 100px;
      &.blue {
         background: #0071c5;
      }
      &.red {
         background: #fd5000;
      }
      &.orange {
         background: #ff9800;
      }
      &.grey {
         background: #c6ced8;
      }
      &.lightblue {
         background: #4bb7f5;
      }
   }
}

.select-text {
   display: none;
}

.accordion-toggle {
   .select-text {
      display: none;
      @extend .font-12;
   }
   &.active .select-text {
      display: block;
      margin: -15px 0 0;
   }
}

#device-slide-out.slide {
   right: 0;
}

#device-bot.slide .shedow {
   right: 0;
   .sidebar-droupdown {
      @extend .pos-rel;
      display: inline-block;
      vertical-align: top;
      .dropdown-toggle {
         border: none;
         padding: 5px 0 0;
         margin: 0 0 0 14px;
         &::after {
            display: none;
         }
      }
      .dropdown-menu {
         left: inherit;
         right: 0;
         top: inherit;
         bottom: 0;
         width: 180px;
         a {
            margin: 0;
            line-height: 20px;
            padding: 10px 27px;
            &:hover {
               background: var(--light-blue) !important;
               text-decoration: none;
            }
         }
      }
   }
}

#device-bot .shedow {
   right: -381px;
}

#slide-out.acc {
   right: 0px;
   width: 260px;
   display: block;
   opacity: 1;
   @extend .animation;
   position: absolute;
   bottom: 0;
   top: 0;
   visibility: visible
}

.sidebar-btn.acc .shedow {
   right: 0;
   @extend .animation;
   transition: right 1s;
}

.sidebar-left.team-sidebar.ls-open {
   left: 0;
   @extend .animation;
}

#sidebar-left .sidebar-btn.ls-open .shedow {
   left: 0;
}

#device-slide-out.slide.slide1 {
   right: -385px;
}
