.progress {
  width: 420px;
  max-width: 85vw;
  height:5px;
/*  position: absolute;
  bottom: 20vh;
  left: 50%;*/
  border-radius:100px;
  background: rgba(224, 229, 233, 0.5);
  transform: translate(0%, -50%);
  overflow: hidden;margin:41px auto 20px; display: inline-block;
  &.w200{ width: 200px; height:10px;margin: 15px auto 0;
  &:after {height:8px;margin: 1px 0;}
  }
&:after {
  content: '';
  display: block;
  width: 100%;
  height:5px;
  background:#19c5a2;
  animation: load 15s linear;border-radius:100px;
}
@-moz-keyframes load {
  0% {
    width: 0;
  }
  10% {
    width: 5%;
  }
  20% {
    width: 15%;
  }
  30% {
    width: 25%;
  }
  40% {
    width: 30%;
  }
  50% {
    width: 44%;
  }
  60% {
    width: 50%;
  }
  70% {
    width: 72%;
  }
  80% {
    width: 84%;
  }
  90% {
    width: 92%;
  }
  100% {
    width: 100%;
  }
}
@-webkit-keyframes load {
  0% {
    width: 0;
  }
  10% {
    width: 5%;
  }
  20% {
    width: 15%;
  }
  30% {
    width: 25%;
  }
  40% {
    width: 30%;
  }
  50% {
    width: 44%;
  }
  60% {
    width: 50%;
  }
  70% {
    width: 72%;
  }
  80% {
    width: 84%;
  }
  90% {
    width: 92%;
  }
  100% {
    width: 100%;
  }
}
@-o-keyframes load {
  0% {
    width: 0;
  }
  10% {
    width: 5%;
  }
  20% {
    width: 15%;
  }
  30% {
    width: 25%;
  }
  40% {
    width: 30%;
  }
  50% {
    width: 44%;
  }
  60% {
    width: 50%;
  }
  70% {
    width: 72%;
  }
  80% {
    width: 84%;
  }
  90% {
    width: 92%;
  }
  100% {
    width: 100%;
  }
}
@keyframes load {
  0% {
    width: 0;
  }
  10% {
    width: 5%;
  }
  20% {
    width: 15%;
  }
  30% {
    width: 25%;
  }
  40% {
    width: 30%;
  }
  50% {
    width: 44%;
  }
  60% {
    width: 50%;
  }
  70% {
    width: 72%;
  }
  80% {
    width: 84%;
  }
  90% {
    width: 92%;
  }
  100% {
    width: 100%;
  }
}
}


.progress-bar{
  position:relative;
  border:none;
  width:100%;
  height:40px;
  line-height:40px;
  vertical-align:midle;
  overflow:hidden;
  margin: 25px 0 0;
  font-weight:normal;
  font-size:20px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2) inset;
  border-radius: 100px;
  &.bgaqua{background:var(--grey-3);}
  &.bgorange{background:var(--grey-3);
   .bar{
   &.positive{
    background: rgba(0, 0, 0, 0) linear-gradient(to right, #fd5000 0%, #fd5000 25%, #dc4600 25%, #dc4600 100%) repeat scroll 0 0;
}
}
  }
  &.bgyellow{background:var(--grey-3);
   .bar{
   &.positive{
    background: rgba(0, 0, 0, 0) linear-gradient(to right, #ffc107 0%, #ffc107 50%, #dda706 50%, #dda706 100%) repeat scroll 0 0;
}
}
  }

  img, svg {margin: 10px 12px 0px 0;}
   span{
      position:absolute;
      display:block;
      width:100%;
      height:100%;
      text-align:center;
      color:#fff; z-index: 1;
    }
  .bar{
    position:absolute;
    top:0;
    height:100%;
    overflow:hidden; z-index: 0;
    span{
      position:absolute;
      display:block;
      width:100%;
      height:100%;
      text-align:center;
        color:#fff;
    }
    &.positive{
      left:0;
      width:100%;
	  background:rgba(0, 0, 0, 0) linear-gradient(to right, #19c5a3 0%, #19c5a3 64%, #15ab8d 64%, #15ab8d 100%) repeat scroll 0 0;
      -webkit-animation: animate-positive 4s;
      animation: animate-positive 4s;
	  &.w100{ background:rgba(0, 0, 0, 0) linear-gradient(to right, #19c5a3 0%, #19c5a3 100%, #15ab8d 64%, #15ab8d 100%) repeat scroll 0 0;}
    }
    &.negative{
      background:#07A4DD;
      right:0;
      width:46%;
      span{
        right:0;
        color:#fff;
      }

      -webkit-animation: animate-negative 4s;
      animation: animate-negative 4s;
    }
  }


  &--migration{
    border-radius: 3px;
  }

}

@-webkit-keyframes animate-positive {
  0% { width:0%; }
}
@keyframes animate-positive {
  0% { width:0%; }
}
@-webkit-keyframes animate-negative {
  0% { width:100%; }
}
@keyframes animate-negative {
  0% { width:100%; }
}

#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index:9999;
  margin:0 auto;
  border: 4px solid #e0e5e9;
  border-radius: 50%;
  border-top: 4px solid #19c5a2;
  width:60px;
  height:60px;
  margin-left:-30px; margin-top:-30px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add animation to "page content" */
.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s
}

@-webkit-keyframes animatebottom {
  from { bottom:-100px; opacity:0 }
  to { bottom:0px; opacity:1 }
}

@keyframes animatebottom {
  from{ bottom:-100px; opacity:0 }
  to{ bottom:0; opacity:1 }
}
.loaded #loader {
    opacity: 0;
	}
#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}
.loaded #loader-wrapper {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s 1s ease-out;
}
#loader-wrapper .loader-section {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background:rgba(255,255,255,0.9);
    z-index:0;
}


.slide #loader-wrapper {
    top:inherit;
    left: inherit;
    width: 100%;
    height: 100%;
    z-index: 1000;
}
/*.slide #loader{
left: 170px;
    top: 45%;
}
.slide #loader-wrapper .loader-section{top:inherit;}*/
#device-slide-out #loader-wrapper {top:inherit;
    left: inherit;}

.loading{ margin:40px auto 0; padding:0 0 20px; position: relative;
&.w200{width:200px;margin: 10px auto 0;}
}
.loader {
	width: 200px;
	height: 10px;
	background-color: #E0E5E9;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	border-radius: 100px;
	box-shadow: none;

	&.w200 {
		width: 200px;

		&:after {
			right: 160px;
			animation-name: slide1;
		}
	}

	&:after {
		border-radius: 100px;
		content: '';
		position: absolute;
		background-color: #19C5A2;
		left: 1px;
		top: 1px;
		bottom: 1px;
		right: 90%;
		animation-name: slide;
		animation-duration: 2s;
		animation-easing-function: linear;
		animation-iteration-count: infinite;
	}
}


@keyframes slide {
	0% {
		right: 90%;
		left: 1px
	}

	5% {
		left: 1px
	}

	50% {
		right: 1px;
		left: 90%;
	}

	55% {
		right: 1px;
	}

	100% {
		right: 90%;
		left: 1px;
	}
}




.sidebar-loader{
	position:absolute;
	top:0; left:0;
	width:100%;
	height:100%; height:calc(100% - 90px);
	background: rgba(255, 255, 255, 0.9);
	z-index:10000;

	&.sidebar-loader--loaded{
	 	visibility: hidden;
    	opacity: 0;
    	transition: all .3s ease-out;

		#loader{
			opacity:0;
		}

	}

}


.small-loader{
	position:absolute;
	top:0; left:0;
	width:100%;
	height:100%;
	z-index:10000;

  &__spinner{
    position:absolute;
    left: 50%;
    top: 50%;
    z-index: 9999;
    margin: 0 auto;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    margin-top: -12px;
    border: 2px solid #e0e5e9;
    border-top: 2px solid #19c5a2;
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }

	&.small-loader--loaded{
	 	visibility: hidden;
    opacity: 0;
    transition: all .3s ease-out;

		.small-loader__spinner{
			opacity:0;
		}

	}

}




