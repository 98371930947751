.cmn-toggle {
   position: absolute;
   margin-left: -9999px;
   visibility: hidden;
   +label {
      display: block;
      position: relative;
      cursor: pointer;
      outline: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
   }
}

input.cmn-toggle-round+label {
   padding: 2px;
   width: 37px;
   height: 20px;
   background-color: none;
   -webkit-border-radius: 60px;
   -moz-border-radius: 60px;
   -ms-border-radius: 60px;
   -o-border-radius: 60px;
   border-radius: 60px;
   &:before,
   &:after {
      display: block;
      position: absolute;
      top: 1px;
      left: 1px;
      bottom: 1px;
      content: "";
   }
}

.switch.active input.cmn-toggle-round+label {
   &:before,
   &:after {
      display: block;
      position: absolute;
      top: 1px;
      left: 1px;
      bottom: 1px;
      content: "";
   }
}

input.cmn-toggle-round+label:before,
.switch.active input.cmn-toggle-round+label:after {
   right: 1px;
   background-color: var(--grey-4);
   -webkit-border-radius: 60px;
   -moz-border-radius: 60px;
   -ms-border-radius: 60px;
   -o-border-radius: 60px;
   border-radius: 60px;
   -webkit-transition: background 0.4s;
   -moz-transition: background 0.4s;
   -o-transition: background 0.4s;
   transition: background 0.4s;
}

input.cmn-toggle-round+label:after,
.switch.active input.cmn-toggle-round+label:after {
   width: 14px;
   height: 14px;
   margin-left: 2px;
   margin-top: 2px;
   background-color: var(--white);
   -webkit-border-radius: 100%;
   -moz-border-radius: 100%;
   -ms-border-radius: 100%;
   -o-border-radius: 100%;
   border-radius: 100%;
   -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
   -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
   -webkit-transition: margin 0.4s;
   -moz-transition: margin 0.4s;
   -o-transition: margin 0.4s;
   transition: margin 0.4s;
}

input.cmn-toggle-round:checked+label:before,
.switch.active input.cmn-toggle-round:checked+label:before,
input.cmn-toggle-round.darkgrey:checked+label:before,
.switch.active input.cmn-toggle-round.darkgrey:checked+label:before {
   background-color: var(--aqua);
}

input.cmn-toggle-round:checked+label:after,
.switch.active input.cmn-toggle-round:checked+label:after {
   margin-left: 18px;
}

input.cmn-toggle-round-flat {
   +label {
      padding: 2px;
      width: 120px;
      height: 60px;
      background-color: #dddddd;
      -webkit-border-radius: 60px;
      -moz-border-radius: 60px;
      -ms-border-radius: 60px;
      -o-border-radius: 60px;
      border-radius: 60px;
      -webkit-transition: background 0.4s;
      -moz-transition: background 0.4s;
      -o-transition: background 0.4s;
      transition: background 0.4s;
      &:before,
      &:after {
         display: block;
         position: absolute;
         content: "";
      }
      &:before {
         top: 2px;
         left: 2px;
         bottom: 2px;
         right: 2px;
         background-color: var(--white);
         -webkit-border-radius: 60px;
         -moz-border-radius: 60px;
         -ms-border-radius: 60px;
         -o-border-radius: 60px;
         border-radius: 60px;
         -webkit-transition: background 0.4s;
         -moz-transition: background 0.4s;
         -o-transition: background 0.4s;
         transition: background 0.4s;
      }
      &:after {
         top: 4px;
         left: 4px;
         bottom: 4px;
         width: 52px;
         background-color: #dddddd;
         -webkit-border-radius: 52px;
         -moz-border-radius: 52px;
         -ms-border-radius: 52px;
         -o-border-radius: 52px;
         border-radius: 52px;
         -webkit-transition: margin 0.4s, background 0.4s;
         -moz-transition: margin 0.4s, background 0.4s;
         -o-transition: margin 0.4s, background 0.4s;
         transition: margin 0.4s, background 0.4s;
      }
   }
   &:checked+label {
      background-color: #8ce196;
      &:after {
         margin-left: 60px;
         background-color: #8ce196;
      }
   }
}

.collapse {
   display: none;
   transition: 0.35s ease-in-out 0s;
}

.collapse.in {
   display: block;
}

input.cmn-toggle-round.w50+label {
   padding: 4px;
   width: 50px;
   height: 25px;
}

input.cmn-toggle-round.w50+label:after,
.switch.active input.cmn-toggle-round.w50+label:after {
   width: 17px;
   height: 17px;
   margin-left: 3px;
   margin-top: 3px;
}

input.cmn-toggle-round.w50:checked+label:after,
.switch.active input.cmn-toggle-round.w50:checked+label:after {
   margin-left: 26px;
}

input.cmn-toggle-round.darkgrey+label:before,
.switch.active input.cmn-toggle-round.darkgrey+label:after {
   background-color: var(--grey-4);
}

.switch {
   width: 50px;
   height: 25px;
   overflow: hidden;
   position: relative;
   .switch__label {
      width: 50px;
      height: 25px;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: var(--grey-4);
      border: none;
      border-radius: 15px;
      -webkit-transition: background-color 0.25s ease;
      transition: background-color 0.25s ease;
      cursor: pointer;
      &::after {
         content: '';
         display: block;
         box-sizing: border-box;
         position: absolute;
         top: 4px;
         left: 4px;
         width: 17px;
         height: 17px;
         background: #fff;
         border-radius: 50px;
         -webkit-transition: left 0.25s ease, background-color 0.25s ease;
         transition: left 0.25s ease, background-color 0.25s ease;
         box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
      }
   }
   .switch__checkbox:checked+.switch__label {
      background-color: var(--aqua);
   }
   .switch__checkbox:checked+.switch__label::after {
      left: 29px;
      background-color: #fff;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
   }
   .switch__checkbox {
      position: absolute;
      left: -20px;
   }
   &.small {
      width: 37px;
      height: 20px;
      .switch__label {
         width: 37px;
         height: 20px;
         &::after {
            width: 14px;
            height: 13px;
            top: 3px;
         }
      }
      .switch__checkbox:checked+.switch__label::after {
         left: 20px;
      }
   }
   &.disabled{
     
   }
}