.status-message-box {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 12px;
	padding: 12px 14px;
	border-radius: 4px;
	width: fit-content;

	&-info {
		@extend .status-message-box;
		background-color: rgba(0, 113, 197, 0.12);
		color: var(--darker-blue);
	}

	&-success {
		@extend .status-message-box;
		background-color: rgba(25, 197, 162, 0.12);
		color: var(--aqua);
	}

	&-warning {
		@extend .status-message-box;
		background-color: rgba(255, 193, 7, 0.12);
		color: var(--dark-yellow);
	}

	&-error {
		@extend .status-message-box;
		background-color: rgba(253, 80, 0, 0.12);
		color: var(--orange);
	}

	svg {
		flex-shrink: 0;
		flex-grow: 0;
		width: 18px;
		height: 18px;
	}

	&__title_message {
		display: flex;
		flex-direction: column;
		align-self: flex-end;
		align-items: start;
		gap: 4px;
	}

	&__title {
        font-size: 16px;
        font-weight: bold;
    }

	&__message {
		font-size: 14px;
	}
}
