.popup{display:table;width:100%;height: 100%;

	&.fix-popup{
		.modalpopup-container{
		/*
		left: 0;
		position: absolute;
		right: 0;
		top: 25%;
		*/
		}
	}

}
.popup > div{display:table-cell;vertical-align:middle;}

.modalpopup-container, .form-container-white {
	background-color: var(--white);
	border-radius: 3px;
	margin: 0px auto 0;
	padding: 40px 0 20px;
	width: 480px;
	height: auto;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
}

.modalpopup-container.w560, .form-container-white.w560 {
  width:560px;
}
.modalpopup-container h3, .form-container-white h3 {
  font-size: 25px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  text-align: center;
  color: var(--grey-2);
  margin-bottom:30px;
  clear: both;
  margin-top:0;
&.title{
  margin-bottom:23px;
}
&.margint20 {
    margin-top: 20px;
}
&.marginb10 {
    margin-bottom: 10px;
}
img, svg{
display: inline-block;
    margin: 0 10px 0 0;
    vertical-align: top;
}
}

.modalpopup-container {
     position: relative;
      .close {
		position:absolute;
		top:20px;
		right:20px;
         float: right;z-index: 999;
		}
		h3{
    &.mb25{margin-bottom:25px;}
    &.mb30{margin-bottom:30px;}
		&.mb0{margin-bottom:0px;}
		}
		.line1{
      width: 520px;
      border-top: solid 1px var(--grey-5);display:block;margin:18px auto 21px;
    }
    .line2{
       width:400px;
       border-top: solid 1px var(--grey-5);display:block;margin:18px auto 21px;
    }

    .text-14i{@extend .text-aqua-14i;color: var(--grey-1);@extend .text-center;margin:0 0 36px;
	&.text-left{@extend .text-left; padding:0 38px; margin:0 0 20px;}
&.text-left1{@extend .text-left; padding:0 59px; margin:0 0 15px;}
	}
	.redio-btn-sec{
	padding:0;
	margin:15px 0 0;
	display:block;
	.first{margin-left:125px;}
	dl{display:inline-block;
	&:first-child{margin-left:125px;}
	}
	.radio{   width: 110px;
    display: inline-block;
	&:first-child{margin-left: 125px;}
	}
	}
.add-text{margin:23px 0 0;}
.line{
@extend .sans-regular;color: var(--grey-2); box-shadow: inset 0 1px 0 0 var(--grey-5);padding: 15px 0;
a{color: var(--grey-2);
&:hover{color: var(--aqua);text-decoration:none;}
}

}

  /* scrollable area */
  &.w600{
    .modalpopup-container__scroll{
       max-height:248px;
      padding-bottom:1px;
      overflow-y:auto;
      .form-group{
        padding-right:20px !important;
        .inline{
          position:relative;
          .trash-grey{
            position:absolute;
            left:500px;
          }
        }
      }
    }
  }

}

.form-container-white {
	margin: 0 auto;

	&.domain {
		padding: 30px 0 30px;
	}

	.close {
		margin: -22px 22px 0 0;
	}

	p {
		margin: 0px 0 30px;
		text-align: center;

		span {
			font-weight: 600;
		}
	}
}

.close .close {
	float: right;
	color: #c6ced8;
}

.modalpopup-container.dis-block {
	display: block;
}

.bg:after {
	content: "";
	background: rgba(53, 61, 75, 0.85);
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 99999;
}

.forgot-password {
	@extend .sans-bold;
	line-height: 1.29;
	color: var(--aqua);
	text-align: right;
	display: inline-block;
	float: right;
	margin: 10px 0 0;

	&:hover {
		color: var(--aqua);
	}
}

p.reset-text {
  margin: 0 0 20px;
&.marginb30{
	margin:0 0 30px;
	}
 span {
 font-weight: 600;
}
}
.form-container-white.forgot{
  padding:31px 0 20px;
  p.reset-text {
    margin: 0 0 29px;
}
.btn-grp{
  margin-top: 29px;
}
}
.device-white-container{
  background-color: var(--white);
  border-radius: 3px;
  margin: 0px 2% 0 0;
  padding:16px 28px 19px;
  height: auto;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.25);
  display:inline-block;
  float: left;
  text-align:center;
&.w429{width:32%;height:190px;}
&.w185{width:16%;padding:16px 16px; /*min-width: 150px;*/margin:0 1% 0 0;}
&.w480{width:32.5%;/*min-width:301px;*/margin:0 1% 0 0; padding: 16px 14px 19px;
&.protection{ padding: 16px 21px 19px;}
}
&.w49{width:49%;
&.marn{margin:0;}
}
&.h200{height:200px;}
&.protect{height:210px;}
&.last{margin:0; padding: 16px 21px 11px;
h3{margin: 0 0 11px;}
}
h3{
 @extend .sans-1-bold;
  line-height: 1.25;
  color: var(--grey-3);
  text-align: center;
  margin:0 0 20px;
}
.discovered{
display:inline-block;
 @extend .sans-1-bold;
  line-height: 1;
  color: var(--grey-1);
  text-align: center;
  font-size: 12px;
  vertical-align: top;
 &.mrg-center{ margin:0 6%;
	@media (min-width: 1900px) {
		margin:0 10%;
	}
 }
 &.border-left{ border-left: solid 1px var(--grey-5); padding:0 0 0 12px;}
 &.w25{/*width:25%;*/}
h4{
  font-size: 12px;
  margin:0;
  font-weight:600;
}
.nomber{
  font-size: 40px;
  line-height:40px;
  letter-spacing: -1px;
&.aqua{color: var(--aqua);}
&.orange{color: var(--orange);}
&.yellow{color: var(--yellow);}
&.grey{color: var(--grey-4);}
}
.text{
@extend .sans-regular;
color: var(--grey-2);
&.aqua{color: var(--aqua);}
&.orange{color: var(--orange);}
img, svg{
    display: inline-block;
    margin:3px 3px 0;
	}
}
.text1{
color: var(--grey-3);
font-weight:normal;
margin:3px 0 13px 0;
font{font-weight: 600;}
}
.number-data{
  padding-top:10px;
  border-top: solid 1px var(--grey-5);
  font-size:25px;
  font-weight:bold;
  color: var(--grey-2);
  font{
    display:block;
    margin-top:2px;
    font-size:12px;
    font-weight:normal;
    color:var(--grey-1);
  }
}
.text2{
color: var(--grey-1);
font-weight:normal;
border-top: solid 1px var(--grey-5);
padding:8px 0;
float:left;
text-align:left;
 width: 100%;
 clear: both;
font{font-weight: 600;font-size:16px;float:right; margin:-3px 0 0 10px;}
}
.text3{
color: var(--grey-1);
font-weight:600;
}

}
.licences{
@extend .sans-regular;
text-align: center;
line-height:1.43;
color: var(--grey-1);
 margin: 13px 0 0;
img, svg{margin:0 7px -4px 0;}
}

}

/*#discoverd .panel-table{ position:inherit;top:inherit; left:inherit; right:inherit; bottom:inherit;}*/

.paddtb20{padding:20px 0;display: inline-block; width:100%;}
.device-progress-sec .breadcrumb.margin{margin-bottom:0;}
.device-progress-sec{
  /* width:100%;*/
  /*height: 290px;*/
  /*padding:0 0 30px 0;*/
  /*display:inline-block;*/
  background-image: linear-gradient(to bottom, #ffffff, #F4F8FA);
  box-shadow: inset 0 -1px 0 0 #e0e5e9;

  display: block;
  position:relative;
  /*overflow: hidden;*/


  &__expander{
    padding-bottom:1px;
    opacity: 0;
    max-height: 0;
    visibility: hidden;
    overflow: hidden;
    transition: all .3s ease-in, visibility 0s .3s ease-in;
    &.is-open{
      opacity:1;
      visibility:visible;
      max-height:250px;
      padding-bottom:30px;
      transition: all .3s ease-in;
    }
  }




  &__toggle{
    cursor: pointer;
    position:absolute;
    z-index:1;
    top:100%;
    left:50%;
    margin-left:-20px;
    margin-top:-1px;
    width:40px;
    height:20px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;

    text-align: center;

    background:#F4F8FA;
    box-shadow:0 1px 1px 0 #e0e5e9;

    svg{
      position:relative;
      top:-5px;
      width:20px;
      height:20px;
    }

    &.is-toggled{
      svg{
        transform:rotate(180deg);
      }
    }

  }

}
.dlp{
  padding:0 0 9px 0;
  &:last-child{padding:9px 0 0 0;}
  img, svg{float:left;margin:5px 12px 0 0;}
  p{font-size: 12px;
  font-weight: normal;
  line-height: 1;
  color: var(--grey-1);
  text-align:left;
  margin:0;
  &.nomber{font-size: 16px;font-weight: 600;line-height: 1.25;}
  }
  &.bdr{ border-top: solid 1px var(--grey-5); border-bottom: solid 1px var(--grey-5); padding:9px 0;}

}
.white-container{
  background-color: var(--white);
  border-radius: 3px;
  margin:0 auto;
  height: auto;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.25);
  display:inline-block;
  text-align:left;
}
.paddlr30{ padding-left:30px; padding-right:30px;}

.device-user-profile {
	width: 100%;
	margin: 0 0 30px;

	.left-sec {
		display: inline-block;
		@extend .font-30l;
		margin: 0 0 0 20px;

		p {
			@extend .sans-regular;
			line-height: 1.43;
			color: var(--grey-1);
			font-style: italic;

			&.aqua14 {
				@extend .sans-bold;
				line-height: 1.43;
				color: var(--aqua);
				font-style: normal;
			}

			&.migration {
				color: var(--dark-blue);
				font-style: normal;
			}

			i {
				margin: 0 10px 0 0;
			}

			.capsule {
				font-weight: bold;
				padding: 0 11px;
				margin-left: 5px;
				cursor: pointer;

				&:hover {
					text-decoration: none;
					color: var(--white);
					background-color: var(--dark-blue);
				}
			}
		}

		.tag-container {
			display: flex;
			flex-wrap: wrap;
			gap: 5px;
			margin: 4px 0 0 0;
		}

		svg {
			margin: 3px 5px 0 0;
		}
	}

	.right-sec {
		margin: 20px 0 0 0;
		display: inline-block;
		float: right;

		img {
			margin: 0 20px 0 0;
		}
	}

	.profile-dropdown {
		@extend .pos-rel;
		display: inline-block;
		vertical-align: top;

		.dropdown-toggle {
			border: none;
			padding: 0;
			margin: 0 20px 0 0;

			&::after {
				display: none;
			}
		}

		.dropdown-menu {
			left: inherit;
			right: 0;
			top: 0;
			bottom: inherit;
			width: 180px;

			a {
				margin: 0;
				line-height: 20px;
				padding: 10px 27px;

				&:hover {
					background: var(--light-blue) !important;
					text-decoration: none;
				}
			}
		}
	}
}

.modalpopup-container {
	.icon-img {
		margin: 0 auto;
		position: absolute;
		text-align: center;
		top: 0;
		left: 0;
		transform: translateY(-50%);
		width: 100%;

		img.error {
			box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
			transform: rotate(-45deg);
			border: solid 4px #ffffff;
			border-radius: 4px;
		}

		/*img{box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);}*/
	}

	&.modal-padd {
		padding: 56px 0 30px;

		&.device-modal {
			p {
				margin: 7px 0 20px;

				&.description {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 12px;
					padding: 12px 14px;
					margin: 0 40px 20px 40px;
					border-radius: 4px;

					&-success {
						@extend .description;
						background-color: rgba(25, 197, 162, 0.12);
						color: var(--aqua);
					}

					&-warning {
						@extend .description;
						background-color: rgba(255, 193, 7, 0.12);
						color: var(--dark-yellow);
					}

					&-error {
						@extend .description;
						background-color: rgba(253, 80, 0, 0.12);
						color: var(--orange);
					}

					svg {
						width: 18px;
						height: 18px;
					}

					span.text {
						align-self: flex-end;
						font-size: 14px;
					}
				}
			}

			ul {
				margin: 0 auto;
				padding: 0;
				width: 400px;
				@extend .sans-regular;
				color: var(--grey-1);

				li {
					border-top: solid 1px var(--grey-5);
					padding: 10px 7px;

					&:last-child {
						border-bottom: solid 1px var(--grey-5);
					}

					span {
						font-weight: 600;
						min-width: 230px;
						display: inline-block;
					}
				}
			}
		}
	}

	&.modal-padd-confirm-with-otp {
		// used with the confirm-with-otp-dialog
		@extend .modal-padd;
		padding-left: 20px;
		padding-right: 20px;
	}

	&.padd35 {
		padding: 35px 0 20px;
	}

	&.w600 {
		width: 600px;

		h3 {
			margin-bottom: 21px;

			&.mb25 {
				margin-bottom: 25px;
			}
		}

		.form-group {
			padding: 0 40px;
			margin-bottom: 18px;

			label {
				color: var(--grey-1);
			}

			label.grey-2 {
				@extend .grey-2;
			}

			&.mb10 {
				margin-bottom: 10px;
			}

			&.mb0 {
				margin-bottom: 0px;
			}

			&.mt30 {
				margin-top: 30px;
			}

			&.mb20 {
				margin-bottom: 20px;
			}

			&.mb30 {
				margin-bottom: 30px;
			}
		}

		.btn-grp {
			margin-top: 0;
		}
	}

	.confirm-warning {
		@extend .sans-bold;
		align-items: flex-start;
		align-self: stretch;
		border-radius: 4px;
		background-color: var(--action-warning-background);
		color: var(--action-warning);
		display: flex;
		flex-grow: 1;
		flex-direction: initial;
		gap: 12px;
		height: auto;
		justify-content: flex-start;
		line-height: 1.43;
		font-weight: normal;
		padding: 12px 14px;
		margin: 30px 0;
		width: auto;

		&--spans {
			display: flex;
			flex-direction: column;
		}
	}

	.confirm-otp-verification {
		border-top: 1px solid var(--grey-5);
		text-align: center;

		p {
			margin: 20px 0;
		}

		.form-group {
			padding: 12px 14px;
			margin: unset;
		}
	}

	.form-group {
		&.assign-security-officer {
			padding: unset;

			input {
				width: 100%;
			}
		}

		&.update-user-email {
			padding: unset;

			input {
				width: 100%;
			}
		}
	}

	.btn-grp3 {
		border-top: 1px solid var(--grey-5);
		padding-top: 30px;
	}
}

.grey-container-scroll {
  min-width: 920px;
  width:100%;
  height: 340px;
  border-radius: 3px;
  background: #f4f8fa;
  border: solid 1px #e0e5e9;
  overflow-y:scroll;
  .padd{ padding:30px 103px 30px 30px;}
  p{@extend .sans-regular;}
}

.modalpopup-container.dis-b{
position:fixed;
top:50%;
left:0;
right:0;
margin:-125px auto;
z-index:999999;
height: auto;
}
