// These rules are based on the Bootstrap 3.3 stylesheet as can be seen here:
// https://getbootstrap.com/docs/3.3/dist/css/bootstrap.css

.tooltip {
	position: absolute;
	z-index: 1070;
	display: block;
	@extend .sans-regular;
	line-height: 1.43;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	word-wrap: normal;
	white-space: normal;
	filter: alpha(opacity=0);
	opacity: 0;
	line-break: auto;

	&.in {
		filter: alpha(opacity=90);
		opacity: .9
	}

	&.top {
		padding: 5px 0;
		margin-top: -5px
	}

	&.right {
		padding: 0 5px;
		margin-left: 5px
	}

	&.bottom {
		padding: 5px 0;
		margin-top: 5px
	}

	&.left {
		padding: 0 5px;
		margin-left: -5px
	}
}

.tooltip-inner {
	min-width: 68px;
	max-width: 200px;
	padding: 4px 8px;
	color: #fff;
	text-align: left;
	min-height: 30px;
	border-radius: 2px;
	background-color: var(--grey-1);
}

.tooltip-arrow {
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid
}

.tooltip {
	&.top .tooltip-arrow {
		bottom: 0;
		left: 50%;
		margin-left: -5px;
		border-width: 5px 5px 0;
		border-top-color: var(--grey-1);
	}

	&.top-left .tooltip-arrow {
		right: 5px;
		bottom: 0;
		margin-bottom: -5px;
		border-width: 5px 5px 0;
		border-top-color: var(--grey-1);
	}

	&.top-right .tooltip-arrow {
		bottom: 0;
		left: 5px;
		margin-bottom: -5px;
		border-width: 5px 5px 0;
		border-top-color: var(--grey-1);
	}

	&.right .tooltip-arrow {
		top: 50%;
		left: 0;
		margin-top: -5px;
		border-width: 5px 5px 5px 0;
		border-right-color: var(--grey-1);
	}

	&.left .tooltip-arrow {
		top: 50%;
		right: 0;
		margin-top: -5px;
		border-width: 5px 0 5px 5px;
		border-left-color: var(--grey-1);
	}

	&.bottom .tooltip-arrow {
		top: 0;
		left: 50%;
		margin-left: -5px;
		border-width: 0 5px 5px;
		border-bottom-color: var(--grey-1);
	}

	&.bottom-left .tooltip-arrow {
		top: 0;
		right: 5px;
		margin-top: -5px;
		border-width: 0 5px 5px;
		border-bottom-color: var(--grey-1);
	}

	&.bottom-right .tooltip-arrow {
		top: 0;
		left: 5px;
		margin-top: -5px;
		border-width: 0 5px 5px;
		border-bottom-color: var(--grey-1);
	}
}
