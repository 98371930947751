.animation{transition:1s all;}


.pullUp{
	animation-name: pullUp;
	-webkit-animation-name: pullUp;	

	animation-duration: 1.1s;	
	-webkit-animation-duration: 1.1s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-webkit-transform-origin: 50% 100%; 		
}

@keyframes pullUp {
	0% {
		transform: scaleY(0.1);
	}
	40% {
		transform: scaleY(1.02);
	}
	60% {
		transform: scaleY(0.98);
	}
	80% {
		transform: scaleY(1.01);
	}
	100% {
		transform: scaleY(0.98);
	}				
	80% {
		transform: scaleY(1.01);
	}
	100% {
		transform: scaleY(1);
	}							
}

@-webkit-keyframes pullUp {
	0% {
		-webkit-transform: scaleY(0.1);
	}
	40% {
		-webkit-transform: scaleY(1.02);
	}
	60% {
		-webkit-transform: scaleY(0.98);
	}
	80% {
		-webkit-transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(0.98);
	}				
	80% {
		-webkit-transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(1);
	}		
}

/*
==============================================
pullDown
==============================================
*/

.pullDown{
	animation-name: pullDown;
	-webkit-animation-name: pullDown;	

	animation-duration: 1.1s;	
	-webkit-animation-duration: 1.1s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	transform-origin: 50% 0%;
	-ms-transform-origin: 50% 0%;
	-webkit-transform-origin: 50% 0%; 		
}

@keyframes pullDown {
	0% {
		transform: scaleY(0.1);
	}
	40% {
		transform: scaleY(1.02);
	}
	60% {
		transform: scaleY(0.98);
	}
	80% {
		transform: scaleY(1.01);
	}
	100% {
		transform: scaleY(0.98);
	}				
	80% {
		transform: scaleY(1.01);
	}
	100% {
		transform: scaleY(1);
	}							
}

@-webkit-keyframes pullDown {
	0% {
		-webkit-transform: scaleY(0.1);
	}
	40% {
		-webkit-transform: scaleY(1.02);
	}
	60% {
		-webkit-transform: scaleY(0.98);
	}
	80% {
		-webkit-transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(0.98);
	}				
	80% {
		-webkit-transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(1);
	}		
}

