.admin-copyright-text {
	@extend .sans-regular;
	font-size: 12px;
	line-height: 1.25;
	color: var(--white);
	position: fixed;
	bottom: 24px;
	left: 30px;

	span {
		margin: 0;
		display: inline-block;
	}
}

.account-copyright-text {
	@extend .sans-regular;
	font-size: 12px;
	line-height: 1.25;
	color: var(--grey-3);
	display: block;
	text-align: center;
	margin: 24px 0;

	&--white {
		color: var(--white);
	}

	span {
		margin: 0;
		display: inline-block;
	}
}
